import React, { useState } from 'react';
import { Box, FormControl, MenuItem, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';

interface TimeSpanPickerProps {
  onChange: (timeSpan: TimeSpan) => void;
}

export interface TimeSpan {
  label: string;
  startDate: Dayjs;
  endDate: Dayjs;
}

export const predefinedSpans: TimeSpan[] = [
  { label: 'Today', startDate: dayjs(), endDate: dayjs() },
  { label: 'This Week', startDate: dayjs().startOf('week'), endDate: dayjs().endOf('week') },
  {
    label: 'Last Week',
    startDate: dayjs().subtract(1, 'week').startOf('week'),
    endDate: dayjs().subtract(1, 'week').endOf('week'),
  },
  { label: 'This Month', startDate: dayjs().startOf('month'), endDate: dayjs().endOf('month') },
  {
    label: 'Last Month',
    startDate: dayjs().subtract(1, 'month').startOf('month'),
    endDate: dayjs().subtract(1, 'month').endOf('month'),
  },
  { label: 'This Year', startDate: dayjs().startOf('year'), endDate: dayjs().endOf('year') },
  {
    label: 'Last Year',
    startDate: dayjs().subtract(1, 'year').startOf('year'),
    endDate: dayjs().subtract(1, 'month').endOf('month'),
  },
  { label: 'Custom', startDate: dayjs(), endDate: dayjs() },
];

const TimeSpanPicker: React.FC<TimeSpanPickerProps> = ({ onChange }) => {
  const [selectedSpan, setSelectedSpan] = useState<string>('Today');
  const [customStartDate, setCustomStartDate] = useState<Dayjs | null>(null);
  const [customEndDate, setCustomEndDate] = useState<Dayjs | null>(null);

  const handleSpanChange = (event: SelectChangeEvent<string>) => {
    const selectedLabel = event.target.value;
    setSelectedSpan(selectedLabel);

    const span = predefinedSpans.find((span) => span.label === selectedLabel);
    if (span && selectedLabel !== 'Custom') {
      onChange({ label: span.label, startDate: span.startDate, endDate: span.endDate });
    }
  };

  const handleCustomDateChange = (start: Dayjs | null, end: Dayjs | null) => {
    setCustomStartDate(start);
    setCustomEndDate(end);
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box display="flex" flexDirection="row" gap={2} maxWidth={'50%'}>
        <FormControl fullWidth>
          <Typography variant="subtitle1">Select Time Span</Typography>
          <Select value={selectedSpan} onChange={handleSpanChange}>
            {predefinedSpans.map((span) => (
              <MenuItem key={span.label} value={span.label}>
                {span.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Box
          display="flex"
          gap={2}
          sx={{ visibility: selectedSpan === 'Custom' ? 'visible' : 'hidden' }}
          alignItems="flex-end"
        >
          <DatePicker
            label="Start Date"
            value={customStartDate}
            onChange={(newValue) => handleCustomDateChange(newValue, customEndDate)}
            slots={{ textField: (params) => <TextField {...params} fullWidth /> }}
          />
          <DatePicker
            label="End Date"
            value={customEndDate}
            onChange={(newValue) => handleCustomDateChange(customStartDate, newValue)}
            slots={{ textField: (params) => <TextField {...params} fullWidth /> }}
          />
        </Box>
      </Box>
    </LocalizationProvider>
  );
};

export default TimeSpanPicker;
