import { ReduxDocument } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import React, { FC } from 'react';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { getFileExtension } from '../../utils/getFileExtension';
import Link from '@mui/material/Link';
import { ImagePreviewComponent } from '../../documents/components/ImagePreviewComponent';
import Box from '@mui/material/Box';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { ICamAssociation } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { useDispatch } from 'react-redux';
import { addAttachmentToAssociatedAssetAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/communications/communicationSlice';
import { ExpenseElementType } from '@monkeyjump-labs/cam-fe-shared/dist/types/expenseTypes';
import Tooltip from '@mui/material/Tooltip';

type AttachmentProps = {
  attachment: ReduxDocument;
  associations: ICamAssociation[];
  emailThreadId?: string;
  emailId: string;
  onOpenAddAttachmentDialog: (attachmentId?: string, emailId?: string) => void;
  onOpenAddExpenseElement: (attachment: ReduxDocument, type: ExpenseElementType, emailId: string) => void;
};

export const AttachmentMenu: FC<AttachmentProps> = ({
  attachment,
  associations,
  emailId,
  emailThreadId,
  onOpenAddAttachmentDialog,
  onOpenAddExpenseElement,
}) => {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const documentUrl = `${process.env.REACT_APP_API_URL}/email/${emailThreadId}/emails/${emailId}/document/${attachment.id}`;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleAddAttachmentToAssociations = (attachmentId?: string) => {
    if (!emailThreadId || !attachmentId) return;
    if (associations.length === 1) {
      dispatch(
        addAttachmentToAssociatedAssetAction({
          threadId: emailThreadId,
          emailId: emailId,
          attachmentId: attachmentId,
          association: associations[0],
        }),
      );
      handleCloseMenu();
    } else {
      onOpenAddAttachmentDialog(attachmentId, emailId);
      handleCloseMenu();
    }
  };

  return (
    <>
      <Tooltip title={attachment.name ?? ''}>
        <IconButton aria-label={'open-attachments-menu'} onClick={handleClick}>
          <AttachFileIcon fontSize={'small'} />
        </IconButton>
      </Tooltip>
      <Menu open={open} anchorEl={anchorEl} sx={{ zIndex: 9999 }} onClose={handleCloseMenu}>
        <MenuItem>
          <Box>
            <ImagePreviewComponent imageUrl={documentUrl} docType={getFileExtension(attachment.name)}>
              <Link href={documentUrl} download={attachment.name} color={'inherit'}>
                {attachment.name}
              </Link>
            </ImagePreviewComponent>
          </Box>
        </MenuItem>
        {associations.length > 0 && (
          <MenuItem onClick={() => handleAddAttachmentToAssociations(attachment.id)}>
            <NoteAddIcon />
            {associations.length === 1 ? 'Add to email thread Association' : 'Add to email thread Associations'}
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            onOpenAddExpenseElement(attachment, ExpenseElementType.Payment, emailId);
            handleCloseMenu();
          }}
        >
          Add Payment
        </MenuItem>
        <MenuItem
          onClick={() => {
            onOpenAddExpenseElement(attachment, ExpenseElementType.Invoice, emailId);
            handleCloseMenu();
          }}
        >
          Add Invoice
        </MenuItem>
        <MenuItem
          onClick={() => {
            onOpenAddExpenseElement(attachment, ExpenseElementType.PurchaseOrder, emailId);
            handleCloseMenu();
          }}
        >
          Add Purchase Order
        </MenuItem>
        <MenuItem
          onClick={() => {
            onOpenAddExpenseElement(attachment, ExpenseElementType.Quote, emailId);
            handleCloseMenu();
          }}
        >
          Add Quote
        </MenuItem>
        <MenuItem
          onClick={() => {
            onOpenAddExpenseElement(attachment, ExpenseElementType.WorkOrder, emailId);
            handleCloseMenu();
          }}
        >
          Add Work Order
        </MenuItem>
      </Menu>
    </>
  );
};
