import React, { FC, useEffect, useState } from 'react';
import { DialogLayout } from '../../../dialogs/DialogLayout';
import DialogContent from '@mui/material/DialogContent';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { ExpenseDialogTab, JobElementTab } from '../../../tabs/TabTypeEnums';
import { PaymentsTable } from '../expenseElementTables/PaymentsTable';
import {
  ExpenseElementType,
  getJobElementTypeFromItemNumber,
} from '@monkeyjump-labs/cam-fe-shared/dist/types/expenseTypes';
import { ExpenseDrawer } from '../drawer/ExpenseDrawer';
import { InvoiceTable } from '../expenseElementTables/InvoiceTable';
import { PurchaseOrderTable } from '../expenseElementTables/PurchaseOrderTable';
import { QuotesTable } from '../expenseElementTables/QuotesTable';
import { WorkOrderTable } from '../expenseElementTables/WorkOrderTable';
import { ExpenseInfo } from '../ExpenseInfo';
import { useDispatch } from 'react-redux';
import {
  addWatcherToExpenseAction,
  getSelectedExpenseByIdAction,
  removeWatcherFromExpenseAction,
  resetExpenseSubmissionAction,
  setSelectedExpenseAction,
  setSelectedInvoiceAction,
  setSelectedPaymentAction,
  setSelectedPurchaseOrderAction,
  setSelectedQuoteAction,
  setSelectedWorkOrderAction,
  unsetSelectedJobElementsAction,
  useExpenses,
} from '../../redux/expenseSlice';
import {
  AssociationType,
  IAddVendorHandlerRequest,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { clearSelectedPropertySubmissionAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/propertySlice';
import { AddExpenseElementAssociation, AddExpenseElementDialog } from './AddExpenseElementDialog';
import { useNavigate, useParams } from 'react-router-dom';
import { getAssetTypeFromPathname } from '../../../utils/getAssetTypeFromPathname';
import { ExpenseAnalysis } from '../analysis/ExpenseAnalysis';
import { useRouting } from '../../../utils/useRouting';
import { setAssociationSearchParentAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/search/searchSlice';
import {
  drawerChildIsOpenAction,
  showToastMessageAction,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import {
  attachmentAddedToAssociationAction,
  useCommunication,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/communications/communicationSlice';
import { StyledInfoBox } from '../../../styledComponents/StyledInfoBox';
import { MultiUserAutocomplete } from '../../../autocompletes/MultiUserAutocomplete';

type JobTabParams = {
  id: string;
  outerTab: string;
  innerTab: string;
  expenseId: string;
  tab: ExpenseDialogTab;
  itemNumber: string;
  innerExpenseTab: JobElementTab;
  innerExpenseItemId: string;
};

export const ExpenseDialog: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { handleNavigateFromAssociationClose } = useRouting();
  const { attachmentAddedToAssociation } = useCommunication();
  const { selectedExpense, selectedInvoice, selectedQuote, selectedPurchaseOrder, selectedWorkOrder, selectedPayment } =
    useExpenses();
  const { id, outerTab, innerTab, expenseId, tab, itemNumber, innerExpenseTab } = useParams<JobTabParams>();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openAddElementDialog, setOpenAddElementDialog] = useState(false);
  const [selectedTableType, setSelectedTableType] = useState<ExpenseElementType | undefined>();
  const [selectedDrawerType, setSelectedDrawerType] = useState<ExpenseElementType | undefined>();
  const [newVendor, setNewVendor] = useState<IAddVendorHandlerRequest | undefined>();
  const [addingElement, setAddingElement] = useState(false);
  const [isUpstream, setIsUpstream] = useState(false);
  const [downstreamDetails, setDownstreamDetails] = useState<AddExpenseElementAssociation | undefined>();
  const handleCloseAddElementDialog = () => {
    setIsUpstream(false);
    setOpenAddElementDialog(false);
    setAddingElement(false);
    setDownstreamDetails(undefined);
  };
  const handleOpenAddElementDialog = (
    type: ExpenseElementType,
    isUpstream?: boolean,
    detailsToAssociate?: AddExpenseElementAssociation,
  ) => {
    setSelectedTableType(type);
    setOpenDrawer(false);
    setOpenAddElementDialog(true);
    dispatch(clearSelectedPropertySubmissionAction());
    isUpstream && setIsUpstream(true);
    setDownstreamDetails(detailsToAssociate);
  };

  const handleOpenPOAssociationDialog = (lineItemNumber: string, changeOrderId?: string) => {
    if (!selectedExpense.value?.id || !selectedPurchaseOrder.value?.number) {
      dispatch(
        showToastMessageAction({
          message: 'Conditions not met for opening add association dialog',
          severity: 'error',
        }),
      );
    } else
      dispatch(
        setAssociationSearchParentAction({
          id: selectedExpense.value.id,
          type: AssociationType.ExpensePurchaseOrder,
          poInfo: {
            poNumber: selectedPurchaseOrder.value.number,
            changeOrderNo: changeOrderId,
            lineItemNo: lineItemNumber,
          },
        }),
      );
  };

  useEffect(() => {
    expenseId && dispatch(getSelectedExpenseByIdAction(expenseId));
    if (!expenseId) {
      dispatch(setSelectedExpenseAction(undefined));
    }
  }, [
    expenseId,
    selectedExpense.submitted,
    selectedPayment.submitted,
    selectedPurchaseOrder.submitted,
    selectedQuote.submitted,
    selectedWorkOrder.submitted,
    selectedInvoice.submitted,
  ]);

  useEffect(() => {
    if (attachmentAddedToAssociation === AssociationType.Expense) {
      expenseId && dispatch(getSelectedExpenseByIdAction(expenseId));
      dispatch(attachmentAddedToAssociationAction(undefined));
    }
  }, [attachmentAddedToAssociation]);

  useEffect(() => {
    handleUpdateSelectedInternalItem();
  }, [selectedExpense.value]);

  const handleUpdateSelectedInternalItem = () => {
    if (itemNumber) {
      const selectedInternalItem = getJobElementTypeFromItemNumber(itemNumber);
      if (selectedInternalItem) {
        handleOpenDrawer(itemNumber, selectedInternalItem, true);
      }
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: ExpenseDialogTab) => {
    switch (newValue) {
      case ExpenseDialogTab.Payments:
        setSelectedTableType(ExpenseElementType.Payment);
        break;
      case ExpenseDialogTab.Invoices:
        setSelectedTableType(ExpenseElementType.Invoice);
        break;
      case ExpenseDialogTab.Quotes:
        setSelectedTableType(ExpenseElementType.Quote);
        break;
      case ExpenseDialogTab.PurchaseOrders:
        setSelectedTableType(ExpenseElementType.PurchaseOrder);
        break;
      case ExpenseDialogTab.WorkOrders:
        setSelectedTableType(ExpenseElementType.WorkOrder);
        break;
      default:
        setSelectedTableType(undefined);
    }
    if (openDrawer) setOpenDrawer(false);
    dispatch(unsetSelectedJobElementsAction());
    navigate(`/assets/${getAssetTypeFromPathname()}/${id}/${outerTab}/${innerTab}/expense/${expenseId}/${newValue}`, {
      replace: true,
    });
  };

  const handleClose = () => {
    if (openDrawer) {
      handleCloseDrawer();
      return;
    }
    handleNavigateFromAssociationClose();
    dispatch(setSelectedExpenseAction(undefined));
  };

  const handleSelectJobElement = (number?: string, type?: ExpenseElementType, skipNavigate = false) => {
    switch (type) {
      case ExpenseElementType.Invoice:
        const invoice = selectedExpense.value?.invoices?.find((p) => p.number === number);
        if (invoice) {
          dispatch(setSelectedInvoiceAction(invoice));
        }
        break;
      case ExpenseElementType.Payment:
        const payment = selectedExpense?.value?.payments?.find((p) => p.number === number);
        if (payment) {
          dispatch(setSelectedPaymentAction(payment));
        }
        break;
      case ExpenseElementType.PurchaseOrder:
        const po = selectedExpense.value?.purchaseOrders?.find((p) => p.number === number);
        if (po) {
          dispatch(setSelectedPurchaseOrderAction(po));
        }
        break;
      case ExpenseElementType.Quote:
        const quote = selectedExpense.value?.quotes?.find((p) => p.number === number);
        if (quote) {
          dispatch(setSelectedQuoteAction(quote));
        }
        break;
      case ExpenseElementType.WorkOrder:
        const wo = selectedExpense.value?.workOrders?.find((p) => p.number === number);
        if (wo) {
          dispatch(setSelectedWorkOrderAction(wo));
        }
        break;
      default:
        break;
    }
    if (!skipNavigate)
      navigate(
        `/assets/${getAssetTypeFromPathname()}/${id}/${outerTab}/${innerTab}/expense/${expenseId}/${tab}/${number}`,
        { replace: true },
      );
  };

  const handleOpenDrawer = (number?: string, type?: ExpenseElementType, skipNavigate = false) => {
    handleSelectJobElement(number, type, skipNavigate);
    type && setSelectedDrawerType(type);
    setOpenDrawer(true);
    dispatch(drawerChildIsOpenAction(true));
  };

  const handleCloseDrawer = () => {
    dispatch(unsetSelectedJobElementsAction());
    dispatch(resetExpenseSubmissionAction());
    setNewVendor(undefined);
    setOpenDrawer(false);
    setSelectedDrawerType(undefined);
    navigate(`/assets/${getAssetTypeFromPathname()}/${id}/${outerTab}/${innerTab}/expense/${expenseId}/${tab}`, {
      replace: true,
    });
  };

  const handleAddNewVendor = (name: string) => {
    if (openAddElementDialog) setAddingElement(true);
    setOpenAddElementDialog(false);
    setNewVendor({ name });
    setOpenDrawer(true);
  };

  const handleUpdateWatchers = (updatedWatchers: string[]) => {
    const watchers = selectedExpense.value?.watchers?.map((watcher) => watcher.id ?? '') ?? [];
    const addUser = updatedWatchers.filter((watcher) => !watchers.some((w) => w === watcher));
    if (addUser.length === 1) {
      dispatch(
        addWatcherToExpenseAction({
          expenseId: selectedExpense.value?.id ?? '',
          userId: addUser[0],
        }),
      );
    }
    const removeUser = watchers.filter((watcher) => !updatedWatchers.some((w) => w === watcher));
    if (removeUser.length === 1) {
      dispatch(
        removeWatcherFromExpenseAction({
          expenseId: selectedExpense.value?.id ?? '',
          userId: removeUser[0],
        }),
      );
    }
  };

  const getUpstreamType = (type: ExpenseElementType | undefined): ExpenseElementType | undefined => {
    switch (type) {
      case ExpenseElementType.Invoice:
        return ExpenseElementType.Payment;
      case ExpenseElementType.PurchaseOrder:
        return ExpenseElementType.Invoice;
      case ExpenseElementType.Quote:
        return ExpenseElementType.PurchaseOrder;
      case ExpenseElementType.WorkOrder:
        return ExpenseElementType.Quote;
      default:
        return undefined;
    }
  };

  const onChangeInnerTab = (innerExpenseTab: JobElementTab) => {
    navigate(
      `/assets/${getAssetTypeFromPathname()}/${id}/${outerTab}/${innerTab}/expense/${expenseId}/${tab}/${itemNumber}/${innerExpenseTab}`,
      {
        replace: true,
      },
    );
  };

  const assetPath = selectedExpense.value?.unitName
    ? `${selectedExpense.value?.propertyName} / ${selectedExpense.value.buildingName} / ${selectedExpense.value.unitName}`
    : selectedExpense.value?.buildingName
      ? `${selectedExpense.value?.propertyName} / ${selectedExpense.value?.buildingName}`
      : `${selectedExpense.value?.propertyName}`;

  return (
    <DialogLayout
      title={`Expense: ${selectedExpense.value?.name}`}
      subtitle={assetPath}
      onClose={handleClose}
      open={selectedExpense.value !== undefined}
      fullWidth
      maxWidth={'lg'}
      fullScreen
      style={{ zIndex: '999' }}
    >
      <DialogContent sx={{ mt: '.1rem' }}>
        <Tabs value={tab} onChange={(e, v) => handleTabChange(e, v)}>
          <Tab value={ExpenseDialogTab.Info} label={'Info'} />
          <Tab value={ExpenseDialogTab.Payments} label={'Payments'} />
          <Tab value={ExpenseDialogTab.Invoices} label={'Invoices'} />
          <Tab value={ExpenseDialogTab.PurchaseOrders} label={'Purchase Orders'} />
          <Tab value={ExpenseDialogTab.Quotes} label={'Quotes'} />
          <Tab value={ExpenseDialogTab.WorkOrders} label={'Work Orders'} />
          <Tab value={ExpenseDialogTab.Analysis} label={'Analysis'} />
        </Tabs>
        <>
          {
            {
              info: selectedExpense ? (
                <>
                  <ExpenseInfo />
                  <StyledInfoBox label={'Watchers'}>
                    <MultiUserAutocomplete
                      setWatchers={(updateWatchers) => handleUpdateWatchers(updateWatchers)}
                      watchers={selectedExpense.value?.watchers?.map((x) => x.id!) ?? []}
                    />
                  </StyledInfoBox>
                </>
              ) : (
                <></>
              ),
              payments: <PaymentsTable onOpenDrawer={handleOpenDrawer} onOpenAddPayment={handleOpenAddElementDialog} />,
              invoices: (
                <InvoiceTable onOpenDrawer={handleOpenDrawer} onOpenAddExpenseChildItem={handleOpenAddElementDialog} />
              ),
              purchaseOrders: (
                <PurchaseOrderTable
                  onOpenDrawer={handleOpenDrawer}
                  onOpenAddExpenseChildItem={handleOpenAddElementDialog}
                />
              ),
              quotes: (
                <QuotesTable onOpenDrawer={handleOpenDrawer} onOpenAddExpenseChildItem={handleOpenAddElementDialog} />
              ),
              workOrders: (
                <WorkOrderTable
                  onSelectWorkOrder={handleOpenDrawer}
                  onOpenAddExpenseChildItem={handleOpenAddElementDialog}
                  onOpenDrawer={handleOpenDrawer}
                />
              ),
              analysis: <ExpenseAnalysis />,
            }[tab ?? ExpenseDialogTab.Info]
          }
        </>
        <ExpenseDrawer
          open={openDrawer}
          onCloseDrawer={handleCloseDrawer}
          type={selectedDrawerType}
          innerTabType={innerExpenseTab ?? JobElementTab.Info}
          newVendor={newVendor}
          onAddNewVendor={handleAddNewVendor}
          addingElement={addingElement}
          onOpenAddElement={() => {
            setOpenDrawer(false);
            setOpenAddElementDialog(true);
            setNewVendor(undefined);
            setAddingElement(false);
          }}
          onClearNewVendor={() => {
            setNewVendor(undefined);
            if (addingElement) {
              setOpenDrawer(false);
              setOpenAddElementDialog(true);
            }
          }}
          onPOLineItemAssociation={handleOpenPOAssociationDialog}
          onTabChange={onChangeInnerTab}
        />
        <AddExpenseElementDialog
          onClose={handleCloseAddElementDialog}
          type={isUpstream ? getUpstreamType(selectedTableType) : selectedTableType}
          open={openAddElementDialog}
          onAddNewVendor={handleAddNewVendor}
          downstreamDetails={downstreamDetails}
        />
      </DialogContent>
    </DialogLayout>
  );
};
