import React, { FC } from 'react';
import { GridRenderCellParams, GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid-premium';
import {
  ExpenseInvoiceStatus,
  ExpensePaymentExpensePaymentStatus,
  ExpensePurchaseOrderStatus,
  ExpenseQuoteStatus,
  ExpenseStatus,
  WorkOrderStatus,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import Typography from '@mui/material/Typography';
import { ExpenseElementType } from '@monkeyjump-labs/cam-fe-shared/dist/types/expenseTypes';
import { useDispatch } from 'react-redux';
import { ExpenseStatusSelect } from '../../ExpenseStatusSelect';
import {
  updateExpensePaymentStatusAction,
  updateExpenseStatusAction,
  updateInvoiceStatusAction,
  updatePurchaseOrderStatusAction,
  updateQuoteStatusAction,
  updateWorkOrderStatusAction,
  useExpenses,
} from '../../../redux/expenseSlice';

type StatusCellProps = {
  statusMap: Map<
    | ExpenseStatus
    | WorkOrderStatus
    | ExpensePurchaseOrderStatus
    | ExpenseInvoiceStatus
    | ExpenseQuoteStatus
    | ExpensePaymentExpensePaymentStatus,
    string
  >;
} & GridRenderCellParams;

export const StatusCell: FC<StatusCellProps> = ({ statusMap, ...params }) => {
  return (
    <>
      {Array.from(statusMap).map(([status, label]) =>
        status === params.value ? (
          <Typography variant="body2" key={status}>
            {label}
          </Typography>
        ) : (
          <Typography key={status}>{''}</Typography>
        ),
      )}
    </>
  );
};

type StatusEditCellProps = {
  type: ExpenseElementType;
  statusMap: Map<
    | ExpenseStatus
    | WorkOrderStatus
    | ExpensePurchaseOrderStatus
    | ExpenseInvoiceStatus
    | ExpenseQuoteStatus
    | ExpensePaymentExpensePaymentStatus,
    string
  >;
} & GridRenderEditCellParams;

export const StatusEditCell: FC<StatusEditCellProps> = ({ type, statusMap, ...params }) => {
  const dispatch = useDispatch();
  const { selectedExpense } = useExpenses();
  const { id, field } = params;
  const apiRef = useGridApiContext();

  function handleStatusChange<
    T extends
      | ExpenseStatus
      | WorkOrderStatus
      | ExpensePurchaseOrderStatus
      | ExpenseInvoiceStatus
      | ExpenseQuoteStatus
      | ExpensePaymentExpensePaymentStatus,
  >(type: ExpenseElementType, params: GridRenderEditCellParams, statusMap: Map<T, string>, status: string) {
    let newValue: T | undefined;
    Array.from(statusMap).forEach(([s]) => {
      if (status === s) {
        newValue = s;
        switch (type) {
          case ExpenseElementType.Expense:
            dispatch(
              updateExpenseStatusAction({
                id: params.row.id!,
                body: {
                  propertyId: params.row.propertyId,
                  status: s as ExpenseStatus,
                },
              }),
            );
            break;
          case ExpenseElementType.Payment:
            selectedExpense.value?.id &&
              dispatch(
                updateExpensePaymentStatusAction({
                  expenseId: selectedExpense.value?.id,
                  number: params.row.number,
                  status: s as ExpensePaymentExpensePaymentStatus,
                }),
              );
            break;
          case ExpenseElementType.Quote:
            selectedExpense.value?.id &&
              dispatch(
                updateQuoteStatusAction({
                  expenseId: selectedExpense.value?.id,
                  number: params.row.number,
                  status: s as ExpenseQuoteStatus,
                }),
              );
            break;
          case ExpenseElementType.PurchaseOrder:
            selectedExpense.value?.id &&
              dispatch(
                updatePurchaseOrderStatusAction({
                  expenseId: selectedExpense.value?.id,
                  number: params.row.number,
                  status: s as ExpensePurchaseOrderStatus,
                }),
              );
            break;
          case ExpenseElementType.Invoice:
            selectedExpense.value?.id &&
              dispatch(
                updateInvoiceStatusAction({
                  expenseId: selectedExpense.value?.id,
                  number: params.row.number,
                  status: s as ExpenseInvoiceStatus,
                }),
              );
            break;
          case ExpenseElementType.WorkOrder:
            selectedExpense.value?.id &&
              dispatch(
                updateWorkOrderStatusAction({
                  expenseId: selectedExpense.value?.id,
                  number: params.row.number,
                  status: s as WorkOrderStatus,
                }),
              );
            break;
        }
      }
    });
    apiRef.current.setEditCellValue({ id, field, value: newValue });
    apiRef.current.stopCellEditMode({ id, field });
  }

  return (
    <ExpenseStatusSelect
      value={params.value}
      onChange={(e) => handleStatusChange(type, params, statusMap, e.target.value)}
      statusMap={statusMap}
      type={type}
      size={'small'}
      ref={apiRef}
    />
  );
};
