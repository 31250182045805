import React, { FC, FormEvent, useEffect, useState } from 'react';
import { PaymentForm } from './PaymentForm';
import { InvoiceForm } from './InvoiceForm';
import { PurchaseOrderForm } from './PurchaseOrderForm';
import { QuoteForm } from './QuoteForm';
import { WorkOrderForm } from './WorkOrderForm';
import {
  blankInvoice,
  blankPayment,
  blankPurchaseOrder,
  blankQuote,
  blankWorkOrder,
  ExpenseElementType,
  ReduxExpense,
  ReduxExpensePayment,
  ReduxInvoice,
  ReduxPurchaseOrder,
  ReduxQuote,
  ReduxWorkOrder,
  updateEditingPurchaseOrderLineItem,
} from '@monkeyjump-labs/cam-fe-shared/dist/types/expenseTypes';
import {
  addInvoiceAction,
  addPaymentAction,
  addPurchaseOrderAction,
  addQuoteAction,
  addWorkOrderAction,
  resetExpenseSubmissionAction,
  setInvoiceSubmittingValueAction,
  setPaymentSubmittingValueAction,
  setPurchaseOrderSubmittingValueAction,
  setQuoteSubmittingValueAction,
  useExpenses,
} from '../../redux/expenseSlice';
import { useDispatch } from 'react-redux';
import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import { AddExpenseElementAssociation, AddExpenseElementFromEmail } from '../dialogs/AddExpenseElementDialog';
import { updateEditingRows } from '../../../utils/updateEditingRows';
import {
  IContactPerson,
  IExpensePurchaseOrderItem,
  ISlimAccountRef,
  IVendor,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogContent from '@mui/material/DialogContent';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';

type AddExpenseElementProps = {
  type?: ExpenseElementType;
  expense?: ReduxExpense;
  open: boolean;
  onClose: () => void;
  onAddNewVendor?: (name: string) => void;
  gridRef: React.RefObject<GridApiPremium>;
  downstreamDetails?: AddExpenseElementAssociation;
  attachmentDetails?: AddExpenseElementFromEmail;
  onWideModal?: () => void;
  title?: string;
  setTitle: (title: string) => void;
  setVendor?: (vendor: IVendor | undefined) => void;
};

export const AddExpenseElement: FC<AddExpenseElementProps> = ({
  type,
  expense,
  gridRef,
  onClose,
  onAddNewVendor,
  downstreamDetails,
  attachmentDetails,
  open,
  onWideModal,
  title,
  setTitle,
  setVendor,
}) => {
  const dispatch = useDispatch();
  const { selectedContext } = useAssets();

  const { selectedPayment, selectedInvoice, selectedPurchaseOrder, selectedWorkOrder, selectedQuote } = useExpenses();
  const [submittingExpense, setSubmittingExpense] = useState(expense);
  const [newPayment, setNewPayment] = useState<ReduxExpensePayment>(selectedPayment.submittingValue ?? blankPayment);
  const [newInvoice, setNewInvoice] = useState<ReduxInvoice>(selectedInvoice.submittingValue ?? blankPayment);
  const [newPurchaseOrder, setNewPurchaseOrder] = useState<ReduxPurchaseOrder>(
    selectedPurchaseOrder.submittingValue ?? blankPurchaseOrder,
  );
  const [newWorkOrder, setNewWorkOrder] = useState<ReduxWorkOrder>(selectedWorkOrder.submittingValue ?? blankWorkOrder);
  const [newQuote, setNewQuote] = useState<ReduxQuote>(selectedQuote.submittingValue ?? blankQuote);
  const [propertyAccount, setPropertyAccount] = useState<ISlimAccountRef>();

  const jobId = submittingExpense?.id;
  const propertyId = submittingExpense?.propertyId ?? selectedContext.propertyId;
  const buildingId = submittingExpense?.buildingId;

  const loading =
    selectedPayment.submitting ||
    selectedInvoice.submitting ||
    selectedQuote.submitting ||
    selectedWorkOrder.submitting ||
    selectedPurchaseOrder.submitting;

  useEffect(() => {
    if (
      selectedPayment.submitted ||
      selectedInvoice.submitted ||
      selectedQuote.submitted ||
      selectedWorkOrder.submitted ||
      selectedPurchaseOrder.submitted
    )
      handleClose();
  }, [
    selectedPayment.submitted,
    selectedInvoice.submitted,
    selectedQuote.submitted,
    selectedWorkOrder.submitted,
    selectedPurchaseOrder.submitted,
  ]);

  useEffect(() => {
    setSubmittingExpense(expense);
  }, [expense]);

  useEffect(() => {
    if (open) {
      setPropertyAccount(downstreamDetails?.propertyAccount);
      switch (type) {
        case ExpenseElementType.Payment:
          setNewPayment(
            selectedPayment.submittingValue ?? {
              ...blankPayment,
              associatedInvoice: downstreamDetails?.association,
              vendor: downstreamDetails?.vendor,
              expenseAccount: downstreamDetails?.propertyAccount,
            },
          );
          setTitle('Add Payment');
          break;
        case ExpenseElementType.Invoice:
          setNewInvoice(
            selectedInvoice.submittingValue ?? {
              ...blankInvoice,
              associatedPurchaseOrder: downstreamDetails?.association,
              vendor: downstreamDetails?.vendor,
            },
          );
          setPropertyAccount(downstreamDetails?.propertyAccount);
          setTitle('Add Invoice');
          break;
        case ExpenseElementType.PurchaseOrder:
          setNewPurchaseOrder(
            selectedPurchaseOrder.submittingValue ?? {
              ...blankPurchaseOrder,
              associatedQuotes: [downstreamDetails?.association].filter((x) => x !== undefined),
              vendor: downstreamDetails?.vendor,
            },
          );
          setTitle('Add Purchase Order');
          break;
        case ExpenseElementType.WorkOrder:
          setNewWorkOrder(selectedWorkOrder.submittingValue ?? blankWorkOrder);
          setTitle('Add Work Order');
          break;
        case ExpenseElementType.Quote:
          setNewQuote(
            selectedQuote.submittingValue ?? {
              ...blankQuote,
              associatedWorkOrder: downstreamDetails?.association,
              vendor: downstreamDetails?.vendor,
            },
          );
          setTitle('Add Quote');
          break;
        default:
          break;
      }
    }
  }, [open, type]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (!jobId || !propertyId) return;
    switch (type) {
      case ExpenseElementType.Payment:
        const paymentBody = {
          jobId,
          propertyId,
          buildingId,
          attachmentDetails,
          body: {
            ...newPayment,
            paymentSource: newPayment.payment,
            propertyAccountId: newPayment.propertyAccount?.id,
            expenseAccountId: newPayment.expenseAccount?.id,
          },
        };
        dispatch(addPaymentAction(paymentBody));
        break;
      case ExpenseElementType.Invoice:
        dispatch(
          addInvoiceAction({
            jobId,
            propertyId,
            buildingId,
            attachmentDetails,
            body: { ...newInvoice, propertyAccount: propertyAccount ?? undefined },
          }),
        );
        break;
      case ExpenseElementType.Quote:
        dispatch(
          addQuoteAction({
            jobId,
            propertyId,
            attachmentDetails,
            body: { ...newQuote, propertyAccount: propertyAccount ?? undefined },
          }),
        );
        break;
      case ExpenseElementType.PurchaseOrder:
        const updatedPurchaseOrder = updateEditingRows<ReduxPurchaseOrder, IExpensePurchaseOrderItem>(
          gridRef,
          newPurchaseOrder,
          'items',
          updateEditingPurchaseOrderLineItem,
        );
        dispatch(
          addPurchaseOrderAction({
            jobId,
            propertyId,
            attachmentDetails,
            body: {
              ...updatedPurchaseOrder,
              propertyAccount: propertyAccount ?? undefined,
              items: updatedPurchaseOrder.items?.filter((i) => i.name !== undefined),
            },
          }),
        );
        break;
      case ExpenseElementType.WorkOrder:
        dispatch(
          addWorkOrderAction({
            jobId,
            propertyId,
            attachmentDetails,
            body: { ...newWorkOrder, propertyAccountId: propertyAccount?.id },
          }),
        );
        break;
      default:
        break;
    }
  };

  const handleChangeInvoice = <TKey extends keyof ReduxInvoice>(field: TKey, value: ReduxInvoice[TKey]) => {
    setNewInvoice({ ...newInvoice, [field]: value });
  };

  const handleChangePayment = <TKey extends keyof ReduxExpensePayment>(
    field: TKey,
    value: ReduxExpensePayment[TKey],
  ) => {
    setNewPayment({ ...newPayment, [field]: value });
  };

  const handleChangeQuote = <TKey extends keyof ReduxQuote>(field: TKey, value: ReduxQuote[TKey]) => {
    setNewQuote({ ...newQuote, [field]: value });
  };

  const handleChangePurchaseOrder = <T extends keyof ReduxPurchaseOrder>(field: T, value: ReduxPurchaseOrder[T]) => {
    setNewPurchaseOrder({ ...newPurchaseOrder, [field]: value });
  };

  const handleChangeWorkOrder = <TKey extends keyof ReduxWorkOrder>(field: TKey, value: ReduxWorkOrder[TKey]) => {
    setNewWorkOrder({ ...newWorkOrder, [field]: value });
  };

  const handleChangeVendor = (value: IVendor | undefined) => {
    switch (type) {
      case ExpenseElementType.Payment:
        setNewPayment({ ...newPayment, vendor: value });
        setVendor && setVendor(value);
        break;
      case ExpenseElementType.Invoice:
        setNewInvoice({ ...newInvoice, vendor: value });
        setVendor && setVendor(value);
        break;
      case ExpenseElementType.PurchaseOrder:
        setNewPurchaseOrder({ ...newPurchaseOrder, vendor: value });
        setVendor && setVendor(value);
        break;
      case ExpenseElementType.Quote:
        setNewQuote({ ...newQuote, vendor: value });
        setVendor && setVendor(value);
        break;
      default:
        break;
    }
  };

  const handleChangeVendorContact = (value: IContactPerson | undefined) => {
    switch (type) {
      case ExpenseElementType.Payment:
        setNewPayment({ ...newPayment, vendorContact: value });
        break;
      case ExpenseElementType.Invoice:
        setNewInvoice({ ...newInvoice, vendorContact: value });
        break;
      case ExpenseElementType.PurchaseOrder:
        setNewPurchaseOrder({ ...newPurchaseOrder, vendorContact: value });
        break;
      case ExpenseElementType.Quote:
        setNewQuote({ ...newQuote, vendorContact: value });
        break;
    }
  };

  const handleChangeAssociation = (association?: string, vendor?: IVendor, newAccount?: ISlimAccountRef) => {
    switch (type) {
      case ExpenseElementType.Payment:
        setNewPayment({
          ...newPayment,
          associatedInvoice: association,
          vendor: vendor ?? newPayment.vendor,
          expenseAccount: newAccount,
        });
        break;
      case ExpenseElementType.Invoice:
        setNewInvoice({
          ...newInvoice,
          associatedPurchaseOrder: association,
          vendor: vendor ?? newInvoice.vendor,
        });
        break;
      case ExpenseElementType.Quote:
        setNewQuote({
          ...newQuote,
          associatedWorkOrder: association,
          vendor: vendor ?? newQuote.vendor,
        });
        break;
      default:
        break;
    }
    newAccount && setPropertyAccount(newAccount);
  };

  const handlePurchaseOrderQuoteAssociation = (associations?: string[]) => {
    setNewPurchaseOrder({
      ...newPurchaseOrder,
      associatedQuotes: associations,
    });
  };

  const handleChangeExpenseAccount = (account: ISlimAccountRef) => setPropertyAccount(account);
  const handleClose = () => {
    onClose();
    setNewPayment(blankPayment);
    setNewInvoice(blankInvoice);
    setNewQuote(blankQuote);
    setNewPurchaseOrder(blankPurchaseOrder);
    setNewWorkOrder(blankWorkOrder);
    setPropertyAccount(undefined);
    dispatch(resetExpenseSubmissionAction());
  };

  const handleSetNewSubmittingValue = (name: string) => {
    onAddNewVendor?.(name);
    switch (type) {
      case ExpenseElementType.Payment:
        dispatch(setPaymentSubmittingValueAction({ ...newPayment, vendor: { ...newPayment.vendor, name: name } }));
        break;
      case ExpenseElementType.Invoice:
        dispatch(setInvoiceSubmittingValueAction({ ...newInvoice, vendor: { ...newInvoice.vendor, name: name } }));
        break;
      case ExpenseElementType.PurchaseOrder:
        dispatch(
          setPurchaseOrderSubmittingValueAction({
            ...newPurchaseOrder,
            vendor: { ...newPurchaseOrder.vendor, name: name },
          }),
        );
        break;
      case ExpenseElementType.Quote:
        dispatch(setQuoteSubmittingValueAction({ ...newQuote, vendor: { ...newQuote.vendor, name: name } }));
        break;
      default:
        break;
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <DialogContent>
        {
          {
            payment: (
              <PaymentForm
                selectedExpense={submittingExpense}
                value={newPayment}
                onChangePayment={handleChangePayment}
                onChangeVendor={handleChangeVendor}
                propertyId={propertyId!}
                onAddNewVendor={onAddNewVendor ? handleSetNewSubmittingValue : undefined}
                onAssociateInvoice={handleChangeAssociation}
                onChangeVendorContact={handleChangeVendorContact}
              />
            ),
            invoice: (
              <InvoiceForm
                selectedExpense={submittingExpense}
                value={newInvoice}
                onChangeInvoice={handleChangeInvoice}
                onChangeVendor={handleChangeVendor}
                propertyId={propertyId!}
                onChangeExpenseAccount={handleChangeExpenseAccount}
                expenseAccount={propertyAccount}
                onAddNewVendor={onAddNewVendor ? handleSetNewSubmittingValue : undefined}
                onAssociatePurchaseOrder={handleChangeAssociation}
                onChangeVendorContact={handleChangeVendorContact}
              />
            ),
            purchaseOrder: (
              <PurchaseOrderForm
                selectedExpense={submittingExpense}
                value={newPurchaseOrder}
                onChangePurchaseOrder={handleChangePurchaseOrder}
                onChangeVendor={handleChangeVendor}
                propertyId={propertyId!}
                onChangeExpenseAccount={handleChangeExpenseAccount}
                expenseAccount={propertyAccount}
                onAddNewVendor={onAddNewVendor ? handleSetNewSubmittingValue : undefined}
                onAssociateQuote={handlePurchaseOrderQuoteAssociation}
                onChangeVendorContact={handleChangeVendorContact}
                onWidenModal={onWideModal}
                gridApiRef={gridRef}
              />
            ),
            quote: (
              <QuoteForm
                selectedExpense={submittingExpense}
                value={newQuote}
                onChangeQuote={handleChangeQuote}
                onChangeVendor={handleChangeVendor}
                propertyId={propertyId!}
                onChangeExpenseAccount={handleChangeExpenseAccount}
                expenseAccount={propertyAccount}
                onAddNewVendor={onAddNewVendor ? handleSetNewSubmittingValue : undefined}
                onAssociateWorkOrder={handleChangeAssociation}
                onChangeVendorContact={handleChangeVendorContact}
              />
            ),
            workOrder: (
              <WorkOrderForm
                value={newWorkOrder}
                onChangeWorkOrder={handleChangeWorkOrder}
                propertyId={propertyId!}
                expenseAccount={propertyAccount}
                onChangeExpenseAccount={handleChangeExpenseAccount}
              />
            ),
            job: <></>,
          }[type ?? ExpenseElementType.Expense]
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <LoadingButton variant={'contained'} type={'submit'} loading={loading}>
          {title}
        </LoadingButton>
      </DialogActions>
    </form>
  );
};
