import React, { FC, useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Menu from '@mui/material/Menu';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import {
  clearNewNotificationAction,
  getMoreNotificationsByUserAction,
  getNotificationsByUserAction,
  useNotifications,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/notifications/notificationSlice';
import { useDispatch } from 'react-redux';
import { NotificationMenuItem } from './NotificationsMenuItem';
import MenuItem from '@mui/material/MenuItem';
import { ReduxUserNotification } from '@monkeyjump-labs/cam-fe-shared/dist/types/notificationTypes';
import { useAuth0 } from '@auth0/auth0-react';
import Divider from '@mui/material/Divider';
import Refresh from '@mui/icons-material/Refresh';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Stack from '@mui/material/Stack';
import './notificationsMenu.css';

export type NotificationsMenuProps = {
  isAuthTokenSet: boolean;
};

export const NotificationsMenu: FC<NotificationsMenuProps> = ({ isAuthTokenSet }) => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useAuth0();
  const notifications = useNotifications();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showBounce, setShowBounce] = useState(false);
  const [showSlideNotification, setShowSlideNotification] = useState(false);
  const [notificationPreviewText, setNotificationPreviewText] = useState('');

  useEffect(() => {
    if (isAuthenticated && isAuthTokenSet) {
      dispatch(getNotificationsByUserAction());
    }
  }, [isAuthenticated, isAuthTokenSet]);

  useEffect(() => {
    if (notifications.globalNotifications?.selectedValue) {
      const timer = setTimeout(() => {
        handleNewNotification(`${notifications.globalNotifications?.selectedValue?.title}:
            ${notifications.globalNotifications?.selectedValue?.description}`);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [notifications.globalNotifications?.selectedValue]);

  const handleNewNotification = (text: string) => {
    // bell ring
    setShowBounce(true);

    //slide notification
    setNotificationPreviewText(text);
    setShowSlideNotification(true);

    setTimeout(() => setShowBounce(false), 800);
    setTimeout(() => setShowSlideNotification(false), 2000);
  };

  useEffect(() => {
    if (!showSlideNotification) {
      dispatch(clearNewNotificationAction());
    }
  }, [showSlideNotification]);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    dispatch(getNotificationsByUserAction());
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRefreshNotifications = () => {
    dispatch(getNotificationsByUserAction());
  };

  const handleGetMoreNotifications = () => {
    dispatch(getMoreNotificationsByUserAction());
  };

  const handleBadgeContent = (): string | undefined => {
    if (!notifications.globalNotifications.value || notifications.globalNotifications.value.length === 0)
      return undefined;
    return `${notifications.globalNotifications.value.length}`;
  };

  return (
    <>
      <div style={{ position: 'relative', display: 'flex' }}>
        <IconButton
          size="large"
          aria-label="notifications"
          aria-controls="notifications-menu"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
        >
          <Badge badgeContent={handleBadgeContent()} color="error">
            <div className={`bell-icon ${showBounce ? 'ring-pop' : ''}`}>
              <NotificationsIcon sx={{ color: 'white' }} />
            </div>
          </Badge>
        </IconButton>
        {/* Conditionally render the slide-out div */}
        {showSlideNotification && (
          <div
            className="notification-slide"
            style={{
              width: 250,
              fontStyle: 'italic',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: 'inline-block',
            }}
          >
            {notificationPreviewText}
          </div>
        )}
      </div>
      <Menu
        id="notifications-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Box sx={{ border: '.1rem solid lightgray', mx: '1rem', my: '.5rem', minWidth: '35rem' }}>
          <Stack>
            {notifications.globalNotifications.value?.length === 0 || !notifications.globalNotifications.value ? (
              <MenuItem>No New Notifications</MenuItem>
            ) : (
              <>
                <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'right' }}>
                  <IconButton onClick={handleRefreshNotifications}>
                    <Refresh />
                  </IconButton>
                </Box>
                <Divider />
                {notifications.globalNotifications.value?.map((notification: ReduxUserNotification) => (
                  <NotificationMenuItem key={notification.id} notification={notification} onClose={handleClose} />
                ))}
                <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'right' }}>
                  <IconButton onClick={handleGetMoreNotifications}>
                    <ExpandMoreIcon />
                  </IconButton>
                </Box>
              </>
            )}
          </Stack>
        </Box>
      </Menu>
    </>
  );
};
