import React, { FC, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Add from '@mui/icons-material/Add';
import { StripedDataGrid, styledFilterPanelProps } from '../../../datagrids/StripedDataGrid';
import Stack from '@mui/material/Stack';
import { ExpenseElementType, quoteStatusMap, ReduxQuote } from '@monkeyjump-labs/cam-fe-shared/dist/types/expenseTypes';
import { GridColDef, GridRenderCellParams, GridRenderEditCellParams, GridRowParams } from '@mui/x-data-grid-premium';
import { NameCell } from './cells/NameCell';
import { tryFormatDate } from '../../../utils/TryFormatDate';
import { removeQuoteAction, useExpenses } from '../../redux/expenseSlice';
import { GlCodeCell } from './cells/GlCodeCell';
import { StatusCell, StatusEditCell } from './cells/StatusCell';
import { ActionCell } from '../../../datagrids/ActionCell';
import { useDispatch } from 'react-redux';
import { AssociateElementDialog } from '../dialogs/AssociateElementDialog';
import { InternalAssociationCell } from './cells/InternalAssociationCell';
import { VendorDetailsCell } from './cells/VendorDetailsCell';
import EditIcon from '@mui/icons-material/Edit';
import { useIcons } from '../../../icons/useIcons';
import { AddExpenseElementAssociation } from '../dialogs/AddExpenseElementDialog';

type QuotesTableProps = {
  onOpenDrawer: (number?: string, type?: ExpenseElementType) => void;
  onOpenAddExpenseChildItem: (
    type: ExpenseElementType,
    isUpstream?: boolean,
    downstreamDetails?: AddExpenseElementAssociation,
  ) => void;
};

export const QuotesTable: FC<QuotesTableProps> = ({ onOpenDrawer, onOpenAddExpenseChildItem }) => {
  const { selectedExpense } = useExpenses();
  const { getActionIcon, ActionType } = useIcons();
  const dispatch = useDispatch();
  const rows = selectedExpense.value?.quotes ?? [];
  const [openAssociationDialog, setOpenAssociationDialog] = useState(false);
  const [selectedNumber, setSelectedNumber] = useState<string | undefined>();

  function* createActions(params: GridRowParams<ReduxQuote>) {
    if (!params.row.number) return;
    yield (
      <ActionCell
        icon={getActionIcon(ActionType.AssociateExpenseItem)}
        key={'associateWorkOrder'}
        label={params.row.associatedWorkOrder ? 'Change Associated WO' : 'Associate Work Order'}
        onClick={() => {
          setSelectedNumber(params.row.number);
          setOpenAssociationDialog(true);
        }}
        showInMenu
      />
    );
    yield (
      <ActionCell
        icon={getActionIcon(ActionType.Delete)}
        key={'deleteQuote'}
        label={'Remove Quote'}
        onClick={() => {
          selectedExpense.value?.id &&
            params.row.number &&
            dispatch(removeQuoteAction({ jobId: selectedExpense.value?.id, quoteNumber: params.row.number }));
        }}
        showInMenu
      />
    );
    yield (
      <ActionCell
        icon={getActionIcon(ActionType.Add)}
        key={'createPurchaseOrder'}
        label={'Create Purchase Order'}
        onClick={() =>
          onOpenAddExpenseChildItem(ExpenseElementType.Quote, true, {
            association: params.row.number,
            vendor: params.row.vendor,
            propertyAccount: params.row.propertyAccount,
          })
        }
        showInMenu
      />
    );
  }

  const columns: GridColDef<ReduxQuote>[] = [
    {
      field: 'number',
      headerName: '#',
      flex: 1.5,
      display: 'flex',
      renderCell: (params: GridRenderCellParams) => (
        <NameCell {...params} onOpenDrawer={onOpenDrawer} type={ExpenseElementType.Quote} />
      ),
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      display: 'flex',
      renderCell: (params: GridRenderCellParams) => (
        <NameCell {...params} onOpenDrawer={onOpenDrawer} type={ExpenseElementType.Quote} />
      ),
    },
    {
      field: 'vendor',
      headerName: 'Vendor',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <VendorDetailsCell vendor={params.row.vendor} contact={params.row.vendorContact} {...params} />
      ),
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 1,
    },
    {
      field: 'dateCreated',
      headerName: 'Date Created',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <>{params.value ? tryFormatDate(params.value ?? '') : ''}</>,
    },
    {
      field: 'dateReceived',
      headerName: 'Date Received',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <>{params.value ? tryFormatDate(params.value ?? '') : ''}</>,
    },
    {
      field: 'associatedPO',
      headerName: 'Purchase Orders',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <InternalAssociationCell
          typeToRender={ExpenseElementType.PurchaseOrder}
          handleOpenDrawer={onOpenDrawer}
          number={params.row.number}
          multiplePossible={true}
          {...params}
        />
      ),
    },
    {
      field: 'associatedWorkOrder',
      headerName: 'Work Order',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <InternalAssociationCell
          typeToRender={ExpenseElementType.WorkOrder}
          handleOpenDrawer={onOpenDrawer}
          number={params.value}
          multiplePossible={false}
          {...params}
        />
      ),
    },
    {
      field: 'quoteAmount',
      headerName: 'Quote Amount',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <>{params.value && `$${params.value}`}</>,
    },
    {
      field: 'propertyAccount',
      headerName: 'GL Code',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <GlCodeCell {...params} />,
    },
    {
      field: 'expenseQuoteStatus',
      headerName: 'Status',
      flex: 1,
      display: 'flex',
      editable: true,
      renderCell: (params: GridRenderCellParams) => <StatusCell statusMap={quoteStatusMap} {...params} />,
      renderHeader: () => {
        return (
          <>
            {'Status '}
            <EditIcon fontSize="small" color="disabled" />
          </>
        );
      },
      renderEditCell: (params: GridRenderEditCellParams) => (
        <StatusEditCell type={ExpenseElementType.Quote} statusMap={quoteStatusMap} {...params} />
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      flex: 1,
      getActions: (params: GridRowParams) => Array.from(createActions(params)),
    },
  ];

  return (
    <Stack spacing={2} mt={'1rem'}>
      <Box>
        <Button
          variant={'outlined'}
          startIcon={<Add />}
          onClick={() => onOpenAddExpenseChildItem(ExpenseElementType.Quote)}
        >
          Add Quote
        </Button>
      </Box>
      <StripedDataGrid
        disableRowGrouping
        rows={rows}
        columns={columns}
        autoHeight
        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
        slotProps={{
          filterPanel: styledFilterPanelProps,
        }}
      />
      <AssociateElementDialog
        selectedNumber={selectedNumber}
        type={ExpenseElementType.Quote}
        onClose={() => setOpenAssociationDialog(false)}
        open={openAssociationDialog}
      />
    </Stack>
  );
};
