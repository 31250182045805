import React, { FC, FormEvent, useState } from 'react';
import Box from '@mui/material/Box';
import { DetailInfoForm } from '../DetailInfoForm';
import { DetailsObservationList } from './DetailsObservationList';
import { StyledInfoBox } from '../../../styledComponents/StyledInfoBox';
import { DocumentList } from '../../../documents/components/DocumentList';
import {
  AssociationType,
  DetailAssociationType,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { DetailsDrawerSopTasks } from './DetailsDrawerSopTasks';
import { AssociationTabList } from './AssociationTabList';
import { DetailsDrawerTable } from './DetailsDrawerTable';
import { ReduxDetail } from '@monkeyjump-labs/cam-fe-shared/dist/types/detailTypes';
import {
  addDetailSpecificationAction,
  addFollowUpNoteAction,
  addWatcherToDetailAction,
  deleteDetailObservationAction,
  deleteDetailSpecificationAction,
  initializeDetailObservationAction,
  removeWatcherFromDetailAction,
  reorderDetailObservationsAction,
  updateDetailInfoAction,
  useDetails,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/details/detailsSlice';
import { getAssetTypeFromPathname } from '../../../utils/getAssetTypeFromPathname';
import { DetailsTab } from '../../../tabs/TabTypeEnums';
import { ReduxSopTask } from '@monkeyjump-labs/cam-fe-shared/dist/types/taskTypes';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { MultiUserAutocomplete } from '../../../autocompletes/MultiUserAutocomplete';

type DetailsDrawerTabsProps = {
  tab: DetailsTab;
  editingDetail: ReduxDetail;
  setEditingDetail: (detail: ReduxDetail) => void;
  isDisplayingSubDetail: boolean;
  onAddSopTask: () => void;
  onViewSubDetail: (detail: ReduxDetail) => void;
};

export const DetailsDrawerTabs: FC<DetailsDrawerTabsProps> = ({
  tab,
  editingDetail,
  setEditingDetail,
  isDisplayingSubDetail,
  onViewSubDetail,
  onAddSopTask,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, outerTab, innerTab, detailId } = useParams();
  const { details, selectedDetail } = useDetails();
  const [dirty, setDirty] = useState(false);
  const [refreshSopTasks, setRefreshSopTasks] = useState(false);

  const onUpdateDetails = (updatedDetail: ReduxDetail) => {
    setDirty(true);
    setEditingDetail({ ...updatedDetail });
  };
  const onDeleteSpecification = (key: string) => {
    if (!editingDetail || editingDetail.id === undefined) return;

    dispatch(
      deleteDetailSpecificationAction({
        detailId: editingDetail.id!,
        key: key,
      }),
    );
  };

  const onAddSpecification = (key: string, value: string) => {
    if (!editingDetail || editingDetail.id === undefined) return;
    dispatch(
      addDetailSpecificationAction({
        detailId: editingDetail.id,
        key: key,
        value: value,
      }),
    );
  };

  const onAddFollowUpNote = (observationId: number, note: string) => {
    if (!editingDetail || editingDetail.id === undefined) return;
    dispatch(
      addFollowUpNoteAction({
        detailId: editingDetail.id!,
        observationId: observationId,
        content: note,
      }),
    );
  };

  const onInitializeObservation = (description: string) => {
    if (!editingDetail || editingDetail.id === undefined) return;
    dispatch(
      initializeDetailObservationAction({
        detailId: editingDetail.id!,
        description: description,
      }),
    );
  };

  const onDeleteObservation = (observationId: number) => {
    if (!editingDetail || editingDetail.id === undefined) return;
    dispatch(
      deleteDetailObservationAction({
        detailId: editingDetail.id!,
        observationId: observationId,
      }),
    );
  };

  const onEditSopTask = (automation: ReduxSopTask) => {
    navigate(
      `/assets/${getAssetTypeFromPathname()}/${id}/${outerTab}/${innerTab}/sopTask/${
        automation.id
      }/task?returnType=Detail&returnId=${editingDetail?.id}&returnName=${editingDetail?.name}`,
    );
  };
  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (!editingDetail || editingDetail.id === undefined) return;
    dispatch(
      updateDetailInfoAction({
        body: editingDetail,
      }),
    );
    setDirty(false);
  };

  const handleReorderObservations = (observationIds: number[]) => {
    dispatch(reorderDetailObservationsAction({ detailId: editingDetail.id!, observationIds: observationIds }));
  };

  const handleUpdateWatchers = (updatedWatchers: string[]) => {
    if (editingDetail.parentId) return;
    const watchers = editingDetail?.watchers?.map((watcher) => watcher.id ?? '') ?? [];
    const addUser = updatedWatchers.filter((watcher) => !watchers.some((w) => w === watcher));
    if (addUser.length === 1) {
      dispatch(
        addWatcherToDetailAction({
          detailId: editingDetail?.id ?? '',
          userId: addUser[0],
        }),
      );
    }
    const removeUser = watchers.filter((watcher) => !updatedWatchers.some((w) => w === watcher));
    if (removeUser.length === 1) {
      dispatch(
        removeWatcherFromDetailAction({
          detailId: editingDetail?.id ?? '',
          userId: removeUser[0],
        }),
      );
    }
  };

  return (
    <Box sx={{ width: '100%', m: 1 }}>
      {
        {
          info: (
            <>
              <form onSubmit={onSubmit}>
                <DetailInfoForm
                  detail={editingDetail}
                  onEditDetail={onUpdateDetails}
                  isEditingMode
                  onAddSpecification={onAddSpecification}
                  onDeleteSpecification={onDeleteSpecification}
                  dirty={dirty}
                  loading={details.submitting}
                  isSubDetail={isDisplayingSubDetail}
                />
              </form>
              {!editingDetail.parentId && (
                <StyledInfoBox label={'Watchers'}>
                  <MultiUserAutocomplete
                    watchers={editingDetail.watchers?.map((x) => x.id!) ?? []}
                    setWatchers={(updateWatchers) => handleUpdateWatchers(updateWatchers)}
                  />
                </StyledInfoBox>
              )}
            </>
          ),
          observations: (
            <DetailsObservationList
              detail={editingDetail}
              onAddFollowUpNote={onAddFollowUpNote}
              onInitializeObservation={onInitializeObservation}
              onDeleteObservation={onDeleteObservation}
              isEditingMode
              onReorderObservations={handleReorderObservations}
            />
          ),
          documents: (
            <StyledInfoBox label={'Documents'}>
              <DocumentList
                propertyId={editingDetail.propertyId}
                value={editingDetail.attachments ?? []}
                association={{
                  associatedId: editingDetail.id,
                  associationType: AssociationType.Detail,
                }}
              />
            </StyledInfoBox>
          ),
          associations: (
            <>
              <DetailsDrawerSopTasks
                propertyId={editingDetail.propertyId}
                detailId={detailId}
                onEditAutomation={onEditSopTask}
                onAddAutomation={onAddSopTask}
                refresh={refreshSopTasks}
                onRefresh={() => setRefreshSopTasks(false)}
              />
              {selectedDetail.value?.associationType !== DetailAssociationType.UnitTemplate && (
                <AssociationTabList editingDetail={editingDetail} />
              )}
            </>
          ),
          subDetails: (
            <DetailsDrawerTable
              detailAssociationType={selectedDetail.value?.associationType ?? DetailAssociationType.RentalProperty}
              associatedId={editingDetail.associatedId ?? id ?? ''}
              handleViewDetail={onViewSubDetail}
              parentId={editingDetail.id}
              isSubDetails
            />
          ),
        }[tab ?? 'info']
      }
    </Box>
  );
};
