import React, { FC, ReactNode } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { Sapphire } from '../../../AppTheme';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

type DialogLayoutProps = {
  title: string;
  subtitle?: string;
  children?: ReactNode;
  onClose: () => void;
  open: boolean;
  level?: 'info' | 'warning' | 'error';
} & Omit<DialogProps, 'onClose' | 'open'>;

export const DialogLayout: FC<DialogLayoutProps> = ({ open, onClose, title, subtitle, children, level, ...props }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const bcColor = !level ? Sapphire[300] : level == 'error' ? theme.palette.error.main : theme.palette.warning.main;
  return (
    <Dialog fullScreen={fullScreen} open={open} onClose={onClose} fullWidth={!fullScreen} {...props}>
      <IconButton onClick={onClose} sx={{ position: 'absolute', right: 8, top: 8 }}>
        <CloseIcon />
      </IconButton>
      <DialogTitle
        id={`${title}-title`}
        sx={{
          backgroundColor: bcColor,
          color: 'white',
          maxHeight: '4rem',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          '& > *': {
            margin: 0,
            padding: 0,
          },
        }}
      >
        {title}
        {subtitle && (
          <Typography variant={'subtitle1'} component={'div'}>
            {subtitle}
          </Typography>
        )}
      </DialogTitle>
      {children}
    </Dialog>
  );
};
