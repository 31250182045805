import React, { useEffect } from 'react';
import { Header } from '../components/header/Header';
import { NavDrawer } from '../components/navDrawer/NavDrawer';
import Box from '@mui/material/Box';
import { useLocation } from 'react-router-dom';

type Props = {
  isAuthTokenSet: boolean;
  children: React.ReactNode;
};

export const Layout = ({ isAuthTokenSet, children }: Props) => {
  const [open, setOpen] = React.useState(false);
  const showNavDrawer = false;
  const [isListings, setIsListings] = React.useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname.split('/').includes('listings')) {
      setIsListings(true);
    } else {
      setIsListings(false);
    }
  }, [pathname]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      {isListings ? null : isAuthTokenSet ? <Header isAuthTokenSet={isAuthTokenSet} /> : null}
      <Box
        sx={{
          display: 'flex',
          backgroundImage: "url('/wavy-dots.png')",
          backgroundRepeat: 'repeat',
          height: '100%',
          width: '100%',
        }}
      >
        {showNavDrawer && (
          <NavDrawer open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose} />
        )}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            pr: isAuthTokenSet && !isListings ? 1 : 0,
            maxWidth: '100%',
          }}
        >
          {children}
        </Box>
      </Box>
    </>
  );
};
