import React, { FC, useEffect, useState } from 'react';
import Chip from '@mui/material/Chip';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import {
  ExpenseElementType,
  ReduxExpensePayment,
  ReduxInvoice,
  ReduxPurchaseOrder,
  ReduxQuote,
  ReduxWorkOrder,
} from '@monkeyjump-labs/cam-fe-shared/dist/types/expenseTypes';
import { useExpenses } from '../../../redux/expenseSlice';
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Popper from '@mui/material/Popper';

type InternalAssociationCellProps = {
  tableType?: ExpenseElementType; //optional, if new logic for a new kind of directionality is needed besides what is present in the switch statement
  typeToRender: ExpenseElementType;
  handleOpenDrawer: (number?: string, type?: ExpenseElementType) => void;
  number: string;
  multiplePossible: boolean;
} & GridRenderCellParams;
export const InternalAssociationCell: FC<InternalAssociationCellProps> = ({
  tableType,
  typeToRender,
  handleOpenDrawer,
  number,
  multiplePossible,
  ...params
}) => {
  const [label, setLabel] = useState<string | undefined>();
  const { selectedExpense } = useExpenses();
  const [options, setOptions] = useState<
    ReduxPurchaseOrder[] | ReduxInvoice[] | ReduxQuote[] | ReduxExpensePayment[] | ReduxWorkOrder[]
  >([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'association-link-menu' : undefined;

  useEffect(() => {
    switch (typeToRender) {
      case ExpenseElementType.PurchaseOrder:
        const associatedPurchaseOrders = selectedExpense.value?.purchaseOrders?.filter((x) =>
          multiplePossible ? x.associatedQuotes?.includes(number) : x.number === number,
        );
        if (!associatedPurchaseOrders || associatedPurchaseOrders.length === 0) {
          setLabel(undefined);
          return;
        }
        if (associatedPurchaseOrders?.length === 1) setLabel(associatedPurchaseOrders[0].name);
        else {
          setLabel(`Purchase Orders (${associatedPurchaseOrders.length})`);
        }
        setOptions(associatedPurchaseOrders);
        break;
      case ExpenseElementType.Quote:
        const associatedQuotes = selectedExpense.value?.quotes?.filter((x) =>
          multiplePossible ? x.associatedWorkOrder === number : x.number === number,
        );
        if (!associatedQuotes || associatedQuotes.length === 0) {
          setLabel(undefined);
          return;
        }
        if (associatedQuotes?.length === 1) setLabel(associatedQuotes[0].name);
        else {
          setLabel(`Quotes (${associatedQuotes.length})`);
        }
        setOptions(associatedQuotes);
        break;
      case ExpenseElementType.Invoice:
        const associatedInvoices = selectedExpense.value?.invoices?.filter((x) =>
          multiplePossible ? x.associatedPurchaseOrder === number : x.number === number,
        );
        if (!associatedInvoices || associatedInvoices.length === 0) {
          setLabel(undefined);
          return;
        }
        if (associatedInvoices?.length === 1) setLabel(associatedInvoices[0].name);
        else {
          setLabel(`Invoices (${associatedInvoices.length})`);
        }
        setOptions(associatedInvoices);
        break;
      case ExpenseElementType.WorkOrder:
        const associatedWorkOrders = selectedExpense.value?.workOrders?.filter((x) => x.number === number);
        if (!associatedWorkOrders || associatedWorkOrders.length === 0) {
          setLabel(undefined);
          return;
        }
        if (associatedWorkOrders?.length === 1) setLabel(associatedWorkOrders[0].name);
        else {
          setLabel(`Work Orders (${associatedWorkOrders.length})`);
        }
        setOptions(associatedWorkOrders);
        break;
      case ExpenseElementType.Payment:
        if (tableType === ExpenseElementType.PurchaseOrder) {
          const associatedInvoices = selectedExpense.value?.invoices?.filter(
            (x) => x.associatedPurchaseOrder === number,
          );
          if (!associatedInvoices || associatedInvoices.length === 0) {
            setLabel(undefined);
            return;
          }
          const associatedPayments = associatedInvoices
            .flatMap((x) => {
              const payments = selectedExpense.value?.payments;
              if (payments) {
                return payments.filter((y) => y.associatedInvoice === x.number);
              }
              return [];
            })
            .filter((x) => x !== undefined);
          if (!associatedPayments || associatedPayments.length === 0) {
            setLabel(undefined);
            return;
          }
          if (associatedPayments?.length === 1) setLabel(associatedPayments[0].name);
          else {
            setLabel(`Payments (${associatedPayments.length})`);
          }
          setOptions(associatedPayments);
        } else {
          const associatedPayments = selectedExpense.value?.payments?.filter((x) =>
            multiplePossible ? x.associatedInvoice === number : x.number === number,
          );
          if (!associatedPayments || associatedPayments.length === 0) {
            setLabel(undefined);
            return;
          }
          if (associatedPayments?.length === 1) setLabel(associatedPayments[0].name);
          else {
            setLabel(`Payments (${associatedPayments.length})`);
          }
          setOptions(associatedPayments);
        }
        break;
      default:
        setLabel(undefined);
        break;
    }
  }, [typeToRender, number, selectedExpense.value]);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (options.length === 1) {
      handleOpenDrawer(options[0].number, typeToRender);
    } else setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  if (!label) return <></>;

  return (
    <>
      <Tooltip title={`${label}`}>
        <Chip aria-describedby={params.row.number} onClick={handleClick} label={label}></Chip>
      </Tooltip>
      <Popper id={id} open={open} anchorEl={anchorEl} sx={{ zIndex: 999999 }} placement={'bottom-end'}>
        <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
          <Paper
            sx={{
              p: 2,
              minWidth: '10rem',
            }}
          >
            <Stack>
              <>
                {options.map(
                  (a: ReduxPurchaseOrder & ReduxQuote & ReduxExpensePayment & ReduxInvoice & ReduxWorkOrder) => {
                    const internalLabel = a.name;
                    return (
                      <Box key={a.number} sx={{ display: 'flex', flexGrow: 1, justifyContent: 'space-between' }}>
                        <Link
                          sx={{ color: 'inherit', cursor: 'pointer', mt: '.5rem' }}
                          onClick={() => {
                            setAnchorEl(null);
                            handleOpenDrawer(a.number, typeToRender);
                          }}
                        >
                          {internalLabel}
                        </Link>
                      </Box>
                    );
                  },
                )}
              </>
            </Stack>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};
