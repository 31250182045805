import React, { useEffect, useState } from 'react';
import { StyledInfoBox } from '../styledComponents/StyledInfoBox';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { darken, lighten, useTheme } from '@mui/material';
import FinancialTransactionCard from './FinancialTransactionCard';
import TimeSpanPicker, { predefinedSpans, TimeSpan } from '../dateTime/TimeSpanPicker';
import { StripedDataGrid } from '../datagrids/StripedDataGrid';
import {
  DataGridPremium,
  DataGridPremiumProps,
  GridColDef,
  GridPaginationModel,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid-premium';
import {
  getFinancialTransactionOverviewAction,
  getRelatedTransactionsAction,
  searchFinancialTransactionsAction,
  useFinancialTransactions,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/financialTransactions/financialTransactionsSlice';
import { useDispatch } from 'react-redux';
import {
  FinancialTransactionOverviewType,
  FinancialTransactionStatus,
  IFinancialTransactionOverview,
  IPaginatedQueryExpression,
  IQueryParameter,
  JournalPaymentFlow,
  PaymentStage,
  QueryOperator,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import {
  ReduxFinancialTransaction,
  ReduxFinancialTransactionView,
  toReduxDate,
} from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import { createInProcessQuery, createTransactionQuery } from './financialTransactionFilters';
import { camelCaseToWords } from '../utils/camelCaseUtils';
import { currencyFormatter } from '../utils/currencyFormatter';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Popper from '@mui/material/Popper';
import { ActionCell } from '../datagrids/ActionCell';
import CommitIcon from '@mui/icons-material/Commit';
import Skeleton from '@mui/material/Skeleton';
import { GridRowId } from '@mui/x-data-grid';
import BusinessIcon from '@mui/icons-material/Business';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import HouseOutlinedIcon from '@mui/icons-material/HouseOutlined';
import KeyIcon from '@mui/icons-material/Key';
import PersonIcon from '@mui/icons-material/Person';

export const FinancialTransactions = () => {
  const theme = useTheme();

  const { financialOverview, financialTransactions, relatedFinancialTransactions } = useFinancialTransactions();
  const dispatch = useDispatch();

  const [startDate, setStartDate] = useState<Date>(() => predefinedSpans[0].startDate.toDate());
  const [endDate, setEndDate] = useState<Date>(() => predefinedSpans[0].endDate.toDate());
  const [transactionQuery, setTransactionQuery] = useState<IQueryParameter>(() => {
    return {
      field: 'financialTransactionStatus',
      operator: QueryOperator.Eq,
      value: FinancialTransactionStatus.Failed,
    };
  });

  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'attachments-menu' : undefined;

  useEffect(() => {
    dispatchTransactionOverview(startDate, endDate);
    dispatch(
      searchFinancialTransactionsAction(createTransactionQuery(startDate, endDate, page, pageSize, transactionQuery)),
    );
  }, []);

  useEffect(() => {
    dispatch(
      searchFinancialTransactionsAction(createTransactionQuery(startDate, endDate, page, pageSize, transactionQuery)),
    );
  }, [page, pageSize]);

  const dispatchTransactionOverview = (startDate: Date, endDate: Date) => {
    dispatch(
      getFinancialTransactionOverviewAction({
        startDate: toReduxDate(startDate),
        endDate: toReduxDate(endDate),
      }),
    );
  };

  const handleInfoClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleTimeSpanChange = (value: TimeSpan) => {
    console.log('Selected Time Span:', value);
    const startDate = value.startDate.toDate();
    setStartDate(startDate);
    const endDate = value.endDate.toDate();
    setEndDate(endDate);
    dispatchTransactionOverview(startDate, endDate);
    dispatch(
      searchFinancialTransactionsAction(createTransactionQuery(startDate, endDate, page, pageSize, transactionQuery)),
    );
  };

  const baseColumns: GridColDef[] = [
    {
      field: 'dateStarted',
      headerName: 'Started',
      flex: 1,
    },
    {
      flex: 1,
      field: 'dateCompleted',
      headerName: 'Completed',
    },
    {
      flex: 1,
      field: 'dateFailed',
      headerName: 'Failed',
      valueGetter: (value, row) => {
        return { dateFailed: value, failedReason: row.failedReason };
      },
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Stack direction="row" alignItems="center">
            {params.value.dateFailed && (
              <>
                {params.value.dateFailed}
                <IconButton onClick={handleInfoClick}>
                  <InfoIcon />
                </IconButton>
                <Popper id={id} open={open} anchorEl={anchorEl} sx={{ zIndex: 9999 }} placement={'right-end'}>
                  <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                    <Paper
                      variant={'outlined'}
                      sx={{
                        padding: theme.spacing(2),
                        backgroundColor: theme.palette.background.default,
                      }}
                    >
                      {params.value.failedReason}
                    </Paper>
                  </ClickAwayListener>
                </Popper>
              </>
            )}
          </Stack>
        );
      },
    },
    {
      flex: 1,
      field: 'processor',
      headerName: 'Processor',
      valueFormatter: (value?: string) => {
        return camelCaseToWords(value);
      },
    },
    {
      flex: 1,
      field: 'paymentType',
      headerName: 'Type',
      valueFormatter: (value?: string) => {
        return camelCaseToWords(value);
      },
    },
    {
      flex: 1,
      field: 'financialTransactionStatus',
      headerName: 'Status',
      valueFormatter: (value?: string) => {
        return camelCaseToWords(value);
      },
    },
    {
      flex: 0.5,
      field: 'amount',
      headerName: 'Amount',
      valueFormatter: (value: number) => {
        return currencyFormatter.format(value);
      },
    },
  ];

  const columns: GridColDef[] = [
    ...baseColumns,
    {
      flex: 0.5,
      field: 'actions',
      type: 'actions',
      headerName: '',
      cellClassName: 'actions',
      getActions: (params: GridRowParams<ReduxFinancialTransaction>) => {
        const actions = [];
        if (params.row.financialTransactionStatus == FinancialTransactionStatus.Failed)
          actions.push(<ActionCell label={'Retry'} key={'showMore'} showInMenu={false} icon={<CommitIcon />} />);
        return actions;
      },
    },
  ];

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
  };

  const selectOverview = (type: FinancialTransactionOverviewType, prop: string) => {
    const typeName = type.toString() as keyof { [key: string]: IFinancialTransactionOverview };
    const propName = prop as keyof IFinancialTransactionOverview;
    return financialOverview.value && financialOverview.value.overview![typeName][propName];
  };

  const handleCardClick = (
    queryGenerator: (
      startDate: Date,
      endDate: Date,
      page: number,
      pageSize: number,
      queryParams: IQueryParameter,
    ) => IPaginatedQueryExpression,
    startDate: Date,
    endDate: Date,
    page: number,
    pageSize: number,
    queryParam: IQueryParameter,
  ) => {
    const query = queryGenerator(startDate, endDate, page, pageSize, queryParam);
    dispatch(searchFinancialTransactionsAction(query));
    setTransactionQuery(queryParam);
  };

  const isCardFilled = (
    filter:
      | FinancialTransactionStatus.Failed
      | FinancialTransactionStatus.Completed
      | FinancialTransactionStatus.InProcess
      | PaymentStage.Stage1
      | PaymentStage.Stage2
      | JournalPaymentFlow.PropertyToPlatform,
  ) => {
    return transactionQuery.value == filter;
  };

  function DetailPanelContent({ row: rowProp }: { row: ReduxFinancialTransactionView }) {
    const transaction = financialTransactions.value?.results?.find((t) => t.id === rowProp.id);
    const related = transaction?.relatedTransactions;
    if (related || relatedFinancialTransactions.loading)
      return (
        <>
          <Stack sx={{ py: 2, height: '100%', boxSizing: 'border-box' }} direction="column">
            <Paper sx={{ flex: 1, mx: 'auto', width: '90%', p: 1 }}>
              <Stack spacing={2}>
                <h4>Related group transactions</h4>
                <Stack direction="row" spacing={1}>
                  <BusinessIcon />
                  <Typography>{related ? related.propertyName : <Skeleton sx={{ width: '20ch' }} />}</Typography>
                  <Box width="2em"></Box>
                  <HouseOutlinedIcon />
                  <Typography>{related ? related.buildingName : <Skeleton sx={{ width: '20ch' }} />}</Typography>
                  <Box width="2em"></Box>
                  <KeyIcon />
                  <Typography>{related ? related.unitName : <Skeleton sx={{ width: '20ch' }} />}</Typography>
                </Stack>
                <Stack direction={'row'} spacing={1}>
                  <PersonIcon />
                  <Typography>
                    {related ? (
                      `${related.tenantInfo?.firstName} ${related.tenantInfo?.lastName}`
                    ) : (
                      <Skeleton sx={{ width: '20ch' }} />
                    )}
                  </Typography>
                </Stack>
              </Stack>
              <DataGridPremium
                sx={{
                  backgroundColor: theme.palette.info.light,
                  '& .MuiDataGrid-columnHeader': {
                    backgroundColor: theme.palette.info.main, // Blue background
                  },
                }}
                rows={rowProp.relatedTransactions?.relatedTransactions ?? []}
                columns={baseColumns}
              />
            </Paper>
          </Stack>
        </>
      );
    if (relatedFinancialTransactions.loadingFailed)
      return <Typography color={theme.palette.warning.main}>Unable to load related Transactions</Typography>;
  }

  const getDetailPanelContent = React.useCallback<NonNullable<DataGridPremiumProps['getDetailPanelContent']>>(
    (params: GridRowParams<ReduxFinancialTransactionView>) => <DetailPanelContent row={params.row} />,
    [relatedFinancialTransactions],
  );

  const handleDetailPanelExpansionChange = (ids: GridRowId[]) => {
    //figure out which are the newly expanded rows and load them
    const needsLoading = financialTransactions.value?.results
      ?.filter((f) => !f.relatedTransactions)
      .filter((f) => ids.includes(f.id!));
    if (!needsLoading || needsLoading.length === 0) {
      return;
    }
    for (const transaction of needsLoading) {
      if (transaction.paymentFlowGroupId) {
        dispatch(getRelatedTransactionsAction({ paymentFlowGroupId: transaction.paymentFlowGroupId }));
      }
    }
  };

  return (
    <StyledInfoBox label={'Financial Transactions'}>
      <Stack>
        <TimeSpanPicker onChange={handleTimeSpanChange} />
        {financialOverview?.value?.overview ? (
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <FinancialTransactionCard
                title="Failed"
                loading={financialOverview.loading}
                outlined={!isCardFilled(FinancialTransactionStatus.Failed)}
                numberTransactions={selectOverview(FinancialTransactionOverviewType.Failed, 'numTransactions')!}
                headerBackgroundColor={lighten(theme.palette.error.main, 0.3)}
                contentBackgroundColor={lighten(theme.palette.error.light, 0.2)}
                amount={selectOverview(FinancialTransactionOverviewType.Failed, 'sum')!}
                handleClick={() =>
                  handleCardClick(createTransactionQuery, startDate, endDate, page, pageSize, {
                    field: 'financialTransactionStatus',
                    operator: QueryOperator.Eq,
                    value: FinancialTransactionStatus.Failed,
                  })
                }
              />
            </Grid>
            <Grid item xs={2}>
              <FinancialTransactionCard
                loading={financialOverview.loading}
                outlined={!isCardFilled(FinancialTransactionStatus.InProcess)}
                title="In Process"
                numberTransactions={selectOverview(FinancialTransactionOverviewType.InProcess, 'numTransactions')!}
                headerBackgroundColor={theme.palette.warning.main}
                contentBackgroundColor={theme.palette.warning.light}
                amount={selectOverview(FinancialTransactionOverviewType.InProcess, 'sum')!}
                handleClick={() =>
                  handleCardClick(createInProcessQuery, startDate, endDate, page, pageSize, {
                    field: 'financialTransactionStatus',
                    operator: QueryOperator.Eq,
                    value: FinancialTransactionStatus.InProcess,
                  })
                }
              />
            </Grid>
            <Grid item xs={2}>
              <FinancialTransactionCard
                loading={financialOverview.loading}
                outlined={!isCardFilled(FinancialTransactionStatus.Completed)}
                title="Successful"
                numberTransactions={selectOverview(FinancialTransactionOverviewType.Completed, 'numTransactions')!}
                headerBackgroundColor={theme.palette.success.main}
                contentBackgroundColor={theme.palette.success.light}
                amount={selectOverview(FinancialTransactionOverviewType.Completed, 'sum')!}
                handleClick={() =>
                  handleCardClick(createTransactionQuery, startDate, endDate, page, pageSize, {
                    field: 'financialTransactionStatus',
                    operator: QueryOperator.Eq,
                    value: FinancialTransactionStatus.Completed,
                  })
                }
              />
            </Grid>
            <Grid item xs={2}>
              <FinancialTransactionCard
                loading={financialOverview.loading}
                outlined={!isCardFilled(PaymentStage.Stage1)}
                title="Stage 1"
                numberTransactions={selectOverview(FinancialTransactionOverviewType.Stage1, 'numTransactions')!}
                headerBackgroundColor={darken(theme.palette.primary.light, 0.3)}
                contentBackgroundColor={theme.palette.primary.light}
                amount={selectOverview(FinancialTransactionOverviewType.Stage1, 'sum')!}
                handleClick={() =>
                  handleCardClick(createTransactionQuery, startDate, endDate, page, pageSize, {
                    field: 'paymentStage',
                    operator: QueryOperator.Eq,
                    value: PaymentStage.Stage1,
                  })
                }
              />
            </Grid>
            <Grid item xs={2}>
              <FinancialTransactionCard
                loading={financialOverview.loading}
                outlined={!isCardFilled(PaymentStage.Stage2)}
                title="Stage 2"
                numberTransactions={selectOverview(FinancialTransactionOverviewType.Stage2, 'numTransactions')!}
                headerBackgroundColor={darken(theme.palette.primary.light, 0.3)}
                contentBackgroundColor={theme.palette.primary.light}
                amount={selectOverview(FinancialTransactionOverviewType.Stage2, 'sum')!}
                handleClick={() =>
                  handleCardClick(createTransactionQuery, startDate, endDate, page, pageSize, {
                    field: 'paymentStage',
                    operator: QueryOperator.Eq,
                    value: PaymentStage.Stage2,
                  })
                }
              />
            </Grid>
            <Grid item xs={2}>
              <FinancialTransactionCard
                loading={financialOverview.loading}
                outlined={!isCardFilled(JournalPaymentFlow.PropertyToPlatform)}
                title="Platform"
                numberTransactions={selectOverview(FinancialTransactionOverviewType.Platform, 'numTransactions')!}
                headerBackgroundColor={darken(theme.palette.primary.light, 0.3)}
                contentBackgroundColor={theme.palette.primary.light}
                amount={selectOverview(FinancialTransactionOverviewType.Platform, 'sum')!}
                handleClick={() =>
                  handleCardClick(createTransactionQuery, startDate, endDate, page, pageSize, {
                    field: 'paymentFlow',
                    operator: QueryOperator.Eq,
                    value: JournalPaymentFlow.PropertyToPlatform,
                  })
                }
              />
            </Grid>

            <Grid item xs={12}>
              <Paper sx={{ display: 'flex', flexDirection: 'column' }}>
                <StripedDataGrid
                  disableRowGrouping
                  rows={financialTransactions.value?.results ?? []}
                  loading={financialTransactions.loading}
                  columns={columns}
                  pagination
                  paginationMode={'server'}
                  pageSizeOptions={[3, 10, 25, 50, 100]}
                  rowCount={financialTransactions.value?.totalCount ?? 0}
                  paginationModel={{ page: page, pageSize: pageSize }}
                  onPaginationModelChange={(model: GridPaginationModel) => {
                    handlePageChange(model.page);
                    handlePageSizeChange(model.pageSize);
                  }}
                  getDetailPanelContent={getDetailPanelContent}
                  getDetailPanelHeight={() => 'auto'} // Height based on the content.
                  sx={{
                    '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
                    '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
                    '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
                  }}
                  onDetailPanelExpandedRowIdsChange={handleDetailPanelExpansionChange}
                />
              </Paper>
            </Grid>
          </Grid>
        ) : (
          <Skeleton variant={'rectangular'} height={'300px'} />
        )}
      </Stack>
    </StyledInfoBox>
  );
};
