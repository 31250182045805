import React, { FC, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {
  ExpenseElementOption,
  quoteStatusMap,
  ReduxExpense,
} from '@monkeyjump-labs/cam-fe-shared/dist/types/expenseTypes';

type AssociateElementMultipleAutocompleteProps = {
  value?: string[];
  onChangeAssociations: (associations?: string[]) => void;
  selectedExpense?: ReduxExpense;
};
export const AssociateElementMultipleAutocomplete: FC<AssociateElementMultipleAutocompleteProps> = ({
  onChangeAssociations,
  selectedExpense,
  value,
}) => {
  const [selectOptions, setSelectOptions] = useState<ExpenseElementOption[]>([]);
  const [internalValue, setInternalValue] = useState<ExpenseElementOption[]>([]);
  useEffect(() => {
    //currently only enabled for Purchase Orders
    const quoteOptions: ExpenseElementOption[] =
      selectedExpense?.quotes?.map((x) => {
        return {
          label: x.name ? `Quote ${x.number}: ${x.name}` : 'Quote',
          number: x.number,
          amount: x.quoteAmount,
          vendorName: x.vendor?.name,
          status: x.expenseQuoteStatus && quoteStatusMap.get(x.expenseQuoteStatus),
          vendor: x.vendor,
          propertyAccountId: x.propertyAccount?.id,
          propertyAccountName: x.propertyAccount?.name,
        };
      }) ?? [];
    setSelectOptions(quoteOptions);
    if (value) {
      const associatedQuotes = quoteOptions.filter((x) => x.number && value.includes(x.number));
      setInternalValue(associatedQuotes);
    }
  }, [value, selectedExpense]);

  return (
    <Autocomplete
      multiple
      renderInput={(params) => <TextField margin="dense" variant="outlined" label={'Associated Quotes'} {...params} />}
      options={selectOptions}
      value={internalValue}
      onChange={(event, newValue) => {
        setInternalValue(newValue);
        onChangeAssociations(newValue.map((x) => x.number!));
      }}
    />
  );
};
