import React, { FC, FormEvent, useEffect, useState } from 'react';
import { DialogLayout } from '../../../dialogs/DialogLayout';
import {
  AssetType,
  AssociationType,
  CamTaskStatus,
  TaskType,
  TaskSection,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import DialogContent from '@mui/material/DialogContent';
import { AddTaskForm } from '../../../tasks/components/AddTaskForm';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  createNewTaskFromDetailAction,
  createNewTaskFromEmailAction,
  createNewTaskFromExpensePurchaseOrderLineItemAction,
  setNewTaskFromAssociationAssetValuesAction,
  setNewTaskFromAssociationValuesAction,
  useTasks,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/tasks/taskSlice';
import { ReduxTask } from '@monkeyjump-labs/cam-fe-shared/dist/types/taskTypes';
import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { AddTaskFromSopTask } from '../../../tasks/components/taskDialogs/AddTaskFromSopTask';
import { associationTypeMap } from '../../../search/searchUtils';
import {
  endpointIsntIntegratedAction,
  setBackUrlAction,
  showToastMessageAction,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import Typography from '@mui/material/Typography';
import { Search } from '../../../search/components/Search';
import { useSearchHook } from '../../../search/useSearchHook';
import FormControl from '@mui/material/FormControl';
import { AddScheduledTask } from '../../../tasks/components/scheduledTasks/AddScheduledTask';
import { resetScheduledTaskSubmissionAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/tasks/scheduledTaskSlice';
import { AssetParams } from '../../../../../AppRouter';
import { getAssetTypeFromPathname } from '../../../utils/getAssetTypeFromPathname';
import { useUnit } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/unitSlice';
import { useBuilding } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/buildingSlice';
import { useProperty } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/propertySlice';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

export const AddTaskFromAssociationDialog: FC = () => {
  const dispatch = useDispatch();
  const tasks = useTasks();
  const { assetStringOptions } = useSearchHook();
  const { selectedContext } = useAssets();
  const { selectedUnit } = useUnit();
  const { selectedBuilding } = useBuilding();
  const { selectedProperty } = useProperty();
  const { newTaskFromAssociationValues } = useTasks();
  const associationValue = newTaskFromAssociationValues;
  const [isFromSopTask, setIsFromSopTask] = useState(false);
  const [isScheduled, setIsScheduled] = useState(false);
  const { id, outerTab, innerTab } = useParams<AssetParams>();

  const getCurrentAssetName = (): string => {
    const asset = getAssetTypeFromPathname();
    switch (asset) {
      case 'unit':
        return `Unit: ${selectedUnit.value?.name}`;
      case 'building':
        return `Building: ${selectedBuilding.value?.name}`;
      case 'property':
        return `Property: ${selectedProperty.value?.name}`;
    }
  };

  const parentPath = `/assets/${getAssetTypeFromPathname()}/${id}/${outerTab}/${innerTab}`;
  const handleSwitchSopTask = () => setIsFromSopTask(!isFromSopTask);

  const [newTask, setNewTask] = useState<ReduxTask | undefined>({
    name: associationValue?.name ?? '',
    description: associationValue?.description ?? '',
    status: CamTaskStatus.NotStarted,
    taskType: TaskType.Normal,
    associateWithCurrentLease: false,
  });

  useEffect(() => {
    if (tasks.newTask.submitted) {
      handleClose();
    }
  }, [tasks.newTask.submitted]);

  useEffect(() => {
    setNewTask({
      name: associationValue?.name ?? '',
      description: associationValue?.description ?? '',
      status: CamTaskStatus.NotStarted,
      taskType: TaskType.Normal,
      associateWithCurrentLease: false,
      checklist: associationValue?.checklistItems?.map((item, index) => ({ id: index.toString(), name: item })) ?? [],
      taskSection: associationValue?.taskSection,
    });
  }, [associationValue]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    dispatch(setBackUrlAction(location.pathname));
    switch (associationValue?.valueAssociationType) {
      case AssociationType.Detail:
        associationValue?.id &&
          newTask &&
          dispatch(
            createNewTaskFromDetailAction({
              body: {
                ...newTask,
                checklist: newTask.checklist?.map((item) => item.name!),
                associatedId: associationValue?.valueAssociatedId ?? selectedContext.propertyId,
                assetType: associationValue?.valueAssetType ?? AssetType.RentalProperty,
              },
              detailId: associationValue?.id,
              parentPath: parentPath,
            }),
          );
        break;
      case AssociationType.ExpensePurchaseOrder:
        associationValue.poLineItemInfo?.poNumber &&
          associationValue.poLineItemInfo?.lineItemNumber &&
          dispatch(
            createNewTaskFromExpensePurchaseOrderLineItemAction({
              body: {
                ...newTask,
                associatedId: associationValue?.valueAssociatedId ?? selectedContext.propertyId,
                assetType: associationValue?.valueAssetType ?? AssetType.RentalProperty,
                checklist: newTask?.checklist?.map((item) => item.name!),
              },
              expenseId: associationValue.id,
              purchaseOrderNumber: associationValue.poLineItemInfo?.poNumber,
              changeOrderId: associationValue.poLineItemInfo.changeOrderId,
              lineItemNumber: associationValue.poLineItemInfo?.lineItemNumber,
              parentPath: parentPath,
            }),
          );
        break;
      case AssociationType.EmailThread:
        if (!associationValue.valueAssetType || !associationValue.valueAssociatedId) {
          dispatch(
            showToastMessageAction({
              message: 'You must select an asset to associate this task with',
              severity: 'warning',
            }),
          );
        } else if (!newTask?.taskSection) {
          dispatch(
            showToastMessageAction({
              message: 'You must select a section to associate this task with',
              severity: 'warning',
            }),
          );
        } else {
          associationValue.id &&
            newTask &&
            dispatch(
              createNewTaskFromEmailAction({
                body: {
                  ...newTask,
                  checklist: newTask.checklist?.map((item) => item.name!),
                  associatedId: associationValue.valueAssociatedId,
                  assetType: associationValue.valueAssetType,
                },
                emailThreadId: associationValue.id,
                parentPath: parentPath,
              }),
            );
        }
        break;
      default:
        dispatch(endpointIsntIntegratedAction());
        break;
    }
  };

  const handleClose = () => {
    setNewTask(undefined);
    dispatch(setNewTaskFromAssociationValuesAction(undefined));
    dispatch(resetScheduledTaskSubmissionAction());
    setIsFromSopTask(false);
    setIsScheduled(false);
  };

  const handleChange = (value: ReduxTask) => {
    setNewTask(value);
  };

  return (
    <DialogLayout
      title={
        associationValue?.valueAssociationType
          ? `Create Task From ${associationTypeMap.get(associationValue?.valueAssociationType)}`
          : `Create Task`
      }
      onClose={handleClose}
      open={!!associationValue}
      PaperProps={{ sx: { minHeight: 800 } }}
    >
      <DialogContent>
        <Stack spacing={2}>
          <Stack spacing={2} mt={1}>
            <FormControl>
              <FormControlLabel
                control={<Switch size="small" checked={isScheduled} onChange={() => setIsScheduled(!isScheduled)} />}
                label={`Create Scheduled Task`}
              />
            </FormControl>
            <FormControl>
              <FormControlLabel
                control={<Switch size="small" checked={isFromSopTask} onChange={handleSwitchSopTask} />}
                label="Create From SOP Task"
              />
            </FormControl>
          </Stack>
          {(associationValue?.valueAssociationType === AssociationType.EmailThread ||
            associationValue?.valueAssociationType === AssociationType.ExpensePurchaseOrder) && (
            <Box>
              <Typography
                sx={associationValue?.valueAssociationType === AssociationType.EmailThread ? { mb: '-1rem' } : {}}
              >
                Select an asset to associate this task with.
              </Typography>
              {associationValue?.valueAssociationType === AssociationType.ExpensePurchaseOrder && (
                <Typography
                  sx={
                    associationValue?.valueAssociationType === AssociationType.ExpensePurchaseOrder
                      ? { mb: '-1rem' }
                      : {}
                  }
                  variant={'subtitle2'}
                >
                  *If left blank, task will associate with current asset
                </Typography>
              )}
              <Search
                placeholder={
                  associationValue.valueAssociationType === AssociationType.ExpensePurchaseOrder
                    ? getCurrentAssetName()
                    : undefined
                }
                searchTypes={[AssociationType.Building, AssociationType.BuildingUnit, AssociationType.RentalProperty]}
                options={assetStringOptions}
                global={false}
                onSelectAssociation={(type?: AssociationType, id?: string) => {
                  if (!type || !id) {
                    dispatch(
                      setNewTaskFromAssociationAssetValuesAction({
                        assetType: undefined,
                        assetId: undefined,
                      }),
                    );
                  } else {
                    const assetType =
                      type === AssociationType.BuildingUnit
                        ? AssetType.BuildingUnit
                        : type === AssociationType.Building
                          ? AssetType.Building
                          : type === AssociationType.RentalProperty
                            ? AssetType.RentalProperty
                            : undefined;
                    dispatch(
                      setNewTaskFromAssociationAssetValuesAction({
                        assetId: id,
                        assetType: assetType,
                      }),
                    );
                  }
                }}
              />
            </Box>
          )}
          {associationValue?.valueAssociationType === AssociationType.EmailThread && (
            <FormControl fullWidth>
              <InputLabel id="task-section">Section</InputLabel>
              <Select
                labelId="task-section-label"
                id="task-section"
                label="Section"
                value={newTask?.taskSection ? newTask.taskSection : ''}
                onChange={(e) => {
                  setNewTask({
                    ...newTask,
                    taskSection: e.target.value as TaskSection,
                  });
                }}
              >
                <MenuItem value={TaskSection.Leasing}>Leasing</MenuItem>
                <MenuItem value={TaskSection.Operations}>Operations</MenuItem>
                <MenuItem value={TaskSection.Financials}>Financials</MenuItem>
              </Select>
            </FormControl>
          )}
          {isScheduled ? (
            <AddScheduledTask
              onClose={handleClose}
              assetId={associationValue?.valueAssociatedId}
              assetType={associationValue?.valueAssetType}
              isSopAdd={isFromSopTask}
              createdFromAssociation={{
                id: associationValue?.id,
                type: associationValue?.valueAssociationType,
                poInfo: associationValue?.poLineItemInfo,
              }}
              localSopTaskAssociation={{
                associationType:
                  associationValue?.valueAssociationType !== AssociationType.EmailThread
                    ? associationValue?.valueAssociationType
                    : undefined,
                associatedId:
                  associationValue?.valueAssociationType !== AssociationType.EmailThread
                    ? associationValue?.id
                    : undefined,
              }}
              value={newTask}
              taskSection={newTask?.taskSection}
            />
          ) : (
            <form onSubmit={handleSubmit} autoComplete={'off'}>
              {isFromSopTask && associationValue?.id ? (
                <AddTaskFromSopTask
                  onClose={handleClose}
                  associatedId={associationValue.id}
                  associationType={associationValue?.valueAssetType}
                  createdFrom={associationValue.valueAssociationType}
                  AssetInfo={{
                    assetType: associationValue.valueAssetType,
                    assetId: associationValue?.valueAssociatedId,
                  }}
                  localSopTaskAssociation={{
                    associationType:
                      associationValue.valueAssociationType !== AssociationType.EmailThread
                        ? associationValue.valueAssociationType
                        : undefined,
                    associatedId:
                      associationValue.valueAssociationType !== AssociationType.EmailThread
                        ? associationValue?.id
                        : undefined,
                  }}
                  poLineItemInfo={associationValue.poLineItemInfo}
                  taskSection={newTask?.taskSection}
                />
              ) : (
                <AddTaskForm onChange={handleChange} value={newTask} propertyId={selectedContext.propertyId} />
              )}
              {!isFromSopTask && (
                <DialogActions sx={{ mt: 1 }}>
                  <Button onClick={handleClose}>Close</Button>
                  <LoadingButton variant={'contained'} type={'submit'} loading={tasks.newTask.submitting}>
                    Create Task
                  </LoadingButton>
                </DialogActions>
              )}
            </form>
          )}
        </Stack>
      </DialogContent>
    </DialogLayout>
  );
};
