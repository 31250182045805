import React, { FC, FormEvent, useEffect, useState } from 'react';
import {
  IAddVendorHandlerRequest,
  IUpdateVendorHandlerRequest,
  IVendor,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { PhoneTextField } from '../../../../../_shared/texfields/PhoneTextField';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

type AddOrUpdateVendorRequest = IAddVendorHandlerRequest | IUpdateVendorHandlerRequest;

type VendorInfoProps<T extends AddOrUpdateVendorRequest> = {
  value?: T;
  onSubmit: (value: T) => void;
  submitting?: boolean;
  newOrEdit: 'new' | 'edit';
};

type VendorAddOrUpdateProps = VendorInfoProps<IAddVendorHandlerRequest> | VendorInfoProps<IUpdateVendorHandlerRequest>;

export const VendorInfoForm: FC<VendorAddOrUpdateProps> = ({ submitting, value, onSubmit, newOrEdit }) => {
  const [vendor, setVendor] = useState<IVendor | undefined>(value);
  const [dirty, setDirty] = useState(false);
  const [primaryContact, setPrimaryContact] = useState<string>('');

  useEffect(() => {
    setVendor(value);
    setPrimaryContact('');
    setDirty(false);
  }, [value]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const contact =
      newOrEdit === 'new'
        ? {
            firstName: primaryContact.split(' ')[0],
            lastName: primaryContact
              .split(' ')
              .reduce((acc, curr, i) => (i > 0 ? acc + (i > 1 ? ' ' : '') + curr : acc), ''),
          }
        : vendor?.primaryContact;

    const submittingVendor = {
      ...vendor,
      primaryContact: contact,
    };
    onSubmit(submittingVendor);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2} px={1}>
        <Grid item xs={6}>
          <TextField
            required
            variant={'outlined'}
            label={'Company Name'}
            fullWidth
            value={vendor?.name ?? ''}
            onChange={(e) => {
              setDirty(true);
              setVendor({ ...vendor, name: e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={6}>
          {newOrEdit === 'new' ? (
            <TextField
              required
              variant={'outlined'}
              label={'Primary Contact'}
              placeholder={'John Doe'}
              fullWidth
              value={primaryContact ?? ''}
              onChange={(e) => {
                setDirty(true);
                setPrimaryContact(e.target.value);
              }}
            />
          ) : (
            <FormControl margin="dense" fullWidth>
              <InputLabel>Primary Contact</InputLabel>
              <Select
                variant={'outlined'}
                label={'Primary Contact'}
                fullWidth
                value={vendor?.primaryContact?.firstName + ' ' + vendor?.primaryContact?.lastName}
                onChange={(e) => {
                  setDirty(true);
                  setVendor({
                    ...vendor,
                    primaryContact: vendor?.contacts?.find((c) => c.firstName + ' ' + c.lastName === e.target.value),
                  });
                }}
              >
                {vendor?.contacts?.map((c) => {
                  return (
                    <MenuItem key={c.firstName + ' ' + c.lastName} value={c.firstName + ' ' + c.lastName}>
                      {c.firstName + ' ' + c.lastName}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}
        </Grid>
        <Grid item xs={6}>
          <PhoneTextField
            variant={'outlined'}
            label={'Company Phone Number'}
            fullWidth
            value={vendor?.contactInfo?.phone ?? ''}
            onChange={(e) => {
              setDirty(true);
              setVendor({
                ...vendor,
                contactInfo: { ...vendor?.contactInfo, phone: e.target.value },
              });
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant={'outlined'}
            label={'Company Email'}
            required
            fullWidth
            value={vendor?.contactInfo?.email ?? ''}
            onChange={(e) => {
              setDirty(true);
              setVendor({
                ...vendor,
                contactInfo: { ...vendor?.contactInfo, email: e.target.value },
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'right' }}>
            <LoadingButton type={'submit'} variant={'contained'} loading={submitting} disabled={!dirty}>
              {newOrEdit === 'new' ? 'Add Vendor' : 'Save'}
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};
