import {
  IPaginatedQueryExpression,
  IQueryExpression,
  IQueryParameter,
  QueryGroupOperator,
  QueryOperator,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';

export const betweenDatesInclusiveQuery = (startDate: Date, endDate: Date): IQueryExpression => {
  const dateCompletedQuery: IQueryExpression = {
    groupOperator: QueryGroupOperator.And,
    parameters: [
      {
        field: 'dateCompleted',
        operator: QueryOperator.Ge,
        value: startDate.toISOString(),
      },
      {
        field: 'dateCompleted',
        operator: QueryOperator.Le,
        value: endDate.toISOString(),
      },
    ],
  };

  const dateFailedQuery: IQueryExpression = {
    groupOperator: QueryGroupOperator.And,
    parameters: [
      {
        field: 'dateFailed',
        operator: QueryOperator.Ge,
        value: startDate.toISOString(),
      },
      {
        field: 'dateFailed',
        operator: QueryOperator.Le,
        value: endDate.toISOString(),
      },
    ],
  };

  return {
    groupOperator: QueryGroupOperator.Or,
    subExpressions: [dateFailedQuery, dateCompletedQuery],
  };
};

export const createTransactionQuery = (
  startDate: Date,
  endDate: Date,
  page: number,
  pageSize: number,
  queryParams: IQueryParameter,
): IPaginatedQueryExpression => {
  const baseQuery = betweenDatesInclusiveQuery(startDate, endDate);

  const query: IQueryExpression = {
    groupOperator: QueryGroupOperator.And,
    parameters: [queryParams],
  };

  return {
    groupOperator: QueryGroupOperator.And,
    subExpressions: [baseQuery, query],
    page: page,
    pageSize: pageSize,
  };
};

/**
 * Because InProcess query is not using Completed/Failed dates, it needs to be different
 * from `createTransactionQuery`
 * @param startDate
 * @param endDate
 * @param page
 * @param pageSize
 * @param queryParams
 */
export const createInProcessQuery = (
  startDate: Date,
  endDate: Date,
  page: number,
  pageSize: number,
  queryParams: IQueryParameter,
): IPaginatedQueryExpression => {
  const query: IQueryExpression = {
    groupOperator: QueryGroupOperator.And,
    parameters: [queryParams],
  };

  const dateStartedQuery: IQueryExpression = {
    groupOperator: QueryGroupOperator.And,
    parameters: [
      {
        field: 'dateStarted',
        operator: QueryOperator.Ge,
        value: startDate.toISOString(),
      },
      {
        field: 'dateStarted',
        operator: QueryOperator.Le,
        value: endDate.toISOString(),
      },
    ],
  };

  return {
    groupOperator: QueryGroupOperator.And,
    subExpressions: [dateStartedQuery, query],
    page: page,
    pageSize: pageSize,
  };
};
