import { createTheme } from '@mui/material/styles';
import '@mui/lab/themeAugmentation';

export const Sapphire = {
  100: '#bddbf4',
  300: '#0d75a6',
  500: '#095173',
  700: '#12446d',
  900: '#0c2d48',
};

export const Persimmon = {
  100: '#e5c09a',
  300: '#d69b5f',
  500: '#e87a00',
  700: '#c66300',
  900: '#4d2800',
};

export const Rose = {
  100: '#E97298',
  300: '#e44e7d',
  500: '#c31d52',
  700: '#7c1334',
  900: '#470B1E',
};

export const Ivy = {
  100: '#c5edd2',
  300: '#77d496',
  500: '#50C878',
  700: '#2b884a',
  900: '#123a20',
};

export const Yellow = {
  100: '#FFF9C4',
  300: '#FFF176',
  500: '#FFEB3B',
  700: '#FBC02D',
  900: '#F57F17',
};

export const navDrawerOpen = 240;

export const navDrawerClosed = 72;

export const selectedItemBorder = `.2rem solid ${Persimmon[500]}`;

export const appTheme = createTheme({
  palette: {
    background: {
      default: '#fff',
    },
    primary: {
      light: Sapphire[100],
      main: Sapphire[500],
      dark: Sapphire[900],
    },
    secondary: {
      light: Persimmon[100],
      main: Persimmon[500],
      dark: Persimmon[900],
    },
    warning: {
      light: Persimmon[100],
      main: Persimmon[500],
      dark: Persimmon[900],
    },
    success: {
      light: Ivy[100],
      main: Ivy[500],
      dark: Ivy[900],
    },
    error: {
      light: Rose[100],
      main: Rose[500],
      dark: Rose[900],
    },
    info: {
      main: Persimmon[100],
    },
  },
  components: {
    MuiPaper: {
      defaultProps: {
        elevation: 3,
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: Sapphire[500],
        },
      },
    },
    MuiTabPanel: {
      styleOverrides: {
        root: {
          '&.MuiTabPanel-root': { padding: '.5rem' },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.MuiTab-root': {
            '&.Mui-selected': {
              backgroundColor: Sapphire[100],
              color: Sapphire[900],
              borderRadius: 4,
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: 'white',
          '&:hover': {
            backgroundColor: Sapphire[300],
            color: 'white',
          },
        },
        outlined: {
          borderColor: Sapphire[900],
          color: Sapphire[900],
        },
        text: {
          boxShadow: '.1rem .1rem .1rem .1rem lightgray',
          color: Sapphire[900],
          '&:hover': {
            backgroundColor: Sapphire[100],
            color: Sapphire[900],
          },
        },
        sizeSmall: {
          boxShadow: 'none',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        margin: 'dense',
        inputProps: {
          autoComplete: 'off',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          color: Sapphire[500],
        },
      },
      defaultProps: {
        variant: 'h5',
      },
    },
  },
  shape: {
    borderRadius: 7,
  },
  typography: {
    fontFamily: ['Montserrat', 'Open Sans', 'Arial', 'sans-serif'].join(','),
    h1: {
      fontWeight: 700,
      fontSize: '2rem',
    },
    h2: {
      fontWeight: 700,
      fontSize: '1.75rem',
    },
    h3: {
      fontWeight: 600,
      fontSize: '1.5rem',
    },
    h4: {
      fontWeight: 600,
      fontSize: '1.25rem',
    },
    h5: {
      fontWeight: 500,
      fontSize: '1.25rem',
    },
    h6: {
      fontWeight: 500,
      fontSize: '1.1rem',
    },
  },
});
