import React, { FC } from 'react';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { IKnownContactDto } from '@monkeyjump-labs/cam-fe-shared/src/services/generated/ApiClientGenerated';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';

type EmailAddressProps = {
  tooltipTitle: string;
  title: string;
  isRead?: boolean;
  isThread?: boolean;
  knownContacts: IKnownContactDto[];
};

export const EmailAddress: FC<EmailAddressProps> = ({ title, tooltipTitle, knownContacts, isRead, isThread }) => {
  const contacts = knownContacts.filter((contact) => contact.emailAddress === title);
  const knownContactTypes = contacts.map((x) => x.contactType).filter((x) => x !== undefined);
  //filter knownContactTypes so that it only includes a value once
  const uniqueKnownContactTypes = Array.from(new Set(knownContactTypes));
  const iconTooltip = contacts.length > 0 ? `Known Contact: ${uniqueKnownContactTypes.join(', ')}` : '';

  return (
    <Stack direction={'row'}>
      <Tooltip title={tooltipTitle}>
        {isThread ? (
          <Link sx={{ cursor: 'pointer' }} color="inherit">
            <Typography fontWeight={!isRead ? 'bold' : ''} variant={'body2'}>
              {title}
            </Typography>
          </Link>
        ) : (
          <Typography variant={'body2'} fontWeight={!isRead ? 'bold' : ''}>
            {title}
          </Typography>
        )}
      </Tooltip>
      {contacts && (
        <Tooltip title={iconTooltip}>
          <VerifiedUserIcon color={'primary'} sx={{ height: 15 }} />
        </Tooltip>
      )}
    </Stack>
  );
};
