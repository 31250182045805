import {
  initLoadable,
  initPageableCollection,
  Loadable,
  PageableCollection,
} from '@monkeyjump-labs/cam-fe-shared/dist/types/ApiData';
import { ICamDocument } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import {
  mapReduxEmailTemplate,
  ReduxEmailTemplate,
} from '@monkeyjump-labs/cam-fe-shared/dist/types/emailTemplateTypes';
import { mapReduxDocument } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';

export interface EmailTemplateSlice {
  emailTemplates: PageableCollection<ReduxEmailTemplate>;
  allowedKeys: Loadable<{ [key: string]: string[] }>;
}

const initialState: EmailTemplateSlice = {
  emailTemplates: initPageableCollection<ReduxEmailTemplate>(),
  allowedKeys: initLoadable<{ [key: string]: string[] }>(),
};

/* eslint-disable @typescript-eslint/no-unused-vars */
// noinspection JSUnusedLocalSymbols
export const emailTemplateSlice = createSlice({
  name: 'emailTemplate',
  initialState: initialState,
  reducers: {
    listEmailTemplatesAction: (
      state,
      action: PayloadAction<{ propertyId: string; page: number; pageSize: number }>,
    ) => {
      state.emailTemplates.loading = true;
      state.emailTemplates.loaded = false;
    },
    listEmailTemplatesSuccessAction: (state, action) => {
      state.emailTemplates.loading = false;
      state.emailTemplates.loaded = true;
      state.emailTemplates.value = action.payload.results.map(mapReduxEmailTemplate);
      state.emailTemplates.totalCount = action.payload.totalCount;
    },
    listEmailTemplatesFailAction: (state) => {
      state.emailTemplates.loading = false;
      state.emailTemplates.loaded = true;
    },
    addEmailTemplateAction: (
      state,
      action: PayloadAction<{ propertyId: string; emailTemplate: ReduxEmailTemplate }>,
    ) => {
      state.emailTemplates.loading = true;
      state.emailTemplates.loaded = false;
      state.emailTemplates.submitting = true;
      state.emailTemplates.submitted = false;
    },
    addEmailTemplateSuccessAction: (state, action: PayloadAction<{ newTemplate: ReduxEmailTemplate }>) => {
      state.emailTemplates.loading = false;
      state.emailTemplates.loaded = true;
      state.emailTemplates.submitting = false;
      state.emailTemplates.submitted = true;

      if (state.emailTemplates.value) {
        state.emailTemplates.value.push(action.payload.newTemplate);
      }
    },
    addEmailTemplateFailAction: (state) => {
      state.emailTemplates.loading = false;
      state.emailTemplates.loaded = false;
      state.emailTemplates.submitting = false;
      state.emailTemplates.submitted = false;
    },
    deleteEmailTemplateAction: (state, action: PayloadAction<{ id: string }>) => {
      state.emailTemplates.loading = true;
      state.emailTemplates.loaded = false;
    },
    deleteEmailTemplateSuccessAction: (state, action: PayloadAction<{ id: string }>) => {
      state.emailTemplates.loading = false;
      state.emailTemplates.loaded = true;

      if (state.emailTemplates.value) {
        state.emailTemplates.value = state.emailTemplates.value.filter((template) => template.id !== action.payload.id);
      }
    },
    deleteEmailTemplateFailAction: (state) => {
      state.emailTemplates.loading = false;
      state.emailTemplates.loaded;
    },
    updateEmailTemplateAction: (state, action: PayloadAction<{ id: string; emailTemplate: ReduxEmailTemplate }>) => {
      state.emailTemplates.loading = true;
      state.emailTemplates.loaded = false;
    },
    updateEmailTemplateSuccessAction: (state, action: PayloadAction<{ updatedTemplate: ReduxEmailTemplate }>) => {
      state.emailTemplates.loading = false;
      state.emailTemplates.loaded = true;

      if (state.emailTemplates.value) {
        const index = state.emailTemplates.value.findIndex(
          (template) => template.id === action.payload.updatedTemplate.id,
        );
        state.emailTemplates.value[index] = action.payload.updatedTemplate;
      }
    },
    updateEmailTemplateFailAction: (state) => {
      state.emailTemplates.loading = false;
      state.emailTemplates.loaded = true;
    },
    resetEmailTemplateSubmissionAction: (state) => {
      state.emailTemplates.loading = false;
      state.emailTemplates.loaded = true;
    },
    fetchKeysAction: (state, action: PayloadAction<string>) => {
      state.allowedKeys.loading = true;
      state.allowedKeys.loaded = false;
    },
    fetchKeysSuccessAction: (state, action: PayloadAction<{ [key: string]: string[] } | undefined>) => {
      state.allowedKeys.loading = false;
      state.allowedKeys.loaded = true;
      state.allowedKeys.value = action.payload;
    },
    fetchKeysFailAction: (state) => {
      state.allowedKeys.loading = false;
      state.allowedKeys.loaded = true;
    },
    addDocumentToEmailTemplateAction: (state, action: PayloadAction<{ id: string; file: File | string }>) => {
      state.emailTemplates.loading = true;
      state.emailTemplates.loaded = false;
    },
    replaceDocumentForEmailTemplateAction: (state, action: PayloadAction<{ id: string; file: File | string }>) => {
      state.emailTemplates.loading = true;
      state.emailTemplates.loaded = false;
    },
    successUploadDocumentForEmailTemplateAction: (
      state,
      action: PayloadAction<{ id: string; document: ICamDocument }>,
    ) => {
      state.emailTemplates.loading = false;
      state.emailTemplates.loaded = true;

      if (state.emailTemplates.value) {
        const index = state.emailTemplates.value.findIndex((template) => template.id === action.payload.id);
        if (!state.emailTemplates.value[index].attachments) {
          state.emailTemplates.value[index].attachments = [mapReduxDocument(action.payload.document)];
        } else {
          state.emailTemplates.value[index].attachments.push(mapReduxDocument(action.payload.document));
        }
      }
    },
    failUploadDocumentForEmailTemplateAction: (state) => {
      state.emailTemplates.loading = false;
      state.emailTemplates.loaded = true;
    },
    removeDocumentFromEmailTemplateAction: (state, action: PayloadAction<{ id: string; documentId: string }>) => {
      state.emailTemplates.loading = true;
      state.emailTemplates.loaded = false;
    },
    removeDocumentFromEmailTemplateSuccessAction: (
      state,
      action: PayloadAction<{ id: string; documentId: string }>,
    ) => {
      state.emailTemplates.loading = false;
      state.emailTemplates.loaded = true;

      if (state.emailTemplates.value) {
        const index = state.emailTemplates.value.findIndex((template) => template.id === action.payload.id);
        state.emailTemplates.value[index].attachments = state.emailTemplates.value[index].attachments?.filter(
          (attachment) => attachment.id !== action.payload.documentId,
        );
      }
    },
    removeDocumentFromEmailTemplateFailAction: (state) => {
      state.emailTemplates.loading = false;
      state.emailTemplates.loaded = true;
    },
    newEmailTemplateArrivedAction: (state, action: PayloadAction<ReduxEmailTemplate>) => {
      if (state.emailTemplates.value) {
        const index = state.emailTemplates.value.findIndex((template) => template.id === action.payload.id);
        state.emailTemplates.value[index] = action.payload;
      }
    },
  },
});

export const {
  listEmailTemplatesAction,
  listEmailTemplatesSuccessAction,
  listEmailTemplatesFailAction,
  addEmailTemplateAction,
  addEmailTemplateSuccessAction,
  addEmailTemplateFailAction,
  deleteEmailTemplateAction,
  deleteEmailTemplateSuccessAction,
  deleteEmailTemplateFailAction,
  updateEmailTemplateAction,
  updateEmailTemplateSuccessAction,
  updateEmailTemplateFailAction,
  resetEmailTemplateSubmissionAction,
  fetchKeysSuccessAction,
  fetchKeysFailAction,
  fetchKeysAction,
  addDocumentToEmailTemplateAction,
  replaceDocumentForEmailTemplateAction,
  successUploadDocumentForEmailTemplateAction,
  failUploadDocumentForEmailTemplateAction,
  removeDocumentFromEmailTemplateAction,
  removeDocumentFromEmailTemplateSuccessAction,
  removeDocumentFromEmailTemplateFailAction,
  newEmailTemplateArrivedAction,
} = emailTemplateSlice.actions;

export const useEmailTemplates = () => useSelector((r: RootState) => r.emailTemplates);
