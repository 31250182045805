import React, { FC } from 'react';
import { DogSizeAllowed, IListing } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { toStandardDate } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { NumberValueTextField } from '../../../../_shared/texfields/NumberValueTextField';
import { StyledInfoBox } from '../../../../_shared/styledComponents/StyledInfoBox';
import Stack from '@mui/material/Stack';
import { AdditionalFees } from './AdditionalFees';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { OtherAmenities } from './OtherAmenities';
import { ReduxListing } from '../../../redux/listingData';

type ListingInfoProps = {
  listing?: ReduxListing;
  onChange: (field: keyof IListing, value: any) => void;
};

export const ListingInfo: FC<ListingInfoProps> = ({ listing, onChange }) => {
  return (
    <Stack spacing={1}>
      <StyledInfoBox label={'General'}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextField
              value={listing?.bedrooms ?? ''}
              label={'Bedrooms'}
              fullWidth
              onChange={(e) => onChange('bedrooms', e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={listing?.bathrooms ?? ''}
              label={'Bathrooms'}
              fullWidth
              onChange={(e) => onChange('bathrooms', e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <NumberValueTextField
              valueUnits={'none'}
              value={listing?.squareFootage ?? ''}
              label={'Square Footage'}
              fullWidth
              onChange={(e) => onChange('squareFootage', e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              value={listing?.dateAvailable ? toStandardDate(listing?.dateAvailable) : null}
              label={'Date Available'}
              slotProps={{ textField: { fullWidth: true } }}
              onChange={(date) => onChange('dateAvailable', date)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={listing?.description ?? ''}
              label={'Description'}
              fullWidth
              multiline
              rows={3}
              onChange={(e) => onChange('description', e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={listing?.landingPage ?? ''}
              label={'Landing Page'}
              fullWidth
              onChange={(e) => onChange('landingPage', e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={listing?.youtubeUrl ?? ''}
              label={'YouTube URL'}
              fullWidth
              onChange={(e) => onChange('youtubeUrl', e.target.value)}
            />
          </Grid>
        </Grid>
      </StyledInfoBox>
      <StyledInfoBox label={'Rent and Lease Details'}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <NumberValueTextField
              valueUnits={'dollars'}
              value={listing?.price ?? ''}
              label={'Price'}
              fullWidth
              onChange={(e) => onChange('price', e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <NumberValueTextField
              valueUnits={'dollars'}
              value={listing?.deposit ?? ''}
              label={'Security Deposit'}
              fullWidth
              onChange={(e) => onChange('deposit', e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={listing?.depositDescription ?? ''}
              label={'Security Deposit Description'}
              fullWidth
              multiline
              rows={3}
              onChange={(e) => onChange('depositDescription', e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={listing?.leaseDetails ?? ''}
              label={'Lease Details'}
              fullWidth
              multiline
              rows={3}
              onChange={(e) => onChange('leaseDetails', e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider textAlign={'left'}>
              <Typography variant={'body2'} fontStyle={'italic'}>
                Other Fees
              </Typography>
            </Divider>
          </Grid>
          <Grid item xs={12}>
            <AdditionalFees />
          </Grid>
        </Grid>
      </StyledInfoBox>
      <StyledInfoBox label={'Pets'}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <FormControlLabel
              control={<Checkbox checked={listing?.allowsCats ?? false} />}
              label={'Allows Cats'}
              onChange={(e, checked) => onChange('allowsCats', checked)}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    (listing?.maxDogSizeAllowed === DogSizeAllowed.Small ||
                      listing?.maxDogSizeAllowed === DogSizeAllowed.Large) ??
                    false
                  }
                />
              }
              label={'Allows Small Dogs'}
              onChange={(e, checked) => {
                if (checked) onChange('maxDogSizeAllowed', DogSizeAllowed.Small);
                else onChange('maxDogSizeAllowed', DogSizeAllowed.None);
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={<Checkbox checked={listing?.maxDogSizeAllowed === DogSizeAllowed.Large} />}
              label={'Allows Large Dogs'}
              onChange={(e, checked) => {
                if (checked) onChange('maxDogSizeAllowed', DogSizeAllowed.Large);
                else onChange('maxDogSizeAllowed', DogSizeAllowed.Small);
              }}
            />
          </Grid>
        </Grid>
      </StyledInfoBox>
      <StyledInfoBox label={'Amenities'}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <FormControlLabel
              control={<Checkbox checked={listing?.washer ?? false} />}
              label={'Washer'}
              onChange={(e, checked) => onChange('washer', checked)}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={<Checkbox checked={listing?.dryer ?? false} />}
              label={'Dryer'}
              onChange={(e, checked) => onChange('dryer', checked)}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={<Checkbox checked={listing?.dishwasher ?? false} />}
              label={'Dishwasher'}
              onChange={(e, checked) => onChange('dishwasher', checked)}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={<Checkbox checked={listing?.furnished ?? false} />}
              label={'Is Furnished'}
              onChange={(e, checked) => onChange('furnished', checked)}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider textAlign={'left'}>
              <Typography variant={'body2'} fontStyle={'italic'}>
                Other Amenities
              </Typography>
            </Divider>
          </Grid>
          <Grid item xs={12}>
            <OtherAmenities />
          </Grid>
        </Grid>
      </StyledInfoBox>
    </Stack>
  );
};
