import React, { FC } from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { ReduxEmail, ReduxEmailThread } from '@monkeyjump-labs/cam-fe-shared/dist/types/communicationTypes';
import Box from '@mui/material/Box';
import { IKnownContactDto } from '@monkeyjump-labs/cam-fe-shared/src/services/generated/ApiClientGenerated';
import { EmailAddress } from './EmailAddress';

type EmailParticipantsCellProps = {
  handleClick: (id: string) => void;
  isThread: boolean;
  knownContacts: IKnownContactDto[];
} & GridRenderCellParams<ReduxEmailThread | ReduxEmail>;

export const isEmailThread = (e: ReduxEmailThread | ReduxEmail): e is ReduxEmailThread => {
  return e !== null && 'emails' in e;
};

export const EmailParticipantsCell: FC<EmailParticipantsCellProps> = ({
  handleClick,
  isThread,
  knownContacts,
  ...params
}) => {
  const email = isEmailThread(params.row) ? params.row.emails[0] : params.row;
  const participants = email.participants ?? [];
  const incoming = email.incoming ?? [];
  const from = email.from ?? '';
  const ccs = email.cc ?? [];
  const to = email.to ?? [];
  return (
    <Box sx={isThread ? [styles.container, styles.isThread] : { overflow: 'hidden', height: '100%' }}>
      <Box sx={{ overflow: 'hidden' }} onClick={() => handleClick(params.row.id!)}>
        {isThread ? (
          <EmailAddress
            title={incoming ? from : to[0]}
            tooltipTitle={participants.join(', ')}
            isRead={email.isRead ?? false}
            isThread
            knownContacts={knownContacts}
          />
        ) : (
          <Box>
            {incoming && (
              <EmailAddress
                tooltipTitle={participants?.join(', ')}
                title={from}
                isRead={email.isRead}
                knownContacts={knownContacts}
              />
            )}
            {to.map((t, index) => (
              <EmailAddress
                key={index}
                tooltipTitle={participants?.join(', ')}
                title={t}
                isRead={email.isRead}
                knownContacts={knownContacts}
              />
            ))}
            {ccs.map((cc, index) => (
              <EmailAddress
                key={index}
                tooltipTitle={participants?.join(', ')}
                title={cc}
                isRead={email.isRead}
                knownContacts={knownContacts}
              />
            ))}
          </Box>
        )}
      </Box>
      <Box sx={{ display: 'flex', flexGrow: 1 }}></Box>
    </Box>
  );
};

const styles = {
  container: { overflow: 'hidden', height: '100%' },
  isThread: { display: 'flex', alignItems: 'center' },
};
