import React, { FC } from 'react';
import {
  ExpenseElementType,
  quoteStatusMap,
  ReduxExpense,
  ReduxQuote,
} from '@monkeyjump-labs/cam-fe-shared/dist/types/expenseTypes';
import {
  AccountType,
  IContactPerson,
  ISlimAccountRef,
  IVendor,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { useDispatch } from 'react-redux';
import { setQuoteSubmittingValueAction } from '../../redux/expenseSlice';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { VendorAutocomplete } from '../VendorAutocomplete';
import { AccountAutocomplete } from '../../../autocompletes/AccountAutocomplete';
import { NumberValueTextField } from '../../../texfields/NumberValueTextField';
import { ExpenseStatusSelect } from '../ExpenseStatusSelect';
import { AssociateElementAutocomplete } from '../AssociateElementAutocomplete';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { toReduxDate, toStandardDate } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import { VendorContactAutocomplete } from '../VendorContactAutocomplete';
import Box from '@mui/material/Box';

type JobQuoteFormProps = {
  value: ReduxQuote;
  selectedExpense?: ReduxExpense;
  onChangeQuote: (field: keyof ReduxQuote, value: string | number) => void;
  onChangeVendor: (value: IVendor | undefined) => void;
  propertyId: string;
  expenseAccount?: ISlimAccountRef;
  onChangeExpenseAccount: (account: ISlimAccountRef) => void;
  editingMode?: boolean;
  onAddNewVendor?: (name: string) => void;
  onAssociateWorkOrder: (association?: string, vendor?: IVendor, propertyAccount?: ISlimAccountRef) => void;
  onChangeVendorContact?: (value: IContactPerson | undefined) => void;
};
export const QuoteForm: FC<JobQuoteFormProps> = ({
  value,
  selectedExpense,
  onChangeQuote,
  onChangeExpenseAccount,
  expenseAccount,
  onChangeVendor,
  onAddNewVendor,
  editingMode,
  propertyId,
  onAssociateWorkOrder,
  onChangeVendorContact,
}) => {
  const dispatch = useDispatch();
  const handleEditingModeAddNewVendor = (name: string) => {
    dispatch(setQuoteSubmittingValueAction({ ...value, vendor: { name: name } }));
    onAddNewVendor?.(name);
  };

  return (
    <Box width={'100%'}>
      <Grid container spacing={2}>
        <Grid item xs={editingMode ? 6 : 12}>
          <TextField
            fullWidth
            required
            label="Name"
            value={value.name}
            variant={'outlined'}
            onChange={(e) => onChangeQuote('name', e.target.value)}
          />
        </Grid>
        {editingMode && (
          <Grid item xs={editingMode ? 6 : 12}>
            <ExpenseStatusSelect
              value={value.expenseQuoteStatus}
              onChange={(e) => onChangeQuote('expenseQuoteStatus', e.target.value)}
              statusMap={quoteStatusMap}
              type={ExpenseElementType.Quote}
            />
          </Grid>
        )}
        <Grid item xs={editingMode ? 6 : 12}>
          <AssociateElementAutocomplete
            selectedExpense={selectedExpense}
            value={value.associatedWorkOrder}
            type={ExpenseElementType.Quote}
            onChange={onAssociateWorkOrder}
            renderInput={(params) => (
              <TextField margin="dense" variant="outlined" label="Associated Work Order" {...params} />
            )}
          />
        </Grid>
        <Grid item xs={editingMode ? 6 : 12}>
          <DatePicker
            label={'Date Received'}
            onChange={(date: Date | null) => {
              if (date) onChangeQuote('dateReceived', toReduxDate(date));
              else onChangeQuote('dateReceived', '');
            }}
            slotProps={{ textField: { fullWidth: true }, field: { clearable: true } }}
            value={value.dateReceived ? toStandardDate(value.dateReceived) : null}
          />
        </Grid>
        <Grid item xs={editingMode ? 6 : 12}>
          <VendorAutocomplete
            propertyId={propertyId}
            onChange={onChangeVendor}
            renderInput={(params) => <TextField margin="dense" variant="outlined" label="Vendor" {...params} />}
            onAddNewVendor={editingMode ? handleEditingModeAddNewVendor : onAddNewVendor}
            value={value.vendor?.name}
          />
        </Grid>
        {value.vendor && (
          <Grid item xs={editingMode ? 6 : 12}>
            <VendorContactAutocomplete
              vendor={value.vendor}
              onChange={onChangeVendorContact}
              renderInput={(params) => (
                <TextField margin="dense" variant="outlined" label="Vendor Contact" {...params} />
              )}
              value={value.vendorContact ?? null}
            />
          </Grid>
        )}
        <Grid item xs={editingMode ? 6 : 12}>
          <AccountAutocomplete
            value={expenseAccount}
            propertyId={propertyId}
            onChange={onChangeExpenseAccount}
            accountType={AccountType.Expenses}
            renderInput={(params) => (
              <TextField margin="dense" variant="standard" label="Expense Account" {...params} />
            )}
          />
        </Grid>
        <Grid item xs={editingMode ? 6 : 12}>
          <NumberValueTextField
            fullWidth
            required
            valueUnits={'dollars'}
            label="Amount"
            value={value.quoteAmount !== 0 ? value.quoteAmount : ''}
            type="number"
            onChange={(e) => onChangeQuote('quoteAmount', e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Description"
            value={value.description}
            variant={'outlined'}
            multiline
            rows={3}
            onChange={(e) => onChangeQuote('description', e.target.value)}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
