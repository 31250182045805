import React, { FC, useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import { NumberValueTextField } from '../../../texfields/NumberValueTextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { StripedDataGrid } from '../../../datagrids/StripedDataGrid';
import { deleteEstimateFromExpenseAction, updateEstimateAction, useExpenses } from '../../redux/expenseSlice';
import {
  GridCellParams,
  GridColDef,
  GridEventListener,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridValidRowModel,
  MuiEvent,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import { tryFormatDate } from '../../../utils/TryFormatDate';
import { ReduxExpenseEstimate } from '@monkeyjump-labs/cam-fe-shared/dist/types/expenseTypes';
import { ActionCell } from '../../../datagrids/ActionCell';
import { useDispatch } from 'react-redux';
import { AddEstimateDialog } from './AddEstimateDialog';
import { useExpenseAnalysis } from './useExpenseAnalysis';
import { ReduxTenant } from '@monkeyjump-labs/cam-fe-shared/dist/types/leaseTypes';
import { showToastMessageAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import { useTheme } from '@mui/material';
import TextField from '@mui/material/TextField';
import { useIcons } from '../../../icons/useIcons';

export const SimpleCostAnalysis: FC = () => {
  const { selectedExpense } = useExpenses();
  const { getActionIcon, ActionType } = useIcons();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [openAddEstimate, setOpenAddEstimate] = useState(false);
  const { totalCostEstimates, totalCostPayments, costToCompleteEstimated } = useExpenseAnalysis(selectedExpense.value);
  const [rows, setRows] = useState(selectedExpense.value?.estimates ?? []);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const apiRef = useGridApiRef();

  useEffect(() => {
    setRows(selectedExpense.value?.estimates ?? []);
  }, [selectedExpense.value?.estimates]);

  function* createActions(params: GridRowParams<ReduxExpenseEstimate>) {
    const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;
    if (isInEditMode) {
      yield (
        <ActionCell
          icon={getActionIcon(ActionType.Save)}
          key="saveAction"
          label="Save"
          onClick={handleStopEdit(params.id)}
          color="primary"
          showInMenu={undefined}
        />
      ),
        yield (
          <ActionCell
            icon={getActionIcon(ActionType.Cancel)}
            key="cancelAction"
            label="Cancel"
            className="textPrimary"
            onClick={handleCancelClick(params.id)}
            color="inherit"
            showInMenu={undefined}
          />
        );
    } else {
      yield (
        <ActionCell
          icon={getActionIcon(ActionType.Delete)}
          key={'deleteEstimate'}
          label={'Delete Estimate'}
          onClick={() =>
            selectedExpense.value?.id &&
            params.row.number &&
            dispatch(
              deleteEstimateFromExpenseAction({
                expenseId: selectedExpense.value?.id,
                estimateNumber: params.row.number,
              }),
            )
          }
        />
      );
    }
  }

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const handleStopEdit = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleRowEditStart = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent>) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (params: GridRowParams<ReduxTenant>) => {
    setRowModesModel({ ...rowModesModel, [params.id]: { mode: GridRowModes.Edit } });
  };

  const handleCellKeyDown = (params: GridCellParams, event: React.KeyboardEvent) => {
    if (event.key === 'Enter' || event.key === 'Return') {
      event.preventDefault();
      handleStopEdit(params.id)();
    }
  };

  const saveEditRow = (newRow: GridValidRowModel, oldRow: GridValidRowModel) => {
    if (!selectedExpense.value?.id) return oldRow;
    if (newRow.name === '' || newRow.amount === '') {
      dispatch(
        showToastMessageAction({
          message: 'Name and amount must be entered before saving',
          severity: 'warning',
        }),
      );
      return oldRow;
    } else {
      if (newRow) {
        const updatedEstimate = newRow;
        dispatch(
          updateEstimateAction({
            expenseId: selectedExpense.value.id,
            body: updatedEstimate,
          }),
        );
      }
      return newRow;
    }
  };

  const handleNoteChange = (
    params: GridRenderEditCellParams,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    apiRef.current?.setEditCellValue({ id: params.id, field: params.field, value: event.target.value });
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Estimate',
      flex: 1,
      display: 'flex',
      editable: true,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      type: 'number',
      flex: 1,
      display: 'flex',
      renderCell: (params: GridRenderCellParams) => <>${Number(params.value).toFixed(2)}</>,
      editable: true,
    },
    {
      field: 'createdBy',
      headerName: 'Created By',
      flex: 1,
      display: 'flex',
      editable: false,
    },
    {
      field: 'dateCreated',
      headerName: 'Date Created',
      flex: 1,
      display: 'flex',
      renderCell: (params: GridRenderCellParams) => <>{params.value ? tryFormatDate(params.value) : ''}</>,
      editable: false,
    },
    {
      field: 'note',
      headerName: 'Notes',
      display: 'flex',
      type: 'string',
      flex: 2,
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <TextField
            multiline
            rows={3}
            value={params.value}
            onChange={(event) => handleNoteChange(params, event)}
            fullWidth
          />
        );
      },
      editable: true,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      flex: 0.75,
      getActions: (params: GridRowParams) => Array.from(createActions(params)),
    },
  ];
  return (
    <>
      <Stack spacing={1}>
        <Stack flexDirection={'row'}>
          <NumberValueTextField
            fullWidth
            valueUnits={'dollars'}
            variant={'outlined'}
            value={totalCostEstimates.toFixed(2)}
            InputProps={{ readOnly: true }}
            label={'Total Cost Estimate'}
            sx={{ mr: 1 }}
            error={costToCompleteEstimated < 0}
            helperText={costToCompleteEstimated < 0 ? '*Estimates need updating' : ''}
          />
          <NumberValueTextField
            fullWidth
            valueUnits={'dollars'}
            variant={'outlined'}
            value={totalCostPayments.toFixed(2)}
            InputProps={{ readOnly: true }}
            label={'Total Cost To Date'}
            sx={{ mr: 1 }}
            error={costToCompleteEstimated < 0}
          />
          <NumberValueTextField
            fullWidth
            valueUnits={'dollars'}
            variant={'outlined'}
            value={costToCompleteEstimated.toFixed(2)}
            InputProps={{ readOnly: true }}
            label={'Cost to Complete'}
            sx={{ input: { color: costToCompleteEstimated < 0 ? theme.palette.error.main : 'inherit' } }}
          />
        </Stack>
        <Box>
          <Button variant={'outlined'} startIcon={<AddIcon />} onClick={() => setOpenAddEstimate(true)}>
            Add Estimate
          </Button>
        </Box>
        <StripedDataGrid
          disableRowGrouping
          rows={rows ?? []}
          columns={columns}
          autoHeight
          getRowId={(x) => x.number}
          getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
          editMode="row"
          rowModesModel={rowModesModel}
          onCellKeyDown={handleCellKeyDown}
          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={saveEditRow}
          onRowClick={(params) => {
            handleEditClick(params);
          }}
          apiRef={apiRef}
          getRowHeight={() => 'auto'}
          rowHeight={100}
        />
      </Stack>
      <AddEstimateDialog onClose={() => setOpenAddEstimate(false)} open={openAddEstimate} />
    </>
  );
};
