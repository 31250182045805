import React, { FC, useEffect, useState } from 'react';
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers-pro';
import { addDays, addHours } from 'date-fns';
import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import { toReduxDate, toStandardDate } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import { ReduxTimeConfiguration } from '@monkeyjump-labs/cam-fe-shared/dist/types/taskTypes';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';

type ScheduleTimeConfigurationProps = {
  timeConfiguration?: ReduxTimeConfiguration;
  onSetTimeConfiguration: (timeConfiguration: ReduxTimeConfiguration) => void;
  editingMode?: boolean;
  onRemoveScheduling?: () => void;
  isNotScheduledTask?: boolean;
};

export const ScheduleTimeConfiguration: FC<ScheduleTimeConfigurationProps> = ({
  timeConfiguration,
  onSetTimeConfiguration,
  editingMode,
  onRemoveScheduling,
  isNotScheduledTask,
}) => {
  const { selectedContext } = useAssets();
  const [allDay, setAllDay] = useState(timeConfiguration?.isAllDay ?? false);
  const [shouldNotifyIfLate, setShouldNotifyIfLate] = useState(timeConfiguration?.shouldNotifyIfLate);
  const [startDate, setStartDate] = useState<Date | null>(
    timeConfiguration?.startDateTime ? toStandardDate(timeConfiguration?.startDateTime) : null,
  );
  const [endDate, setEndDate] = useState<Date | null>(
    timeConfiguration?.endDateTime ? toStandardDate(timeConfiguration?.endDateTime) : null,
  );
  useEffect(() => {
    setStartDate(timeConfiguration?.startDateTime ? toStandardDate(timeConfiguration?.startDateTime) : null);
  }, [timeConfiguration?.startDateTime]);
  useEffect(() => {
    setEndDate(timeConfiguration?.endDateTime ? toStandardDate(timeConfiguration?.endDateTime) : null);
  }, [timeConfiguration?.endDateTime]);
  useEffect(() => {
    setAllDay(timeConfiguration?.isAllDay ?? false);
  }, [timeConfiguration?.isAllDay]);

  const handleIsAllDay = (allDay: boolean) => {
    const startDateStringWithoutTimeStamp = toReduxDate(timeConfiguration?.startDateTime);
    const endDateStringWithoutTimeStamp = toReduxDate(timeConfiguration?.endDateTime);

    onSetTimeConfiguration &&
      onSetTimeConfiguration({
        ...timeConfiguration,
        startDateTime: startDateStringWithoutTimeStamp,
        endDateTime: endDateStringWithoutTimeStamp,
        isAllDay: allDay,
        shouldNotifyIfLate: shouldNotifyIfLate,
      });
  };

  const handleShouldNotifyIfLate = () => {
    setShouldNotifyIfLate(!shouldNotifyIfLate);
    onSetTimeConfiguration &&
      onSetTimeConfiguration({
        ...timeConfiguration,
        shouldNotifyIfLate: !shouldNotifyIfLate,
      });
  };

  const handleAddScheduling = () => {
    const initializeDate = addDays(toStandardDate(selectedContext.currentDate ?? ''), 1);
    const blankTimeConfiguration: ReduxTimeConfiguration = {
      startDateTime: addHours(initializeDate, 6).toISOString(), //beginning of day
      endDateTime: addHours(initializeDate, 18).toISOString(), //EOD
      isAllDay: true,
      shouldNotifyIfLate: true,
    };
    onSetTimeConfiguration(blankTimeConfiguration);
  };

  const dateTimePickerSlotProps = {
    textField: { required: true, fullWidth: true },
    digitalClockSectionItem: {
      sx: {
        minWidth: 75,
        justifyContent: 'left',
      },
    },
    popper: {
      sx: {
        '& .MuiMultiSectionDigitalClockSection-root': {
          minWidth: 75,
        },
      },
    },
  };

  if (!timeConfiguration) {
    return (
      <Grid container display={'flex'} justifyContent={'center'}>
        <Button variant={'outlined'} onClick={handleAddScheduling}>
          Add Scheduling
        </Button>
      </Grid>
    );
  }

  return (
    <Grid container spacing={2}>
      {allDay ? (
        <>
          <Grid item xs={12} md={editingMode ? 6 : 12}>
            <DatePicker
              label={'Scheduled Start'}
              onChange={(date) => {
                console.log('date', date?.toISOString());
                onSetTimeConfiguration({
                  ...timeConfiguration,
                  startDateTime: date ? date.toISOString() : undefined,
                });
              }}
              value={startDate}
              slotProps={{ textField: { required: true, fullWidth: true } }}
              minDate={isNotScheduledTask ? undefined : addDays(toStandardDate(selectedContext.currentDate ?? ''), 1)}
            />
          </Grid>
          <Grid item xs={12} md={editingMode ? 6 : 12}>
            <DatePicker
              label={'Scheduled End'}
              onChange={(date) => {
                console.log('date', date?.toISOString());
                onSetTimeConfiguration({
                  ...timeConfiguration,
                  endDateTime: date ? date.toISOString() : undefined,
                });
              }}
              value={endDate}
              slotProps={{ textField: { required: true, fullWidth: true } }}
              minDate={isNotScheduledTask ? undefined : addDays(toStandardDate(selectedContext.currentDate ?? ''), 1)}
            />
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12} md={editingMode ? 6 : 12}>
            <DateTimePicker
              label={'Scheduled Start'}
              onChange={(date: Date | null) => {
                onSetTimeConfiguration({
                  ...timeConfiguration,
                  startDateTime: date ? date.toISOString() : undefined,
                });
              }}
              value={startDate}
              slotProps={dateTimePickerSlotProps}
            />
          </Grid>
          <Grid item xs={12} md={editingMode ? 6 : 12}>
            <DateTimePicker
              label={'Scheduled End'}
              onChange={(date: Date | null) => {
                onSetTimeConfiguration({
                  ...timeConfiguration,
                  endDateTime: date ? date.toISOString() : undefined,
                });
              }}
              value={endDate}
              slotProps={dateTimePickerSlotProps}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <Box sx={{ minHeight: '2rem' }}>
          <FormControl>
            <FormControlLabel
              control={<Checkbox checked={allDay} onChange={() => handleIsAllDay(!allDay)} />}
              label={'All Day'}
            />
          </FormControl>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ minHeight: '2rem' }}>
          <FormControl>
            <FormControlLabel
              control={<Checkbox checked={shouldNotifyIfLate} onChange={handleShouldNotifyIfLate} />}
              label={'Notify If Task Is Late'}
            />
          </FormControl>
        </Box>
      </Grid>
      <Grid item xs={10}>
        <Box>
          <FormControl>
            <FormControlLabel
              control={
                <Switch
                  checked={timeConfiguration?.showInCalendar}
                  onChange={(e) =>
                    onSetTimeConfiguration({
                      ...timeConfiguration,
                      showInCalendar: e.target.checked,
                    })
                  }
                />
              }
              label={'Show In Calendar'}
            />
          </FormControl>
        </Box>
      </Grid>
      {onRemoveScheduling && (
        <Grid item>
          <Button variant={'outlined'} onClick={onRemoveScheduling}>
            Remove Scheduling
          </Button>
        </Grid>
      )}
    </Grid>
  );
};
