import React, { FC, useEffect, useState } from 'react';
import Chip from '@mui/material/Chip';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { ICamAssociation } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { useParams } from 'react-router-dom';
import { AssetParams } from '../../../AppRouter';
import { useAssociations } from './useAssociations';
import { AssociationListItem } from './AssociationListItem';
import List from '@mui/material/List';
import AddLinkIcon from '@mui/icons-material/AddLink';

type AssociationCellProps = {
  onOpenAddAssociation?: () => void;
  onRemoveAssociation?: (association: ICamAssociation) => void;
  associations: ICamAssociation[];
  tableType?: 'email' | 'notifications' | 'details' | 'purchaseOrderLineItems';
  onClose?: () => void;
};

export const AssociationCell: FC<AssociationCellProps> = ({
  onOpenAddAssociation,
  onRemoveAssociation,
  associations,
  tableType,
  onClose,
}) => {
  const { id } = useParams<AssetParams>();
  const { handleGetChipColor, handleGetLabel, handleListItemClick } = useAssociations();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [label, setLabel] = useState<string | undefined>();
  const [chipColor, setChipColor] = useState<
    'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | undefined
  >();

  useEffect(() => {
    const l = handleGetLabel(associations);
    setLabel(l);
    const color = handleGetChipColor(associations);
    if (color) {
      setChipColor(color);
    }
  }, [associations]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleRemoveAssociation = (association: ICamAssociation) => {
    setAnchorEl(null);
    onRemoveAssociation && onRemoveAssociation(association);
  };

  const open = Boolean(anchorEl);
  const menuId = open ? 'association-link-menu' : undefined;
  return (
    <>
      {onOpenAddAssociation && (
        <Tooltip title={'Add Association'}>
          <IconButton aria-label={'add-association'} size={'small'} onClick={() => onOpenAddAssociation()}>
            <AddLinkIcon />
          </IconButton>
        </Tooltip>
      )}
      {label && (
        <Tooltip title={label}>
          <Chip aria-describedby={id} onClick={handleClick} label={label} color={chipColor}></Chip>
        </Tooltip>
      )}
      <Popper id={menuId} open={open} anchorEl={anchorEl} sx={{ zIndex: 999999 }} placement={'bottom-end'}>
        <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
          <Paper
            sx={{
              p: 2,
              minWidth: '10rem',
            }}
          >
            <List>
              {associations.map((a: ICamAssociation) => (
                <AssociationListItem
                  key={a.associatedId}
                  a={a}
                  onListItemSelect={() => {
                    setAnchorEl(null);
                    handleListItemClick(a, tableType === 'email');
                    onClose && onClose();
                  }}
                  onRemoveAssociation={onRemoveAssociation ? handleRemoveAssociation : undefined}
                />
              ))}
            </List>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};
