import { ExpenseElementType } from '@monkeyjump-labs/cam-fe-shared/dist/types/expenseTypes';

const getAssetTypeFromPathname = () => {
  const pathnameArray = location.pathname.split('/');
  if (pathnameArray.includes('property')) {
    return 'property';
  } else if (pathnameArray.includes('building')) {
    return 'building';
  } else {
    return 'unit';
  }
};

const getJobElementTypeFromPathname = (): ExpenseElementType | undefined => {
  const pathnameArray = location.pathname.split('/');
  if (pathnameArray.includes('quotes')) {
    return ExpenseElementType.Quote;
  } else if (pathnameArray.includes('payments')) {
    return ExpenseElementType.Payment;
  } else if (pathnameArray.includes('invoices')) {
    return ExpenseElementType.Invoice;
  } else if (pathnameArray.includes('purchaseOrders')) {
    return ExpenseElementType.PurchaseOrder;
  } else if (pathnameArray.includes('workOrders')) {
    return ExpenseElementType.WorkOrder;
  } else return undefined;
};
export { getAssetTypeFromPathname, getJobElementTypeFromPathname };
