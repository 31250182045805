import React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { currencyFormatter } from '../utils/currencyFormatter';
import Skeleton from '@mui/material/Skeleton';

// Props for the CompactCard component
interface CompactCardProps {
  title: string;
  numberTransactions: number;
  amount: number;
  headerBackgroundColor: string;
  contentBackgroundColor: string;
  outlined?: boolean;
  loading: boolean;
  handleClick: () => void;
}

const FinancialTransactionCard: React.FC<CompactCardProps> = ({
  title,
  numberTransactions,
  amount,
  headerBackgroundColor,
  contentBackgroundColor,
  loading,
  handleClick,
  outlined = false,
}) => {
  const outLinedStyle = outlined ? { border: `2px solid ${headerBackgroundColor}` } : {};

  return loading ? (
    <Skeleton width={150} height={100} variant="rectangular"></Skeleton>
  ) : (
    <Card sx={{ maxWidth: 300, ...outLinedStyle, cursor: 'pointer' }} onClick={handleClick}>
      <CardHeader
        sx={{
          backgroundColor: headerBackgroundColor,
          padding: '0px',
        }}
        title={title}
        titleTypographyProps={{
          variant: 'h6',
          color: 'primary',
          padding: '2px',
        }}
      />
      <CardContent
        sx={{
          display: 'flex',
          padding: '2px',
          justifyContent: 'center',
          backgroundColor: outlined ? 'default' : contentBackgroundColor,
          '&.MuiCardContent-root:last-child': { paddingBottom: '2px' },
        }}
      >
        <Stack direction={'column'} alignItems={'center'}>
          <Typography variant="h3" gutterBottom component="div" sx={{ fontWeight: 'normal' }}>
            {numberTransactions}
            <Typography variant="body1" component={'span'} sx={{ marginLeft: '0.5em' }}>
              Transactions
            </Typography>
          </Typography>
          <Typography variant="h5" component="div">
            {currencyFormatter.format(amount)}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default FinancialTransactionCard;
