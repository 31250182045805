import React, { FC, useEffect, useState } from 'react';
import { ReduxDocument } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import { ExpenseElementType, ReduxExpense } from '@monkeyjump-labs/cam-fe-shared/dist/types/expenseTypes';
import Box from '@mui/material/Box';
import {
  AssociationType,
  GetExpenseHandlerResponse,
  IVendor,
  SimpleDocumentType,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { useDispatch } from 'react-redux';
import { AddExpenseElement } from '../../expenses/components/forms/AddExpenseElement';
import { DialogLayout } from '../../dialogs/DialogLayout';
import { ApiClientSingleton } from '@monkeyjump-labs/cam-fe-shared/dist/services/buildApiClient';
import { showToastMessageAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import { PdfViewer } from '../../documents/components/PdfViewer';
import { Search } from '../../search/components/Search';
import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';
import { useGridApiRef } from '@mui/x-data-grid-premium';

type CreateExpenseElementFromAttachmentProps = {
  open: boolean;
  onClose: () => void;
  attachment?: ReduxDocument;
  emailThreadId?: string;
  type?: ExpenseElementType;
  fromEmailAddress: string;
};

export const CreateExpenseElementFromAttachment: FC<CreateExpenseElementFromAttachmentProps> = ({
  open,
  onClose,
  emailThreadId,
  attachment,
  type,
  fromEmailAddress,
}) => {
  const dispatch = useDispatch();
  const gridRef = useGridApiRef();
  const [submittingExpense, setSubmittingExpense] = useState<ReduxExpense>();
  const [title, setTitle] = useState('');
  const [vendor, setVendor] = useState<IVendor | undefined>();
  const [error, setError] = useState<string | undefined>();

  useEffect(() => {
    if (fromEmailAddress) {
      const vendorEmails: string[] = [];
      if (vendor?.contactInfo?.email) vendorEmails.push(vendor.contactInfo.email);
      if (vendor?.primaryContact?.contactInfo?.email) vendorEmails.push(vendor.primaryContact?.contactInfo?.email);
      if (vendor?.contacts)
        vendor.contacts.forEach((contact) => {
          if (contact.contactInfo?.email) vendorEmails.push(contact.contactInfo.email);
        });
      if (vendor && !vendorEmails.includes(fromEmailAddress)) {
        setError('The vendor email list does not include the sender email');
      } else {
        setError(undefined);
      }
    }
  }, [vendor]);

  const handleSetExpenseAssociation = (associationType?: AssociationType, associatedId?: string) => {
    switch (associationType) {
      case AssociationType.Expense:
        associatedId && handleFetchExpense(associatedId);
        break;
      default:
        break;
    }
  };

  const handleFetchExpense = async (id: string) => {
    try {
      const response: GetExpenseHandlerResponse = await ApiClientSingleton.getInstance().expenses_GetExpense(id);
      if (response) setSubmittingExpense(response.toJSON().expenseDto);
    } catch (e) {
      console.error(e);
      dispatch(showToastMessageAction({ message: 'Error fetching expense details', severity: 'error' }));
    }
  };

  const handleClose = () => {
    setError(undefined);
    onClose();
  };

  return (
    <DialogLayout
      title={title}
      onClose={handleClose}
      open={open}
      fullScreen={true}
      level={error ? 'error' : undefined}
      subtitle={error}
    >
      <Stack direction={'row'}>
        {attachment && attachment.metadata?.documentType === SimpleDocumentType.Document ? (
          <PdfViewer
            document={attachment}
            association={{ associatedId: emailThreadId, associationType: AssociationType.EmailThread }}
          />
        ) : (
          <Box p={1} maxWidth={'50%'}>
            {attachment && <img src={attachment.uri} alt={attachment.name} style={{ maxWidth: '100%' }} />}
          </Box>
        )}
        <DialogContent sx={{ maxWidth: '50%' }}>
          {attachment && (
            <Search
              searchTypes={[AssociationType.Expense]}
              onSelectAssociation={handleSetExpenseAssociation}
              options={['Expenses']}
              global={false}
              placeholder={`Select Expense to add ${type} to`}
            />
          )}
          <AddExpenseElement
            attachmentDetails={{ emailThreadId: emailThreadId, attachment: attachment }}
            type={type}
            expense={submittingExpense}
            open={open}
            onClose={handleClose}
            setTitle={(title: string) => setTitle(title)}
            title={title}
            gridRef={gridRef}
            setVendor={(vendor: IVendor | undefined) => setVendor(vendor)}
          />
        </DialogContent>
      </Stack>
    </DialogLayout>
  );
};
