import React, { FC, FormEvent, useEffect, useState } from 'react';
import { ReduxQuote } from '@monkeyjump-labs/cam-fe-shared/dist/types/expenseTypes';
import { useDispatch } from 'react-redux';
import { updateQuoteAction, useExpenses } from '../../redux/expenseSlice';
import {
  IContactPerson,
  ISlimAccountRef,
  IVendor,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import Stack from '@mui/material/Stack';
import { QuoteForm } from './QuoteForm';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';

type ViewQuoteDetailsProps = {
  quote: ReduxQuote;
  propertyId: string;
  jobId: string;
  onAddNewVendor: (name: string) => void;
};
export const ViewQuoteDetails: FC<ViewQuoteDetailsProps> = ({ quote, onAddNewVendor, jobId, propertyId }) => {
  const dispatch = useDispatch();
  const { selectedQuote } = useExpenses();
  const [editingQuote, setEditingQuote] = useState<ReduxQuote>(selectedQuote.submittingValue ?? quote);
  const [dirty, setDirty] = useState(false);

  useEffect(() => {
    setEditingQuote(selectedQuote.submittingValue ?? quote);
    selectedQuote.submittingValue && setDirty(true);
  }, [selectedQuote.submittingValue, quote]);
  const handleChangeQuote = (field: keyof ReduxQuote, value: string | number) => {
    setEditingQuote({ ...editingQuote, [field]: value });
    setDirty(true);
  };
  const handleChangeVendor = (value: IVendor | undefined) => {
    setEditingQuote({ ...editingQuote, vendor: value });
    setDirty(true);
  };
  const handleChangeVendorContact = (value: IContactPerson | undefined) => {
    setEditingQuote({ ...editingQuote, vendorContact: value });
    setDirty(true);
  };
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    dispatch(updateQuoteAction({ jobId: jobId, quote: editingQuote }));
    setDirty(false);
  };
  const handleChangeAssociation = (association?: string, vendor?: IVendor, propertyAccount?: ISlimAccountRef) => {
    setEditingQuote({
      ...editingQuote,
      associatedWorkOrder: association,
      vendor: vendor ?? editingQuote.vendor,
      propertyAccount: propertyAccount ?? editingQuote.propertyAccount,
    });
    setDirty(true);
  };
  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={2}>
        <QuoteForm
          value={editingQuote}
          onChangeQuote={handleChangeQuote}
          onChangeVendor={handleChangeVendor}
          onChangeVendorContact={handleChangeVendorContact}
          propertyId={propertyId}
          expenseAccount={editingQuote.propertyAccount}
          onChangeExpenseAccount={(account: ISlimAccountRef) => {
            setDirty(true);
            setEditingQuote({ ...editingQuote, propertyAccount: account });
          }}
          onAddNewVendor={onAddNewVendor}
          editingMode
          onAssociateWorkOrder={handleChangeAssociation}
        />
        <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'right' }}>
          <LoadingButton variant={'contained'} disabled={!dirty} type={'submit'} loading={selectedQuote.submitting}>
            Save Changes
          </LoadingButton>
        </Box>
      </Stack>
    </form>
  );
};
