import React, { FC, HTMLAttributes } from 'react';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { ExpenseElementOption } from '@monkeyjump-labs/cam-fe-shared/dist/types/expenseTypes';

type JobElementListItemProps = {
  option: ExpenseElementOption;
} & HTMLAttributes<HTMLLIElement>;

export const ExpenseElementListItem: FC<JobElementListItemProps> = ({ option, ...props }) => {
  return (
    <ListItem key={option.number} {...props}>
      <Stack>
        <Typography>{option.label}</Typography>
        {option.vendorName && <Typography>{option.vendorName}</Typography>}
        {option.status && <Typography variant={'subtitle2'}>{option.status}</Typography>}
        {option.amount && <Typography variant={'subtitle2'}>Amount: ${option.amount}</Typography>}
        <Divider
          sx={{
            mt: '.2rem',
          }}
        />
      </Stack>
    </ListItem>
  );
};
