import { all, put, takeEvery } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  AssociationType,
  ICamAssociation,
  ICamDocument,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import {
  resetAssociationSubmissionAction,
  setAssociationSubmittingSuccessAction,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/search/searchSlice';
import {
  addAssociationToEmailAction,
  addAssociationToEmailFailAction,
  addAssociationToEmailSuccessAction,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/communications/communicationSlice';
import {
  addAttachmentToSelectedExpenseAction,
  addAttachmentToSelectedExpenseFailAction,
  addAttachmentToSelectedExpenseSuccessAction,
  getSelectedExpenseByIdAction,
} from '../../areas/_shared/expenses/redux/expenseSlice';
import {
  addAssociationToDetailFailAction,
  addAssociationToDetailSuccessAction,
  getDetailByIdAction,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/details/detailsSlice';
import {
  add360PhotoToTypeSuccessAction,
  addDocumentToTypeSuccessAction,
  removeDocumentFromTypeSuccessAction,
  updateDocumentMetadataSuccessAction,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/documents/documentSlice';
import { getSelectedTaskAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/tasks/taskSlice';
import { getScheduledTaskByIdAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/tasks/scheduledTaskSlice';
import { getSingleAutomationByPropertyIdAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/tasks/sopTaskSlice';
import { getListingAction } from '../../areas/listings/redux/listingSlice';
import { getSingleLeaseAction } from '../../areas/_shared/leases/redux/leasesSlice';
import { getSingleApplicationByIdAction } from '../../areas/property/redux/applicationSlice';
import { exportGlEntriesSuccessAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/documents/documentSlice';
import { saveAs } from 'file-saver';

function* updateSelectedExpenseWhenAddedToEmail(action: PayloadAction<AssociationType>) {
  if (action.payload === AssociationType.Expense) {
    yield put(addAttachmentToSelectedExpenseAction());
  }
}

function* updateSelectedExpenseWhenAddedToEmailSuccess(action: PayloadAction<AssociationType>) {
  yield put(setAssociationSubmittingSuccessAction());
  if (action.payload === AssociationType.Expense) {
    yield put(addAttachmentToSelectedExpenseSuccessAction());
  }
}

function* updateSelectedExpenseWhenAddedToEmailFail(action: PayloadAction<AssociationType>) {
  yield put(resetAssociationSubmissionAction());
  if (action.payload === AssociationType.Expense) {
    yield put(addAttachmentToSelectedExpenseFailAction());
  }
}

function* updateAssociationSubmissionStateSuccess() {
  yield put(setAssociationSubmittingSuccessAction());
}

function* updateAssociationSubmissionStateFail() {
  yield put(resetAssociationSubmissionAction());
}

function* downloadGLEntries(action: PayloadAction<{ file: Blob; fileName: string }>) {
  const { file, fileName } = action.payload;
  saveAs(file, fileName);
}

function* updateAssociationDocs(
  action: PayloadAction<{ doc: ICamDocument; propertyId: string; association: ICamAssociation }>,
) {
  if (!action.payload.association.associatedId) return;
  if (action.payload.association.associatedId) {
    switch (action.payload.association.associationType) {
      case AssociationType.Lease:
        yield put(getSingleLeaseAction({ leaseId: action.payload.association.associatedId, openLeaseModal: true }));
        break;
      case AssociationType.Task:
        yield put(getSelectedTaskAction({ id: action.payload.association.associatedId, includeClosed: false }));
        break;
      case AssociationType.ScheduledTask:
        yield put(getScheduledTaskByIdAction(action.payload.association.associatedId));
        break;
      case AssociationType.Detail:
      case AssociationType.DetailObservation:
        yield put(getDetailByIdAction({ detailId: action.payload.association.associatedId }));
        break;
      case AssociationType.TaskConfiguration:
        yield;
        put(
          getSingleAutomationByPropertyIdAction({
            propertyId: action.payload.propertyId,
            configId: action.payload.association.associatedId,
          }),
        );
        break;
      case AssociationType.Application:
        yield put(getSingleApplicationByIdAction(action.payload.association.associatedId));
        break;
      case AssociationType.Expense:
        yield put(getSelectedExpenseByIdAction(action.payload.association.associatedId));
        break;
      case AssociationType.Listing:
        yield put(getListingAction({ listingId: action.payload.association.associatedId }));
        break;
      default:
        break;
    }
  }
}

export function* stateSagas() {
  yield all([
    takeEvery(addAssociationToEmailAction.type, updateSelectedExpenseWhenAddedToEmail),
    takeEvery(addAssociationToEmailSuccessAction.type, updateSelectedExpenseWhenAddedToEmailSuccess),
    takeEvery(addAssociationToEmailFailAction.type, updateSelectedExpenseWhenAddedToEmailFail),
    takeEvery(addAssociationToDetailSuccessAction.type, updateAssociationSubmissionStateSuccess),
    takeEvery(addAssociationToDetailFailAction.type, updateAssociationSubmissionStateFail),
    takeEvery(exportGlEntriesSuccessAction.type, downloadGLEntries),
    takeEvery(addDocumentToTypeSuccessAction.type, updateAssociationDocs),
    takeEvery(add360PhotoToTypeSuccessAction.type, updateAssociationDocs),
    takeEvery(updateDocumentMetadataSuccessAction.type, updateAssociationDocs),
    takeEvery(removeDocumentFromTypeSuccessAction.type, updateAssociationDocs),
  ]);
}
