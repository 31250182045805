import { fork } from 'redux-saga/effects';
import { roomSagas } from '../areas/room/redux/roomSagas';
import { leasesSagas } from '../areas/_shared/leases/redux/leasesSagas';
import { applicationSagas } from '../areas/property/redux/applicationSagas';
import { statementSagas } from '../areas/_shared/statements/redux/statementSagas';
import { leaseTransactionSaga } from '../areas/_shared/leases/redux/leaseTransactionSaga';
import { tenantSagas } from '../areas/tenantPortal/redux/tenantSagas';
import { expenseSaga } from '../areas/_shared/expenses/redux/expenseSaga';
import { reportSaga } from '../areas/property/redux/reportSaga';
import { bankAccountsSaga } from '../areas/_shared/bankAccounts/redux/bankAccountsSagas';
import { unitTemplateSaga } from '../areas/_shared/unitTemplates/redux/unitTemplateSaga';
import { listingSaga } from '../areas/listings/redux/listingSaga';
import { routingSagas } from './redux/routingSagas';
import { stateSagas } from './redux/stateSagas';
import { emailTemplateSaga } from '../areas/_shared/emailTemplates/redux/emailTemplateSaga';
import { calendarSaga } from '../areas/_shared/calendar/redux/calendarSaga';
import { accountSaga } from '../areas/_shared/accounts/redux/accountSaga';
import { financialTransactionsSaga } from '@monkeyjump-labs/cam-fe-shared/dist/redux/financialTransactions/financialTransactionsSagas';

export function* rootSaga() {
  yield fork(roomSagas);
  yield fork(leasesSagas);
  yield fork(leaseTransactionSaga);
  yield fork(statementSagas);
  yield fork(applicationSagas);
  yield fork(expenseSaga);
  yield fork(tenantSagas);
  yield fork(bankAccountsSaga);
  yield fork(stateSagas);
  yield fork(reportSaga);
  yield fork(unitTemplateSaga);
  yield fork(listingSaga);
  yield fork(routingSagas);
  yield fork(emailTemplateSaga);
  yield fork(calendarSaga);
  yield fork(accountSaga);
  yield fork(financialTransactionsSaga);
}
