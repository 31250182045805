import React, { FC } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Add from '@mui/icons-material/Add';
import { StripedDataGrid, styledFilterPanelProps } from '../../../datagrids/StripedDataGrid';
import Stack from '@mui/material/Stack';
import { GridColDef, GridRenderCellParams, GridRenderEditCellParams, GridRowParams } from '@mui/x-data-grid-premium';
import {
  ExpenseElementType,
  ReduxWorkOrder,
  workOrderStatusMap,
} from '@monkeyjump-labs/cam-fe-shared/dist/types/expenseTypes';
import { NameCell } from './cells/NameCell';
import { tryFormatDate } from '../../../utils/TryFormatDate';
import { removeWorkOrderAction, useExpenses } from '../../redux/expenseSlice';
import { GlCodeCell } from './cells/GlCodeCell';
import { StatusCell, StatusEditCell } from './cells/StatusCell';
import { ActionCell } from '../../../datagrids/ActionCell';
import { useDispatch } from 'react-redux';
import { InternalAssociationCell } from './cells/InternalAssociationCell';
import EditIcon from '@mui/icons-material/Edit';
import { useIcons } from '../../../icons/useIcons';
import { AddExpenseElementAssociation } from '../dialogs/AddExpenseElementDialog';

type WorkOrderTableProps = {
  onSelectWorkOrder: (number?: string, type?: ExpenseElementType) => void;
  onOpenAddExpenseChildItem: (
    type: ExpenseElementType,
    isUpstream?: boolean,
    downstreamDetails?: AddExpenseElementAssociation,
  ) => void;
  onOpenDrawer: (number?: string, type?: ExpenseElementType) => void;
};

export const WorkOrderTable: FC<WorkOrderTableProps> = ({
  onSelectWorkOrder,
  onOpenAddExpenseChildItem,
  onOpenDrawer,
}) => {
  const dispatch = useDispatch();
  const { getActionIcon, ActionType } = useIcons();
  const { selectedExpense } = useExpenses();
  const rows = selectedExpense.value?.workOrders ?? [];

  function* createActions(params: GridRowParams<ReduxWorkOrder>) {
    if (!params.row.number) return;
    yield (
      <ActionCell
        icon={getActionIcon(ActionType.Delete)}
        key={'removeWorkOrder'}
        label={'Remove Work Order'}
        onClick={() => {
          selectedExpense.value?.id &&
            params.row.number &&
            dispatch(removeWorkOrderAction({ jobId: selectedExpense.value?.id, woNumber: params.row.number }));
        }}
        showInMenu
      />
    );
    yield (
      <ActionCell
        icon={getActionIcon(ActionType.Add)}
        key={'createQuote'}
        label={'Create Quote'}
        onClick={() =>
          onOpenAddExpenseChildItem(ExpenseElementType.WorkOrder, true, {
            association: params.row.number,
            propertyAccount: params.row.propertyAccount,
          })
        }
        showInMenu
      />
    );
  }

  const columns: GridColDef<ReduxWorkOrder>[] = [
    {
      field: 'number',
      headerName: '#',
      flex: 1,
      display: 'flex',
      renderCell: (params: GridRenderCellParams) => (
        <NameCell {...params} onOpenDrawer={onSelectWorkOrder} type={ExpenseElementType.WorkOrder} />
      ),
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      display: 'flex',
      renderCell: (params: GridRenderCellParams) => (
        <NameCell {...params} onOpenDrawer={onSelectWorkOrder} type={ExpenseElementType.WorkOrder} />
      ),
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 1,
    },
    {
      field: 'dateCreated',
      headerName: 'Date Created',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <>{params.value ? tryFormatDate(params.value ?? '') : ''}</>,
    },
    {
      field: 'dateCompleted',
      headerName: 'Date Completed',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <>{params.value ? tryFormatDate(params.value ?? '') : ''}</>,
    },
    {
      field: 'associatedQuote',
      headerName: 'Quotes',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <InternalAssociationCell
          typeToRender={ExpenseElementType.Quote}
          handleOpenDrawer={onOpenDrawer}
          number={params.row.number}
          multiplePossible={true}
          {...params}
        />
      ),
    },
    {
      field: 'propertyAccount',
      headerName: 'GL Code',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <GlCodeCell {...params} />,
    },
    {
      field: 'workOrderStatus',
      headerName: 'Status',
      flex: 1,
      display: 'flex',
      renderCell: (params: GridRenderCellParams) => <StatusCell statusMap={workOrderStatusMap} {...params} />,
      editable: true,
      renderHeader: () => {
        return (
          <>
            {'Status '}
            <EditIcon fontSize="small" color="disabled" />
          </>
        );
      },
      renderEditCell: (params: GridRenderEditCellParams) => (
        <StatusEditCell type={ExpenseElementType.WorkOrder} statusMap={workOrderStatusMap} {...params} />
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      flex: 1,
      getActions: (params: GridRowParams) => Array.from(createActions(params)),
    },
  ];
  return (
    <Stack spacing={2} mt={'1rem'}>
      <Box>
        <Button
          variant={'outlined'}
          startIcon={<Add />}
          onClick={() => onOpenAddExpenseChildItem(ExpenseElementType.WorkOrder)}
        >
          Add Work Order
        </Button>
      </Box>
      <StripedDataGrid
        disableRowGrouping
        rows={rows}
        columns={columns}
        autoHeight
        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
        slotProps={{
          filterPanel: styledFilterPanelProps,
        }}
      />
    </Stack>
  );
};
