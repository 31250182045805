import React, { FC, useEffect, useState } from 'react';
import { ExpandableCard } from '../../../_shared/styledComponents/ExpandableCard';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import { deleteTenantAccountAction, makeAccountDefaultAction, useTenant } from '../../redux/tenantSlice';
import { BankingInfoList } from '../../../_shared/paymentProcessing/modernTreasury/BankingInfoList';
import { Feature } from '../../../global/Feature';
import { useDispatch } from 'react-redux';
import { showErrorAction, showToastMessageAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import { ReduxMtAccount } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import Skeleton from '@mui/material/Skeleton';
import { BankAccountType } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { AddBankAccountButton } from '../../../property/components/financials/components/externalAccounts/AddBankAccountButton';
import { useUser } from '@monkeyjump-labs/cam-fe-shared/dist/redux/user/userSlice';
import {
  plaidRevalidateFinishAction,
  plaidTenantRefreshSuccessfulAction,
  updatePlaidLinkTokenAction,
  useBankAccounts,
} from '../../../_shared/bankAccounts/redux/bankAccountsSlice';
import { PlaidLinkOptions, usePlaidLink } from 'react-plaid-link';

type BankingInfoCardProps = {
  focus: boolean;
  propertyHasPaymentsEnabled: boolean;
};

export const BankingInfoCard: FC<BankingInfoCardProps> = ({ focus, propertyHasPaymentsEnabled }) => {
  const dispatch = useDispatch();
  const { currentUser } = useUser((u) => u);
  const { plaidProcessorToken, plaidLinkTokenUpdate } = useBankAccounts();
  const { bankAccounts } = useTenant();
  const [rows, setRows] = useState(bankAccounts?.value ?? []);
  const [getToken, setToken] = useState('');
  const [mtAccountId, setMtAccountId] = useState('');

  useEffect(() => {
    setRows(bankAccounts?.value ?? []);
  }, [bankAccounts?.value]);

  useEffect(() => {
    if (plaidLinkTokenUpdate.loaded && plaidLinkTokenUpdate.value) {
      setToken(plaidLinkTokenUpdate.value);
    }
  }, [plaidLinkTokenUpdate.loaded]);

  const handleDeleteAccount = (acct: ReduxMtAccount) => {
    if (acct.mtAccountId) {
      dispatch(
        deleteTenantAccountAction({
          acctId: acct.mtAccountId,
        }),
      );
    } else {
      dispatch(showToastMessageAction({ message: 'cannot delete user account', severity: 'error' }));
    }
  };

  const createSetDefaultHandler = (accountId: string) => {
    dispatch(makeAccountDefaultAction(accountId));
  };

  const config: PlaidLinkOptions = {
    onSuccess: () => {
      dispatch(plaidTenantRefreshSuccessfulAction({ mtAccountId: mtAccountId }));
    },

    onExit: (err) => {
      if (err != null)
        dispatch(
          showErrorAction({
            error: err?.display_message,
            fallbackMessage: "Couldn't create Plaid processor token",
          }),
        );
      dispatch(plaidRevalidateFinishAction());
    },
    token: getToken,
  };

  const plaidLinkUpdate = usePlaidLink(config);

  useEffect(() => {
    if (plaidLinkUpdate.ready && getToken && getToken !== '') plaidLinkUpdate.open();
  }, [getToken, plaidLinkUpdate.open, plaidLinkUpdate.ready]);

  const handlePlaidRefresh = (acct: ReduxMtAccount) => {
    bankAccounts?.value &&
      acct.plaidAccessToken &&
      dispatch(
        updatePlaidLinkTokenAction({
          userOrPropertyId: currentUser.value!.id!,
          bankAccountType: BankAccountType.Individual,
          accessToken: acct.plaidAccessToken,
        }),
      );
    setMtAccountId(acct.mtAccountId!);
  };

  if (bankAccounts.loading) return <Skeleton variant={'rectangular'} animation={'wave'} width={'100%'} height={250} />;
  return (
    <>
      <Feature flag={'CAM.Feature.Payments.TenantBilling'}>
        <ExpandableCard title={'Manage Accounts'} subtitle={''} focus={focus}>
          <CardContent sx={{ flexGrow: 1 }}>
            <BankingInfoList
              isPropertyList={false}
              rows={rows}
              onDeleteAccount={handleDeleteAccount}
              onSetDefaultAcct={createSetDefaultHandler}
              onPlaidRefresh={handlePlaidRefresh}
              loadingNewAccount={plaidProcessorToken.loading || bankAccounts.loading || plaidLinkTokenUpdate.loading}
            />
          </CardContent>
          {propertyHasPaymentsEnabled ? (
            <CardActions sx={{ justifyContent: 'right' }}>
              <AddBankAccountButton
                bankAccountType={BankAccountType.Individual}
                userOrPropertyId={currentUser.value!.id!}
              />
            </CardActions>
          ) : (
            <Box px={4}>
              <Typography>You cannot currently add a bank account.</Typography>
            </Box>
          )}
        </ExpandableCard>
      </Feature>
    </>
  );
};
