import React, { useEffect, useState } from 'react';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import LinearProgress from '@mui/material/LinearProgress';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import {
  updateNotificationConfigAction,
  useNotifications,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/notifications/notificationSlice';
import { useDispatch } from 'react-redux';
import {
  getNotificationConfigNotifiedUsers,
  getSupportedRoleUserOptions,
  NotifiedUserOptionType,
  ReduxNotificationConfiguration,
} from '@monkeyjump-labs/cam-fe-shared/dist/types/notificationTypes';
import { useProperty } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/propertySlice';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSopTasks } from '@monkeyjump-labs/cam-fe-shared/dist/redux/tasks/sopTaskSlice';
import { ReduxSopTask } from '@monkeyjump-labs/cam-fe-shared/dist/types/taskTypes';
import { DialogLayout } from '../../../dialogs/DialogLayout';
import { StyledInfoBox } from '../../../styledComponents/StyledInfoBox';
import { ISimpleEmailTemplateDto } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';

type Props = {
  open: boolean;
  onClose: () => void;
  notification: ReduxNotificationConfiguration;
};

export const EditConfigModal = ({ open, onClose, notification }: Props) => {
  const dispatch = useDispatch();
  const { selectedProperty } = useProperty();
  const notifications = useNotifications();
  const { sopTasks } = useSopTasks();
  const [notificationConfig, setNotificationConfig] = useState<ReduxNotificationConfiguration>(notification);
  const [selectedTaskConfig, setSelectedTaskConfig] = useState<ReduxSopTask | null>(
    notification.taskConfiguration ?? null,
  );
  const [taskConfigOptions, setTaskConfigOptions] = useState<ReduxSopTask[]>(sopTasks.value ?? []);
  const [userOptions, setUserOptions] = useState<NotifiedUserOptionType[]>([]);
  const [userValue, setUserValue] = useState<NotifiedUserOptionType[]>([]);

  useEffect(() => {
    setNotificationConfig(notification);
    const userOptionArray =
      notifications.notificationSupportedRoles.value &&
      getSupportedRoleUserOptions(notifications.notificationSupportedRoles.value.supportedRoles);
    setUserOptions(userOptionArray ?? []);
    setSelectedTaskConfig(notification.taskConfiguration ?? null);
  }, [notification]);

  useEffect(() => {
    const userValueArray = getNotificationConfigNotifiedUsers(userOptions, notification);
    setUserValue(userValueArray);
  }, [userOptions]);

  useEffect(() => {
    if (notifications.globalNotifications.submitted) onClose();
  }, [notifications.globalNotifications.submitted]);

  useEffect(() => {
    sopTasks.value && setTaskConfigOptions(sopTasks.value);
  }, [sopTasks.value]);

  const handleEnableToggle = () => {
    setNotificationConfig({ ...notificationConfig, enabled: !notificationConfig.enabled });
  };

  const saveConfigChanges = () => {
    notificationConfig.id &&
      notificationConfig.propertyId &&
      dispatch(
        updateNotificationConfigAction({
          id: notificationConfig.id,
          propertyId: notificationConfig.propertyId,
          body: {
            ...notificationConfig,
            emailTemplateId:
              notificationConfig.emailTemplateId !== 'empty' ? notificationConfig.emailTemplateId : undefined,
            taskConfiguration: selectedTaskConfig !== null ? selectedTaskConfig : undefined,
          },
        }),
      );
  };

  if (!notification) {
    return (
      <Dialog maxWidth="md" fullWidth={true} open={open} onClose={onClose}>
        <LinearProgress sx={{ width: '100%' }} variant={'indeterminate'} />;
      </Dialog>
    );
  }

  return (
    <DialogLayout
      title={`Edit Notification ${notification.code} Configuration`}
      maxWidth={'sm'}
      fullWidth
      open={open}
      onClose={onClose}
    >
      <DialogContent>
        <Stack spacing={3} sx={{ mt: '1rem' }}>
          <TextField
            fullWidth
            id="name"
            value={notification.name ? notification.name : ''}
            label="Name"
            variant="outlined"
            type="text"
            InputProps={{
              readOnly: true,
            }}
          />

          <TextField
            id="description"
            multiline={true}
            fullWidth
            value={notification.description ? notification.description : ''}
            label="Description"
            variant="outlined"
            type="text"
            InputProps={{
              readOnly: true,
            }}
          />
          {notification.valueThreshold !== undefined ? (
            <TextField
              fullWidth
              id="triggerThreshold"
              value={notificationConfig.valueThreshold}
              label="Trigger Threshold"
              variant="standard"
              type="string"
              onChange={(e) =>
                setNotificationConfig({
                  ...notificationConfig,
                  valueThreshold: e.target.value,
                })
              }
            />
          ) : (
            <></>
          )}
          <StyledInfoBox label={'Automation Configurations'}>
            <Autocomplete
              id="taskConfiguration"
              disabled={!notificationConfig.allowsEditingSopTaskConfiguration}
              options={taskConfigOptions}
              value={selectedTaskConfig ? selectedTaskConfig : null}
              filterSelectedOptions
              isOptionEqualToValue={(option, value) => {
                if (option.name && value.name) return option.name === value.name;
                else return false;
              }}
              getOptionLabel={(option) => (option.name ? option.name : '')}
              onChange={(event, value: ReduxSopTask | null) => {
                setSelectedTaskConfig(value);
              }}
              renderInput={(params) => <TextField {...params} variant="standard" label="Attached SOP Task" />}
            />
            <Autocomplete
              id="attachedEmailTemplate"
              disabled={!notificationConfig.allowsEditingEmailTemplate || !selectedProperty.value?.details?.email}
              options={notificationConfig.availableEmailTemplates ?? []}
              value={
                notificationConfig.availableEmailTemplates?.find((x) => x.id === notificationConfig.emailTemplateId) ??
                null
              }
              filterSelectedOptions
              isOptionEqualToValue={(option, value) => {
                if (option.name && value.name) return option.name === value.name;
                else return false;
              }}
              getOptionLabel={(option) => (option.name ? option.name : '')}
              onChange={(event, value: ISimpleEmailTemplateDto | null) => {
                setNotificationConfig({ ...notificationConfig, emailTemplateId: value?.id ?? undefined });
              }}
              renderInput={(params) => <TextField {...params} variant="standard" label="Attached Email Template" />}
            />
          </StyledInfoBox>
          <Autocomplete
            multiple
            id="usersNotified"
            options={userOptions}
            value={userValue}
            filterSelectedOptions
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) => {
              return option.label === value.label;
            }}
            onChange={(event, value: NotifiedUserOptionType[]) => {
              setNotificationConfig({
                ...notificationConfig,
                notifiedUsers: value.map((userOption) => userOption.value),
              });
              setUserValue(value);
            }}
            renderInput={(params) => <TextField {...params} variant="standard" label="Notified Users" />}
          />
          <FormGroup>
            <FormControlLabel
              control={<Switch size="small" checked={notificationConfig.enabled} onChange={handleEnableToggle} />}
              label="Enabled"
            />
          </FormGroup>
          <Box sx={{ display: 'flex', justifyContent: 'right' }}>
            <Button onClick={onClose} sx={{ mr: '1rem' }}>
              Close
            </Button>
            <LoadingButton
              variant="contained"
              onClick={saveConfigChanges}
              loading={notifications.globalNotifications.submitting}
            >
              Save Configuration
            </LoadingButton>
          </Box>
        </Stack>
      </DialogContent>
    </DialogLayout>
  );
};
