/**
 * https://stackoverflow.com/a/7225450/1579778
 * @param s
 */
export function camelCaseToWords(s: string | undefined) {
  if (!s) return '';

  const result = s.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
}
