import React, { FC, FormEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addEmailTemplateAction, useEmailTemplates } from '../redux/emailTemplateSlice';
import { EmailTemplateDialogMode, EmailTemplateInfoForm } from './EmailTemplateInfoForm';
import DialogContent from '@mui/material/DialogContent';
import { DialogLayout } from '../../dialogs/DialogLayout';
import { validateHandlebarKeys } from '../redux/emailTemplateData';
import { showToastMessageAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import { ReduxEmailTemplate } from '@monkeyjump-labs/cam-fe-shared/dist/types/emailTemplateTypes';

export type AddEmailTemplateDialogProps = {
  open: boolean;
  onClose: () => void;
  propertyId: string;
};

export const AddEmailTemplateDialog: FC<AddEmailTemplateDialogProps> = ({ open, onClose, propertyId }) => {
  const dispatch = useDispatch();
  const [templateToAdd, setTemplateToAdd] = useState<ReduxEmailTemplate>({ attachments: [], targetedUsers: [] });
  const { allowedKeys } = useEmailTemplates();

  const handleClose = () => {
    setTemplateToAdd({ attachments: [], targetedUsers: [] });
    onClose();
  };

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!templateToAdd.type) return;
    if (
      !validateHandlebarKeys(templateToAdd.type, allowedKeys.value, templateToAdd?.body) ||
      !validateHandlebarKeys(templateToAdd.type, allowedKeys.value, templateToAdd?.subject)
    ) {
      dispatch(showToastMessageAction({ message: 'Invalid keys in email template', severity: 'error' }));
      return;
    }
    templateToAdd.name && dispatch(addEmailTemplateAction({ propertyId: propertyId, emailTemplate: templateToAdd }));
    onClose();
  };

  return (
    <DialogLayout title={'Add Email Template'} onClose={handleClose} open={open}>
      <DialogContent>
        <form onSubmit={onSubmit} autoComplete="off">
          <EmailTemplateInfoForm
            mode={EmailTemplateDialogMode.Add}
            editingTemplate={templateToAdd}
            onEditTemplate={(template) => setTemplateToAdd(template)}
            dirty={true}
          />
        </form>
      </DialogContent>
    </DialogLayout>
  );
};
