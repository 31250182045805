import React, { FC, FormEvent, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { NumberValueTextField } from '../../../texfields/NumberValueTextField';
import { AccountAutocomplete } from '../../../autocompletes/AccountAutocomplete';
import {
  AccountType,
  AssetType,
  ISlimAccountRef,
  JournalEntryStatus,
  PaymentType,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import { useDispatch } from 'react-redux';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {
  addIncomeToAssetAction,
  clearSelectedIncomeAction,
  getSelectedIncomeAction,
  updateAssetIncomeAction,
  useStatement,
} from '../../redux/statementSlice';
import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import Grid from '@mui/material/Grid';
import { getAssetTypeFromPathname } from '../../../utils/getAssetTypeFromPathname';
import { useNavigate, useParams } from 'react-router-dom';
import { AssetParams } from '../../../../../AppRouter';
import { setBackUrlAction, useGlobal } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import Typography from '@mui/material/Typography';
import { DialogLayout } from '../../../dialogs/DialogLayout';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

type IncomeDrawerProps = {
  open?: boolean;
  onClose?: () => void;
};

export const IncomeDialog: FC<IncomeDrawerProps> = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedContext } = useAssets();
  const {
    incomeStatement: { submittingValue, submitting, submitted },
  } = useStatement();
  const { backUrl } = useGlobal();
  const { id: assetId, outerTab, innerTab, incomeId } = useParams<AssetParams & { incomeId: string }>();
  const [description, setDescription] = useState('');
  const [amount, setAmount] = useState('');
  const [revenueAccount, setRevenueAccount] = useState<ISlimAccountRef>();
  const [depositAccount, setDepositAccount] = useState<ISlimAccountRef>();
  const [isDeposited, setIsDeposited] = useState(false);
  const [payer, setPayer] = useState<string | undefined>();
  const [paymentType, setPaymentType] = useState<PaymentType | undefined>();
  const [checkNo, setCheckNo] = useState<string | undefined>();
  const title = submittingValue ? 'Edit Income' : 'Add Income';
  const assetType =
    getAssetTypeFromPathname() === 'unit'
      ? AssetType.BuildingUnit
      : getAssetTypeFromPathname() === 'building'
        ? AssetType.Building
        : AssetType.RentalProperty;

  const isNotPending = submittingValue && submittingValue?.status !== JournalEntryStatus.Pending;

  useEffect(() => {
    submitted && handleClose();
  }, [submitted]);

  useEffect(() => {
    if (incomeId) {
      dispatch(getSelectedIncomeAction(incomeId));
    }
  }, [incomeId]);

  useEffect(() => {
    setDescription(submittingValue?.description ?? '');
    setAmount(submittingValue?.amount?.toString() ?? '');
    setRevenueAccount(submittingValue?.lines?.find((l) => l.account)?.account);
    setDepositAccount(submittingValue?.depositAccount);
    setIsDeposited(submittingValue?.status === JournalEntryStatus.Posted ? true : false);
    setPayer(submittingValue?.payer);
    setPaymentType(submittingValue?.paymentNo ? PaymentType.Check : PaymentType.Cash);
    setCheckNo(submittingValue?.paymentNo);
  }, [submittingValue]);

  // useEffect(() => {
  //   //drawer isn't closing on escape without event listener
  //   const handleKeyDown = (event: KeyboardEvent) => {
  //     if (event.key === 'Escape') handleClose();
  //   };
  //   document.addEventListener('keydown', handleKeyDown, true);
  //   return () => {
  //     document.removeEventListener('keydown', handleKeyDown, true);
  //   };
  // }, []);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const body = isNotPending
      ? { description, payer }
      : {
          description,
          amount: Number(amount),
          revenueAccountId: revenueAccount?.id,
          depositAccountId: depositAccount?.id,
          isDeposited,
          payer,
          paymentType,
          checkNo,
        };
    if (submittingValue) {
      submittingValue.journalEntryId &&
        dispatch(
          updateAssetIncomeAction({
            journalEntryId: submittingValue.journalEntryId,
            body: body,
          }),
        );
      return;
    }
    assetId &&
      dispatch(
        addIncomeToAssetAction({
          assetId,
          assetType,
          body: body,
        }),
      );
  };

  const handleClose = () => {
    setDescription('');
    setAmount('');
    setRevenueAccount(undefined);
    setDepositAccount(undefined);
    setIsDeposited(false);
    setPaymentType(undefined);
    setPayer(undefined);
    setCheckNo(undefined);
    dispatch(clearSelectedIncomeAction());
    onClose && onClose();
    if (backUrl) {
      navigate(backUrl);
      dispatch(setBackUrlAction(undefined));
    } else navigate(`/assets/${getAssetTypeFromPathname()}/${assetId}/${outerTab}/${innerTab}`);
  };

  return (
    <DialogLayout title={title} onClose={handleClose} open={open ?? !!submittingValue} maxWidth={'md'}>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Grid container spacing={1}>
            {isNotPending && (
              <Grid item xs={12}>
                <Typography variant={'body1'} fontStyle={'italic'}>
                  **Only description and payer are editable for income that has been deposited or archived.
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={'Description'}
                onChange={(e) => setDescription(e.target.value)}
                value={description ?? ''}
                variant={'outlined'}
                required
                multiline
                rows={3}
              />
            </Grid>
            <Grid item xs={6}>
              <NumberValueTextField
                fullWidth
                valueUnits={'dollars'}
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                label={'Amount'}
                required
                InputProps={{
                  readOnly: isNotPending,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label={'Payer'}
                onChange={(e) => setPayer(e.target.value)}
                value={payer ?? ''}
                variant={'outlined'}
              />
            </Grid>
            <Grid item xs={6}>
              {isNotPending ? (
                <TextField
                  fullWidth
                  label={'Payment Type'}
                  value={paymentType === PaymentType.Cash ? 'Cash' : 'Check'}
                  variant={'outlined'}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              ) : (
                <FormControl fullWidth>
                  <InputLabel id="payment-type-label">Payment Type</InputLabel>
                  <Select
                    key={paymentType}
                    required
                    value={paymentType}
                    onChange={(e) =>
                      e.target.value === PaymentType.Cash
                        ? setPaymentType(PaymentType.Cash)
                        : e.target.value === PaymentType.Check
                          ? setPaymentType(PaymentType.Check)
                          : setPaymentType(undefined)
                    }
                    labelId="payment-type-label"
                    id="payment-type"
                    label="Payment Type"
                  >
                    <MenuItem value={PaymentType.Cash}>Cash</MenuItem>
                    <MenuItem value={PaymentType.Check}>Check</MenuItem>
                  </Select>
                </FormControl>
              )}
            </Grid>
            <Grid item xs={6}>
              {paymentType === PaymentType.Check && (
                <TextField
                  fullWidth
                  sx={{ mt: -0.07 }}
                  required
                  hidden={paymentType !== PaymentType.Check}
                  label={'Check Number'}
                  value={checkNo}
                  onChange={(e) => setCheckNo(e.target.value)}
                  InputProps={{
                    readOnly: isNotPending,
                  }}
                />
              )}
            </Grid>
            <Grid item xs={6}>
              <AccountAutocomplete
                propertyId={selectedContext.propertyId!}
                onChange={setRevenueAccount}
                renderInput={(params) => (
                  <TextField required margin="dense" variant="standard" label="Select Revenue Account" {...params} />
                )}
                accountType={AccountType.Revenue}
                value={revenueAccount}
                readOnly={isNotPending}
              />
            </Grid>
            <Grid item xs={6}>
              <AccountAutocomplete
                propertyId={selectedContext.propertyId!}
                onChange={setDepositAccount}
                renderInput={(params) => (
                  <TextField required margin="dense" variant="standard" label="Select Target Account" {...params} />
                )}
                accountType={AccountType.Assets}
                filterOptions={(options) =>
                  options.filter((o) => (o.defaultSelection?.match(/General|Deposit|None/)?.length ?? 0) > 0)
                }
                accountDefault={(o) => ((o.defaultSelection?.match(/General/)?.length ?? 0 > 0) ? true : false)}
                value={depositAccount}
                readOnly={isNotPending}
              />
            </Grid>
            {!isNotPending && (
              <Grid item xs={6}>
                <Box>
                  <FormControlLabel
                    control={<Checkbox checked={isDeposited} onChange={(e) => setIsDeposited(e.target.checked)} />}
                    label="Has this been deposited?"
                  />
                </Box>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'right', pt: 2 }}>
            <LoadingButton type={'submit'} variant={'contained'} loading={submitting}>
              Save
            </LoadingButton>
          </Box>
        </DialogActions>
      </form>
    </DialogLayout>
  );
};
