import React, { FC, useState } from 'react';
import { ExpenseElementType } from '@monkeyjump-labs/cam-fe-shared/dist/types/expenseTypes';
import { ISlimAccountRef, IVendor } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { useExpenses } from '../../redux/expenseSlice';
import { DialogLayout } from '../../../dialogs/DialogLayout';
import { useGridApiRef } from '@mui/x-data-grid-premium';
import { ReduxDocument } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import { AddExpenseElement } from '../forms/AddExpenseElement';

export type AddJobElementFormProps = {
  onClose: () => void;
  open: boolean;
  onAddNewVendor: (name: string) => void;
  type?: ExpenseElementType;
  downstreamDetails?: AddExpenseElementAssociation;
};

export type AddExpenseElementAssociation = {
  association?: string;
  vendor?: IVendor;
  propertyAccount?: ISlimAccountRef;
};

export type AddExpenseElementFromEmail = {
  attachment?: ReduxDocument;
  emailThreadId?: string;
};

export const AddExpenseElementDialog: FC<AddJobElementFormProps> = ({
  type,
  onAddNewVendor,
  onClose,
  open,
  downstreamDetails,
}) => {
  const { selectedExpense } = useExpenses();
  const [wideModal, setWideModal] = useState(false);
  const gridRef = useGridApiRef();
  const [title, setTitle] = useState('');

  const handleClose = () => {
    onClose();
    setWideModal(false);
  };

  const handleWidenModal = () => {
    setWideModal(!wideModal);
  };

  return (
    <DialogLayout
      title={title}
      onClose={handleClose}
      open={open}
      // style={{ zIndex: '1000' }}
      maxWidth={wideModal ? 'md' : 'sm'}
    >
      <AddExpenseElement
        type={type}
        expense={selectedExpense.value}
        open={open}
        onClose={handleClose}
        gridRef={gridRef}
        onAddNewVendor={onAddNewVendor}
        setTitle={(title: string) => setTitle(title)}
        title={title}
        downstreamDetails={downstreamDetails}
        onWideModal={handleWidenModal}
      />
    </DialogLayout>
  );
};
