import React, { FC, useEffect, useState } from 'react';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import {
  ExpenseInvoiceStatus,
  ISlimAccountRef,
  IVendor,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import {
  ExpenseElementOption,
  ExpenseElementType,
  invoiceStatusMap,
  purchaseOrderStatusMap,
  ReduxExpense,
  workOrderStatusMap,
} from '@monkeyjump-labs/cam-fe-shared/dist/types/expenseTypes';
import { ExpenseElementListItem } from './ExpenseElementListItem';

export type AssociateElementAutocompleteProps = {
  value?: string;
  type: Exclude<ExpenseElementType, ExpenseElementType.PurchaseOrder>;
  onChange: (association?: string, vendor?: IVendor, propertyAccount?: ISlimAccountRef) => void;
  selectedExpense?: ReduxExpense;
} & Omit<
  AutocompleteProps<ExpenseElementOption, false, false, false>,
  'options' | 'value' | 'onChange' | 'getOptionLabel'
>;

export const AssociateElementAutocomplete: FC<AssociateElementAutocompleteProps> = ({
  type,
  onChange,
  value,
  selectedExpense,
  ...autocompleteProps
}) => {
  const [options, setOptions] = useState<ExpenseElementOption[]>([]);
  const [internalValue, setInternalValue] = useState<ExpenseElementOption | null>(null);

  useEffect(() => {
    switch (type) {
      case ExpenseElementType.WorkOrder:
        break;
      case ExpenseElementType.Quote:
        const workOrderOptions: ExpenseElementOption[] =
          selectedExpense?.workOrders?.map((x) => {
            return {
              label: x.name ? `Work Order ${x.number}: ${x.name}` : 'Work Order',
              number: x.number,
              status: x.workOrderStatus && workOrderStatusMap.get(x.workOrderStatus),
              propertyAccountId: x.propertyAccount?.id,
              propertyAccountName: x.propertyAccount?.name,
            };
          }) ?? [];
        setOptions(workOrderOptions);
        const previousValue = workOrderOptions.find((x) => x.number === value);
        setInternalValue(previousValue ?? null);
        break;
      case ExpenseElementType.Payment:
        const invoiceOptions: ExpenseElementOption[] =
          selectedExpense?.invoices
            ?.filter((x) => x.expenseInvoiceStatus !== ExpenseInvoiceStatus.Archived)
            ?.map((x) => {
              return {
                label: x.name ? `Invoice ${x.number}: ${x.name}` : 'Invoice',
                number: x.number,
                amount: x.invoiceAmount,
                vendorName: x.vendor?.name,
                status: x.expenseInvoiceStatus && invoiceStatusMap.get(x.expenseInvoiceStatus),
                vendor: x.vendor,
                propertyAccountId: x.propertyAccount?.id,
                propertyAccountName: x.propertyAccount?.name,
              };
            }) ?? [];
        setOptions(invoiceOptions);
        const invoiceValue = invoiceOptions.find((x) => x.number === value);
        setInternalValue(invoiceValue ?? null);
        break;
      case ExpenseElementType.Invoice:
        const purchaseOrderOptions: ExpenseElementOption[] =
          selectedExpense?.purchaseOrders?.map((x) => {
            return {
              label: x.name ? `Purchase Order ${x.number}: ${x.name}` : 'Purchase Order',
              number: x.number,
              amount: x.totalAmount,
              vendorName: x.vendor?.name,
              status: x.purchaseOrderStatus && purchaseOrderStatusMap.get(x.purchaseOrderStatus),
              vendor: x.vendor,
              propertyAccountId: x.propertyAccount?.id,
              propertyAccountName: x.propertyAccount?.name,
            };
          }) ?? [];
        setOptions(purchaseOrderOptions);
        const poValue = purchaseOrderOptions.find((x) => x.number === value);
        setInternalValue(poValue ?? null);
        break;
      default:
        break;
    }
  }, [type, value, selectedExpense]);

  const handleChange = (event: React.SyntheticEvent, newValue: ExpenseElementOption | null) => {
    onChange(newValue?.number, newValue?.vendor, {
      id: newValue?.propertyAccountId,
      name: newValue?.propertyAccountName,
    });
    setInternalValue(newValue);
  };

  return (
    <Autocomplete
      options={options}
      onChange={handleChange}
      value={internalValue}
      {...autocompleteProps}
      renderOption={(props, option) => {
        return <ExpenseElementListItem option={option} {...props} />;
      }}
    />
  );
};
