import React, { FC, ReactNode } from 'react';
import Typography from '@mui/material/Typography';
import { appTheme } from '../../../../../../AppTheme';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';

type ConfigurationLayoutProps = {
  children: ReactNode;
  title: string;
};

export const ConfigurationLayout: FC<ConfigurationLayoutProps> = ({ children, title }) => {
  return (
    <Stack spacing={1}>
      <Typography variant={'h5'} color={appTheme.palette.secondary.dark}>
        {title}
      </Typography>
      <Divider />
      {children}
    </Stack>
  );
};
