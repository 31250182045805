import React from 'react';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';

export function updateEditingRows<T, ItemType>(
  gridRef: React.RefObject<GridApiPro>,
  data: T,
  itemPath: string,
  customUpdateLogic: (item: ItemType, key: string, editValues: Record<string, any>) => ItemType,
): T {
  if (!gridRef.current?.state) return data;
  let updatedData = data;
  const editRows = gridRef.current.state.editRows;

  for (const [key, editValues] of Object.entries(editRows)) {
    gridRef.current.stopRowEditMode({ id: key });

    const items: ItemType[] = getNestedProperty(updatedData, itemPath);
    const newItems = items.map((item) => customUpdateLogic(item, key, editValues));

    updatedData = setNestedProperty(updatedData, itemPath, newItems);
  }

  return updatedData;
}

function getNestedProperty(obj: any, path: string) {
  return path.split('.').reduce((acc, part) => acc && acc[part], obj);
}

function setNestedProperty(obj: any, path: string, value: any) {
  const pathArray = path.split('.');
  const lastKey = pathArray.pop();
  const lastObj = pathArray.reduce((acc, key) => (acc[key] = acc[key] || {}), obj);
  if (lastKey) lastObj[lastKey] = value;
  return obj;
}
