import React, { FC } from 'react';
import {
  ExpenseElementType,
  expensePaymentStatusMap,
  ReduxExpense,
  ReduxExpensePayment,
} from '@monkeyjump-labs/cam-fe-shared/dist/types/expenseTypes';
import TextField from '@mui/material/TextField';
import { AccountAutocomplete } from '../../../autocompletes/AccountAutocomplete';
import {
  AccountType,
  ExpensePaymentExpensePaymentStatus,
  ExpensePaymentPaymentSource,
  IContactPerson,
  ISlimAccountRef,
  IVendor,
  LinkedPaymentAccountType,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { NumberValueTextField } from '../../../texfields/NumberValueTextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import { ExpenseStatusSelect } from '../ExpenseStatusSelect';
import { VendorAutocomplete } from '../VendorAutocomplete';
import { useDispatch } from 'react-redux';
import { setPaymentSubmittingValueAction } from '../../redux/expenseSlice';
import { AssociateElementAutocomplete } from '../AssociateElementAutocomplete';
import { VendorContactAutocomplete } from '../VendorContactAutocomplete';
import Tooltip from '@mui/material/Tooltip';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { toReduxDate, toStandardDate } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import Box from '@mui/material/Box';

type JobPaymentFormProps = {
  value: ReduxExpensePayment;
  selectedExpense?: ReduxExpense;
  onChangePayment: <TKey extends keyof ReduxExpensePayment>(field: TKey, value: ReduxExpensePayment[TKey]) => void;
  onChangeVendor: (value: IVendor | undefined) => void;
  propertyId: string;
  editingMode?: boolean;
  onAddNewVendor?: (name: string) => void;
  onAssociateInvoice: (association?: string, vendor?: IVendor, propertyAccount?: ISlimAccountRef) => void;
  vendorContact?: IContactPerson;
  onChangeVendorContact?: (value: IContactPerson | undefined) => void;
};
export const PaymentForm: FC<JobPaymentFormProps> = ({
  value,
  onChangePayment,
  onChangeVendor,
  propertyId,
  editingMode,
  onAddNewVendor,
  onAssociateInvoice,
  onChangeVendorContact,
  selectedExpense,
}) => {
  const dispatch = useDispatch();
  const handleEditingModeAddNewVendor = (name: string) => {
    dispatch(setPaymentSubmittingValueAction({ ...value, vendor: { name: name } }));
    onAddNewVendor?.(name);
  };

  const handleChangeInvoice = (association?: string, vendor?: IVendor, propertyAccount?: ISlimAccountRef) => {
    if (association) onChangePayment('expenseAccount', undefined);
    onAssociateInvoice(association, vendor, propertyAccount);
  };

  return (
    <Box width={'100%'}>
      <Grid container spacing={2}>
        <Grid item xs={editingMode ? 6 : 12}>
          <TextField
            fullWidth
            required
            label="Name"
            value={value.name}
            variant={'outlined'}
            onChange={(e) => onChangePayment('name', e.target.value)}
          />
        </Grid>
        {editingMode && (
          <Grid item xs={editingMode ? 6 : 12}>
            <ExpenseStatusSelect
              value={value.status}
              onChange={(e) => onChangePayment('status', e.target.value as ExpensePaymentExpensePaymentStatus)}
              statusMap={expensePaymentStatusMap}
              type={ExpenseElementType.Payment}
            />
          </Grid>
        )}
        <Grid item xs={editingMode ? 6 : 12}>
          <AssociateElementAutocomplete
            selectedExpense={selectedExpense}
            value={value.associatedInvoice}
            type={ExpenseElementType.Payment}
            onChange={handleChangeInvoice}
            renderInput={(params) => (
              <TextField margin="dense" variant="outlined" label="Associated Invoice" {...params} />
            )}
          />
        </Grid>
        {editingMode && value.associatedInvoice ? (
          <Grid item xs={editingMode ? 6 : 12}>
            <Tooltip title={'Cannot update vendor when an invoice is associated'}>
              <TextField
                fullWidth
                label={'Vendor'}
                value={value.vendor?.name ?? ''}
                variant={'outlined'}
                InputProps={{ readOnly: true }}
              />
            </Tooltip>
          </Grid>
        ) : (
          <>
            <Grid item xs={editingMode ? 6 : 12}>
              <VendorAutocomplete
                propertyId={propertyId}
                onChange={onChangeVendor}
                renderInput={(params) => <TextField margin="dense" variant="outlined" label="Vendor" {...params} />}
                onAddNewVendor={editingMode ? handleEditingModeAddNewVendor : onAddNewVendor}
                value={value.vendor?.name}
              />
            </Grid>
            {value.vendor && (
              <Grid item xs={editingMode ? 6 : 12}>
                <VendorContactAutocomplete
                  vendor={value.vendor}
                  onChange={onChangeVendorContact}
                  renderInput={(params) => (
                    <TextField margin="dense" variant="outlined" label="Vendor Contact" {...params} />
                  )}
                  value={value.vendorContact ?? null}
                />
              </Grid>
            )}
          </>
        )}
        <Grid item xs={editingMode ? 6 : 12}>
          <DatePicker
            value={value.datePaid ? toStandardDate(value.datePaid) : null}
            onChange={(date) => onChangePayment('datePaid', toReduxDate(date) ?? undefined)}
            label="Date Paid"
            slotProps={{ textField: { fullWidth: true } }}
          />
        </Grid>
        {value.associatedInvoice ? undefined : (
          <Grid item xs={editingMode ? 6 : 12}>
            <AccountAutocomplete
              value={value.expenseAccount}
              propertyId={propertyId}
              onChange={(value) => onChangePayment('expenseAccount', value)}
              accountType={AccountType.Expenses}
              renderInput={(params) => (
                <TextField required margin="dense" variant="standard" label="Expense Account" {...params} />
              )}
            />
          </Grid>
        )}
        <Grid item xs={editingMode ? 6 : 12}>
          <Tooltip title={'Cannot update amount on payments'}>
            <NumberValueTextField
              fullWidth
              required
              valueUnits={'dollars'}
              label="Amount"
              value={value.amount !== 0 ? value.amount : ''}
              type="number"
              onChange={(e) => onChangePayment('amount', Number(e.target.value))}
              InputProps={{ readOnly: editingMode ? true : false }}
            />
          </Tooltip>
        </Grid>
        <Grid item xs={editingMode ? 6 : 12}>
          {editingMode ? (
            <Tooltip title={'Cannot update payment type on payments'}>
              <TextField
                fullWidth
                label={'Payment Type'}
                value={value.payment ?? ''}
                variant={'outlined'}
                InputProps={{ readOnly: true }}
              />
            </Tooltip>
          ) : (
            <FormControl fullWidth>
              <InputLabel id="payment-type-label">Payment Type</InputLabel>
              <Select
                required
                value={value.payment}
                onChange={(e) => onChangePayment('payment', e.target.value as ExpensePaymentPaymentSource)}
                labelId="payment-type-label"
                id="payment-type"
                label="Payment Type"
              >
                <MenuItem value={ExpensePaymentPaymentSource.Cash}>Cash</MenuItem>
                <MenuItem value={ExpensePaymentPaymentSource.Check}>Check</MenuItem>
                <MenuItem value={ExpensePaymentPaymentSource.CreditCard}>Credit Card Receipt</MenuItem>
              </Select>
            </FormControl>
          )}
        </Grid>
        {value.payment !== ExpensePaymentPaymentSource.Cash && (
          <Grid item xs={editingMode ? 6 : 12}>
            <AccountAutocomplete
              value={value.propertyAccount}
              propertyId={propertyId}
              onChange={(value) => onChangePayment('propertyAccount', value)}
              accountType={
                value.payment === ExpensePaymentPaymentSource.Check ? AccountType.Assets : AccountType.Liabilities
              }
              accountFilter={
                value.payment === ExpensePaymentPaymentSource.Check
                  ? (account) =>
                      account.linkedPaymentAccountType === LinkedPaymentAccountType.BankAccount &&
                      account.defaultSelection !== 'Deposit'
                  : (account) => account.linkedPaymentAccountType === LinkedPaymentAccountType.CreditCard
              }
              renderInput={(params) => (
                <TextField
                  required
                  margin="dense"
                  variant="standard"
                  label="Paid From Account"
                  helperText={
                    value.payment === ExpensePaymentPaymentSource.CreditCard
                      ? 'This will log a receipt against the account, *not* transfer funds electronically'
                      : undefined
                  }
                  {...params}
                />
              )}
            />
          </Grid>
        )}
        {value.payment === ExpensePaymentPaymentSource.Check && (
          <Grid item xs={editingMode ? 6 : 12}>
            <TextField
              fullWidth
              required
              hidden={value.payment !== ExpensePaymentPaymentSource.Check}
              label={'Check Number'}
              value={value.paymentDetail}
              onChange={(e) => onChangePayment('paymentDetail', e.target.value)}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Description"
            value={value.description}
            variant={'outlined'}
            required
            multiline
            rows={3}
            onChange={(e) => onChangePayment('description', e.target.value)}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
