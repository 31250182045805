import React, { FC, useEffect, useState } from 'react';
import {
  GridCellParams,
  GridColDef,
  GridRowModesModel,
  GridRowsProp,
  GridToolbarContainer,
  GridToolbarProps,
  GridValidRowModel,
  MuiEvent,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import {
  AssociationType,
  ICamAssociation,
  IExpensePurchaseOrderItem,
  TaskSection,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { StripedDataGrid } from '../../../datagrids/StripedDataGrid';
import { showToastMessageAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import { useDispatch } from 'react-redux';
import { AssociationCell } from '../../../datagrids/AssociationCell';
import { removeAssociationFromPurchaseOrderLineItemAction, useExpenses } from '../../redux/expenseSlice';
import { setNewTaskFromAssociationValuesAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/tasks/taskSlice';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { useIcons } from '../../../icons/useIcons';

type ItemizedPurchaseOrderItemTableProps = {
  loading?: boolean;
  value: IExpensePurchaseOrderItem[];
  onSave: (values: IExpensePurchaseOrderItem[]) => void;
  isEditable?: boolean;
  onPOLineItemAssociation?: (lineItemNumber: string, changeOrderId?: string) => void;
  changeOrderId?: string;
  gridApiRef?: React.RefObject<GridApiPremium>;
};

function EditToolbar(props: GridToolbarProps) {
  const { gridRef, nextId, setRows } = props;

  const handleClick = () => {
    setRows((oldRows: GridRowsProp<IExpensePurchaseOrderItem>) => [
      ...oldRows,
      {
        number: nextId.toString(),
      },
    ]);
    setTimeout(() => {
      setTimeout(() => {
        const field = 'firstName';
        gridRef?.current?.apiRef?.current.setCellFocus(nextId.toString(), field);
        // Optionally select the text
        const cellInput = document.querySelector<HTMLInputElement>(
          `[data-id='${nextId}'] [data-field='${field}'] input`,
        );
        cellInput?.select();
      }, 100);
    }, 100);
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add Item
      </Button>
    </GridToolbarContainer>
  );
}

export const ItemizedPurchaseOrderItemTable: FC<ItemizedPurchaseOrderItemTableProps> = ({
  loading,
  value,
  onSave,
  isEditable,
  onPOLineItemAssociation,
  changeOrderId,
  gridApiRef,
}) => {
  const dispatch = useDispatch();
  const { getActionIcon, ActionType } = useIcons();
  const { selectedExpense, selectedPurchaseOrder } = useExpenses();
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [rows, setRows] = useState<IExpensePurchaseOrderItem[]>(value ?? []);
  const gridRef = gridApiRef ?? useGridApiRef();

  useEffect(() => {
    setRows(value);
  }, [value]);

  const handleOnKeyDown = (params: GridCellParams, event: MuiEvent<React.KeyboardEvent<HTMLElement>>) => {
    //this allows user to access the save changes button without having to first click out of the row -- onSave flips the dirty bool
    const { id, field } = params;
    if (event.target instanceof HTMLInputElement) {
      const target = event.target as HTMLInputElement;
      let value = target.value;

      if (!value) {
        value = event.key;
      }
      const editingRow = rows.find((x) => x.number === id);
      const updatedRow = { ...editingRow, [field]: value };

      const updatedRows = rows.map((x) => (x.number === updatedRow.number ? updatedRow : x));
      onSave(updatedRows);
    }
  };

  const columns: GridColDef<IExpensePurchaseOrderItem>[] = [
    { field: 'name', headerName: 'Name', flex: 1, editable: !!isEditable },
    { field: 'description', headerName: 'Description', flex: 1, editable: !!isEditable },
    {
      field: 'amount',
      headerName: 'Amount',
      type: 'number',
      flex: 1,
      editable: !!isEditable,
      valueParser: (value) => {
        if (value === undefined) return 0;

        const valuePieces = value.toString().split('.');

        if (valuePieces.length === 2 && valuePieces[1].length > 2) {
          return Number(valuePieces[0] + '.' + valuePieces[1].slice(0, 2));
        }

        return value;
      },
    },
    {
      field: 'quantity',
      headerName: 'Quantity',
      type: 'number',
      flex: 1,
      editable: !!isEditable,
      valueParser: (value) => {
        if (value === undefined || value < 0) return 1;

        const valuePieces = value.toString().split('.');

        if (valuePieces.length === 2 && valuePieces[1].length > 2) {
          return Number(valuePieces[0] + '.' + valuePieces[1].slice(0, 2));
        }

        return value;
      },
    },
    {
      field: 'associations',
      headerName: 'Associations',
      display: 'flex',
      flex: 1,
      editable: false,
      renderCell: (params) => (
        <AssociationCell
          associations={params.row.associations ?? []}
          tableType={'purchaseOrderLineItems'}
          onRemoveAssociation={(association: ICamAssociation) => {
            if (
              !selectedPurchaseOrder.value?.number ||
              !selectedExpense.value?.id ||
              !params.row.number ||
              !association.associatedId ||
              !association.associationType
            )
              return;
            dispatch(
              removeAssociationFromPurchaseOrderLineItemAction({
                expenseId: selectedExpense.value?.id,
                purchaseOrderNumber: selectedPurchaseOrder.value?.number,
                changeOrderId: changeOrderId,
                lineItemNumber: params.row.number,
                association,
              }),
            );
          }}
          onOpenAddAssociation={
            params.row.number && params.row.associations && onPOLineItemAssociation
              ? () => {
                  onPOLineItemAssociation(params.row.number!, changeOrderId);
                }
              : undefined
          }
        />
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: (params) => (
        <Stack direction={'row'} spacing={1}>
          <Tooltip title={'Delete'}>
            <IconButton size={'small'} onClick={() => onSave(rows.filter((r) => r.number !== params.row.number))}>
              {getActionIcon(ActionType.Delete)}
            </IconButton>
          </Tooltip>
          {params.row.associations && (
            <Tooltip title={'Create Task From Line Item'}>
              <IconButton
                size={'small'}
                onClick={() => {
                  if (!selectedExpense.value?.id || !selectedPurchaseOrder.value?.number || !params.row.number) return;
                  dispatch(
                    setNewTaskFromAssociationValuesAction({
                      id: selectedExpense.value.id,
                      valueAssociationType: AssociationType.ExpensePurchaseOrder,
                      poLineItemInfo: {
                        poNumber: selectedPurchaseOrder.value?.number,
                        lineItemNumber: params.row.number,
                        changeOrderId: changeOrderId,
                      },
                      name: selectedPurchaseOrder.value?.name,
                      description: selectedPurchaseOrder.value?.description,
                      valueAssociatedId: selectedExpense.value.associatedId,
                      valueAssetType: selectedExpense.value.assetType,
                      taskSection: TaskSection.Operations,
                    }),
                  );
                }}
              >
                {getActionIcon(ActionType.CreateTask)}
              </IconButton>
            </Tooltip>
          )}
        </Stack>
      ),
    },
  ];

  const handleSave = (updatedRow: GridValidRowModel) => {
    if (updatedRow.name === '' || !updatedRow.name) {
      dispatch(showToastMessageAction({ message: 'The name field is required', severity: 'warning' }));
    }
    //removed this because save is being handled by onCellKeyDown and updateEditingRows (gridRef in outer component) logic
    // const updatedRows = rows.map((x) => (x.number === updatedRow.number ? updatedRow : x));
    // onSave(updatedRows);
    return updatedRow;
  };

  return (
    <StripedDataGrid
      disableRowGrouping
      autoHeight
      apiRef={gridRef?.current ? (gridRef as React.MutableRefObject<GridApiPremium>) : undefined}
      columns={columns}
      rows={rows}
      loading={loading}
      editMode={'row'}
      rowModesModel={rowModesModel}
      onRowModesModelChange={(model) => setRowModesModel(model)}
      processRowUpdate={handleSave}
      onRowEditStop={(params) => handleSave(params.row)}
      slots={{
        toolbar: EditToolbar,
      }}
      getRowId={(x) => x.number}
      slotProps={{
        toolbar: {
          gridRef,
          nextId: rows.length > 0 ? Math.max(...rows.map((r) => Number(r.number))) + 1 : 1,
          setRows,
          setRowModesModel,
        },
      }}
      onCellKeyDown={(params, event) => handleOnKeyDown(params, event)}
    />
  );
};
