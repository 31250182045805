import React, { FC } from 'react';
import {
  ExpenseElementType,
  ReduxExpensePayment,
  ReduxInvoice,
  ReduxPurchaseOrder,
  ReduxQuote,
  ReduxWorkOrder,
} from '@monkeyjump-labs/cam-fe-shared/dist/types/expenseTypes';
import { ViewPaymentDetails } from '../forms/ViewPaymentDetails';
import { ViewInvoiceDetails } from '../forms/ViewInvoiceDetails';
import { ViewPurchaseOrderDetails } from '../forms/ViewPurchaseOrderDetails';
import { ViewQuoteDetails } from '../forms/ViewQuoteDetails';
import { ViewWorkOrderDetails } from '../forms/ViewWorkOrderDetails';

type JobElementInfoTabProps = {
  type: ExpenseElementType;
  onAddNewVendor: (name: string) => void;
  value?: ReduxExpensePayment | ReduxInvoice | ReduxQuote | ReduxPurchaseOrder | ReduxWorkOrder;
  jobId?: string;
  propertyId?: string;
  onPOLineItemAssociation: (lineItemNumber: string) => void;
};

export const ExpenseElementInfoTab: FC<JobElementInfoTabProps> = ({
  type,
  onAddNewVendor,
  value,
  jobId,
  propertyId,
  onPOLineItemAssociation,
}) => {
  if (!value || !jobId || !propertyId) return <></>;
  return (
    <>
      {
        {
          payment: (
            <ViewPaymentDetails payment={value} propertyId={propertyId} jobId={jobId} onAddNewVendor={onAddNewVendor} />
          ),
          invoice: (
            <ViewInvoiceDetails invoice={value} propertyId={propertyId} jobId={jobId} onAddNewVendor={onAddNewVendor} />
          ),
          quote: (
            <ViewQuoteDetails quote={value} propertyId={propertyId} jobId={jobId} onAddNewVendor={onAddNewVendor} />
          ),
          purchaseOrder: (
            <ViewPurchaseOrderDetails
              purchaseOrder={value}
              propertyId={propertyId}
              jobId={jobId}
              onAddNewVendor={onAddNewVendor}
              onPOLineItemAssociation={onPOLineItemAssociation}
            />
          ),
          workOrder: <ViewWorkOrderDetails workOrder={value} propertyId={propertyId} jobId={jobId} />,
          job: <></>,
        }[type ?? ExpenseElementType.Expense]
      }
    </>
  );
};
