import React, { Ref } from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import {
  ExpenseInvoiceStatus,
  ExpensePaymentExpensePaymentStatus,
  ExpensePurchaseOrderStatus,
  ExpenseQuoteStatus,
  ExpenseStatus,
  WorkOrderStatus,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { ExpenseElementType } from '@monkeyjump-labs/cam-fe-shared/dist/types/expenseTypes';

type ExpenseStatusType =
  | ExpenseStatus
  | ExpenseQuoteStatus
  | ExpensePaymentExpensePaymentStatus
  | ExpenseInvoiceStatus
  | WorkOrderStatus
  | ExpensePurchaseOrderStatus;

type JobStatusSelectProps<TStatus extends ExpenseStatusType> = {
  ref?: Ref<any>;
  size?: 'small' | 'medium' | undefined;
  value?: TStatus;
  onChange: (e: SelectChangeEvent<TStatus>) => void;
  statusMap: Map<TStatus, string>;
  type: ExpenseElementType;
};

export const ExpenseStatusSelect = <TStatus extends ExpenseStatusType>({
  ref,
  statusMap,
  size,
  value,
  onChange,
}: JobStatusSelectProps<TStatus>) => {
  const title = 'Status';

  return (
    <FormControl fullWidth size={size} sx={{ mt: '.5rem' }}>
      {!ref && <InputLabel id={title}>{title}</InputLabel>}
      <Select
        id={title}
        labelId={`${title}-label`}
        label={title}
        aria-label={title}
        value={value ?? ''}
        onChange={(e: SelectChangeEvent<TStatus>) => onChange(e)}
        ref={ref}
      >
        {Array.from(statusMap).map(([status, label]) => (
          <MenuItem key={status} dense value={status}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
