import React, { FC, FormEvent, useEffect, useState } from 'react';
import { DialogLayout } from '../../../dialogs/DialogLayout';
import DialogContent from '@mui/material/DialogContent';
import { WizardStepper } from '../../../steppers/WizardStepper';
import { StyledInfoBox } from '../../../styledComponents/StyledInfoBox';
import Typography from '@mui/material/Typography';
import { getStatementByLeaseIdAction, useStatement } from '../../../statements/redux/statementSlice';
import { StatementView } from '../../../statements/components/StatementView';
import { isEnum, toReduxDate, toStandardDate } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import { useProperty } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/propertySlice';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { useDispatch } from 'react-redux';
import {
  getAvailableDepositBalanceAction,
  getDepositBankAccountBalanceAction,
  markLeaseAsClosedAction,
  useLeases,
} from '../../redux/leasesSlice';
import {
  AccountType,
  DefaultSelection,
  ISlimAccountRef,
  LinkedPaymentAccountType,
  PaymentType,
  StatementPeriodType,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import TextField from '@mui/material/TextField';
import { AccountAutocomplete } from '../../../autocompletes/AccountAutocomplete';
import { SelectChangeEvent } from '@mui/material/Select';
import { LeasePaymentTypeSelector } from '../../../dialogs/paymentDialog/LeasePaymentTypeSelector';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import { LeaseDispatchType } from '@monkeyjump-labs/cam-fe-shared/dist/types/leaseTypes';
import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import { AccountStatementViewDialog } from '../../../statements/components/accountStatementDialog/AccountStatementViewDialog';
import { ReduxEntry } from '../../../statements/redux/statementTypes';
import { startOfMonth } from 'date-fns';
import { NumericFormat } from 'react-number-format';

export type CloseLeaseWizardProps = {
  onClose: () => unknown;
  leaseId: string;
  assetId: string;
  isOpen: boolean;
  leasesType: LeaseDispatchType;
};

export const CloseLeaseWizard: FC<CloseLeaseWizardProps> = ({ leaseId, isOpen, assetId, onClose, leasesType }) => {
  const dispatch = useDispatch();
  const { selectedContext } = useAssets();
  const { availableDepositBalance } = useLeases();
  const { selectedProperty } = useProperty();
  const { selectedStatement } = useStatement();
  const generalAccount = selectedProperty.value?.rentalBillingInfo?.find(
    (x) => x.bankAccount?.defaultSelection === DefaultSelection.General,
  );
  const depositAccount = selectedProperty.value?.rentalBillingInfo?.find(
    (x) => x.bankAccount?.defaultSelection === DefaultSelection.Deposit,
  );

  const [activeStep, setActiveStep] = useState(0);
  const [depositToRectifyAmount, setDepositToRectifyAmount] = useState<number>(0);
  const [bankDepositBalance, setBankDepositBalance] = useState<number>(0);
  const [type, setType] = useState<PaymentType | undefined>(PaymentType.Check);
  const [returnDepositNo, setReturnDepositNo] = useState('');
  const [returnExcessFundsNo, setReturnExcessFundsNo] = useState('');
  const [reason, setReason] = useState('');
  const [returnDepositAccount, setReturnDepositAccount] = useState<ISlimAccountRef>();
  const [returnExcessFundsAccount, setReturnExcessFundsAccount] = useState<ISlimAccountRef>();
  const [transferRemainingFunds, setTransferRemainingFunds] = useState(true);
  const [openPopupStatement, setOpenPopupStatement] = useState(false);
  const [selectedStatementLine, setSelectedStatementLine] = useState<ReduxEntry | undefined>(undefined);
  const [postRectifiedBalance, setPostRectifiedBalance] = useState<number>(0);
  const [returnDepositFundsAmount, setReturnDepositFundsAmount] = useState<number>(0);
  const [returnAdditionalFundsAmount, setReturnAdditionalFundsAmount] = useState<number>(0);
  const [transferFundsAmount, setTransferFundsAmount] = useState(0);

  const hasSameDepositAndPaymentsAccount =
    selectedProperty.value?.rentalBillingInfo?.find(
      (x) => x.bankAccount?.defaultSelection?.toString() === 'Deposit, General',
    ) !== undefined;

  const stepTitles = [
    ...['Rectify Deposit', 'Return Deposit Funds'],
    ...(returnAdditionalFundsAmount > 0 ? ['Return Remaining Funds'] : []),
    ...(transferFundsAmount > 0 ? ['Transfer Funds'] : []),
    'Summary & Close',
  ];

  useEffect(() => {
    availableDepositBalance.value !== undefined && setBankDepositBalance(availableDepositBalance.value);
  }, [availableDepositBalance.value]);

  useEffect(() => {
    selectedStatement.value?.depositBalance !== undefined &&
      setDepositToRectifyAmount(selectedStatement.value?.depositBalance ?? 0);
  }, [selectedStatement.value]);

  useEffect(() => {
    typeof selectedStatement.value?.totalBalance === 'number' &&
      typeof selectedStatement.value?.depositBalance === 'number' &&
      setPostRectifiedBalance(selectedStatement.value.totalBalance - selectedStatement.value.depositBalance);
  }, [selectedStatement.value]);

  useEffect(() => {
    const toReturnAmount = hasSameDepositAndPaymentsAccount
      ? Math.max(0, -1 * postRectifiedBalance)
      : Math.max(0, Math.min(-1 * postRectifiedBalance, bankDepositBalance));
    setReturnDepositFundsAmount(toReturnAmount);
    const singleBankAccount = selectedProperty.value?.rentalBillingInfo?.find(
      (x) => x.bankAccount?.defaultSelection?.toString() === 'Deposit, General',
    );
    singleBankAccount && setReturnDepositAccount(singleBankAccount.linkedGlAccount);
  }, [hasSameDepositAndPaymentsAccount, postRectifiedBalance, bankDepositBalance]);

  useEffect(() => {
    const toReturnAmount = hasSameDepositAndPaymentsAccount
      ? 0
      : Math.max(0, -1 * postRectifiedBalance - returnDepositFundsAmount);
    setReturnAdditionalFundsAmount(toReturnAmount);
  }, [hasSameDepositAndPaymentsAccount, postRectifiedBalance, returnDepositFundsAmount]);

  useEffect(() => {
    if (hasSameDepositAndPaymentsAccount) setTransferFundsAmount(0);
    else setTransferFundsAmount(Math.max(0, bankDepositBalance - returnDepositFundsAmount));
  }, [hasSameDepositAndPaymentsAccount, returnDepositFundsAmount, bankDepositBalance]);

  useEffect(() => {
    if (leaseId) {
      dispatch(getAvailableDepositBalanceAction(leaseId));
      dispatch(getDepositBankAccountBalanceAction(leaseId));
    }
    const today = toStandardDate(selectedContext.currentDate) ?? new Date();
    const firstOfMonth = toReduxDate(startOfMonth(today));

    dispatch(
      getStatementByLeaseIdAction({
        leaseId: leaseId,
        startDate: firstOfMonth,
        statementPeriodType: StatementPeriodType.Month,
      }),
    );
  }, [leaseId]);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    if (!activeStep) return;

    setActiveStep(activeStep - 1);
  };

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(
      markLeaseAsClosedAction({
        leaseId: leaseId,
        assetId: assetId,
        leaseType: leasesType,
        body: {
          reason: 'Closing Lease',
          transferRemainingDepositFunds: transferRemainingFunds,
          returnDepositAccountId: returnDepositAccount?.id,
          returnDepositCheckNo: returnDepositNo,
          returnOutstandingCreditAccountId: returnExcessFundsAccount?.id,
          returnOutstandingCreditCheckNo: !hasSameDepositAndPaymentsAccount ? returnExcessFundsNo : undefined,
        },
      }),
    );
    onClose();
  };

  const handleTypeChange = (event: SelectChangeEvent<PaymentType | undefined>) => {
    const value = event.target.value;
    if (value === undefined) setType(undefined);
    isEnum(PaymentType)(value) && setType(value);
  };

  let stepCount = 2;
  const steps: { [index: number]: any } = {
    0: (
      <Stack spacing={2}>
        <StatementView
          statement={selectedStatement.value}
          loading={selectedStatement.loading}
          submitting={selectedStatement.submitting}
          skipTimeControls
          showDepositChip
          onOpenPopupStatement={(statementLine) => {
            setOpenPopupStatement(true);
            setSelectedStatementLine(statementLine);
          }}
        />
        <Typography>
          Bank Deposit Funds Available:{' '}
          <NumericFormat
            value={bankDepositBalance}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
            decimalScale={2}
            fixedDecimalScale
          />
        </Typography>
        <Typography>
          Deposit Funds to Rectify:{' '}
          <NumericFormat
            value={depositToRectifyAmount}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
            decimalScale={2}
            fixedDecimalScale
          />
        </Typography>
        <Typography variant={'body2'}>
          *Rectify deposit will release deposit funds to return to tenant or apply charges towards.
        </Typography>
      </Stack>
    ),
    1: (
      <>
        {returnDepositFundsAmount > 0 ? (
          <Stack spacing={2}>
            <LeasePaymentTypeSelector
              onChange={handleTypeChange}
              supportedPaymentTypes={[PaymentType.BankTransfer, PaymentType.Check]}
              value={type}
            />
            {type === PaymentType.Check && (
              <TextField
                required
                hidden={type !== PaymentType.Check}
                label={'Check Number'}
                value={returnDepositNo}
                onChange={(e) => setReturnDepositNo(e.target.value)}
              />
            )}
            {type === PaymentType.Check && (
              <AccountAutocomplete
                value={returnDepositAccount}
                propertyId={selectedProperty!.value!.id!}
                onChange={setReturnDepositAccount}
                accountType={AccountType.Assets}
                hideAccountPath
                accountFilter={(account) => account.linkedPaymentAccountType === LinkedPaymentAccountType.BankAccount}
                accountDefault={(account) =>
                  (depositAccount?.bankAccount &&
                    account.linkedPaymentAccountId === depositAccount.bankAccount?.mtAccountId) ??
                  false
                }
                renderInput={(params) => (
                  <TextField margin="dense" variant="standard" label="From Account" {...params} />
                )}
              />
            )}
            <Typography>
              Funds To Return:{' '}
              <NumericFormat
                value={returnDepositFundsAmount}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
                decimalScale={2}
                fixedDecimalScale
              />
            </Typography>
          </Stack>
        ) : (
          <Typography>No funds to return</Typography>
        )}
      </>
    ),
  };

  if (returnAdditionalFundsAmount > 0)
    steps[stepCount++] = (
      <Stack spacing={2}>
        <LeasePaymentTypeSelector
          onChange={handleTypeChange}
          supportedPaymentTypes={[PaymentType.BankTransfer, PaymentType.Check]}
          value={type}
        />
        {type === PaymentType.Check && (
          <TextField
            required
            hidden={type !== PaymentType.Check}
            label={'Check Number'}
            value={returnExcessFundsNo}
            onChange={(e) => setReturnExcessFundsNo(e.target.value)}
          />
        )}
        {type === PaymentType.Check && (
          <AccountAutocomplete
            value={returnExcessFundsAccount}
            propertyId={selectedProperty!.value!.id!}
            onChange={setReturnExcessFundsAccount}
            accountType={AccountType.Assets}
            hideAccountPath
            accountFilter={(account) => account.linkedPaymentAccountType === LinkedPaymentAccountType.BankAccount}
            accountDefault={(account) =>
              (generalAccount?.bankAccount &&
                account.linkedPaymentAccountId === generalAccount.bankAccount?.mtAccountId) ??
              false
            }
            renderInput={(params) => <TextField margin="dense" variant="standard" label="From Account" {...params} />}
          />
        )}
        <Typography>
          Funds To Return:{' '}
          <NumericFormat
            value={returnAdditionalFundsAmount}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
            decimalScale={2}
            fixedDecimalScale
          />
        </Typography>
      </Stack>
    );

  if (transferFundsAmount > 0)
    steps[stepCount++] = (
      <>
        <Typography>
          Remaining funds in Deposit Bank Account:{' '}
          <NumericFormat
            value={transferFundsAmount}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
            decimalScale={2}
            fixedDecimalScale
          />
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={transferRemainingFunds}
              onChange={() => setTransferRemainingFunds(!transferRemainingFunds)}
            />
          }
          label="Transfer remaining funds to General Bank Account"
        />
      </>
    );

  steps[stepCount++] = (
    <Stack spacing={2}>
      <StyledInfoBox label={'Summary'}>
        <List>
          {returnDepositFundsAmount > 0 && (
            <ListItem
              secondaryAction={
                <Typography>
                  <NumericFormat
                    value={returnDepositFundsAmount}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                    decimalScale={2}
                    fixedDecimalScale
                  />
                </Typography>
              }
            >
              <ListItemAvatar>
                <Avatar>
                  <CurrencyExchangeIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={'Deposit Return'} secondary={returnDepositNo} />
            </ListItem>
          )}
          {returnAdditionalFundsAmount > 0 && (
            <ListItem
              secondaryAction={
                <Typography>
                  <NumericFormat
                    value={returnAdditionalFundsAmount}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                    decimalScale={2}
                    fixedDecimalScale
                  />
                </Typography>
              }
            >
              <ListItemAvatar>
                <Avatar>
                  <CurrencyExchangeIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={'Additional Return'} secondary={returnExcessFundsNo} />
            </ListItem>
          )}
          {transferFundsAmount > 0 && transferRemainingFunds && (
            <ListItem
              secondaryAction={
                <Typography>
                  <NumericFormat
                    value={transferFundsAmount}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                    decimalScale={2}
                    fixedDecimalScale
                  />
                </Typography>
              }
            >
              <ListItemAvatar>
                <Avatar>
                  <AccountBalanceIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText>
                <Stack direction={'row'}>
                  <Typography>{depositAccount?.bankAccount?.name}</Typography>
                  <Box px={1}>
                    <ArrowRightAltIcon />
                  </Box>
                  <Typography>{generalAccount?.bankAccount?.name}</Typography>
                </Stack>
              </ListItemText>
            </ListItem>
          )}
        </List>
      </StyledInfoBox>
      {availableDepositBalance.value !== undefined && availableDepositBalance.value < 0 && (
        <StyledInfoBox label={'Reason'}>
          <TextField
            label={'Reason for Closing'}
            value={reason}
            sx={{ width: '100%' }}
            multiline
            rows={3}
            variant={'outlined'}
            onChange={(e) => setReason(e.target.value)}
          />
        </StyledInfoBox>
      )}
      <Box pt={1} justifyContent={'end'} width={'100%'} sx={{ display: 'flex' }}>
        <LoadingButton type="submit" variant={'contained'} loading={availableDepositBalance.loading}>
          Close Lease
        </LoadingButton>
      </Box>
    </Stack>
  );

  return (
    <DialogLayout
      sx={{
        width: '100%',
      }}
      maxWidth="md"
      PaperProps={{ sx: { width: '100%' } }}
      title={'Close Lease'}
      onClose={onClose}
      open={isOpen}
      level={selectedStatement.value?.pendingBalance ? 'error' : undefined}
      subtitle={selectedStatement.value?.pendingBalance ? '**Confirm Pending Funds Have Deposited**' : undefined}
    >
      <form onSubmit={onSubmit} autoComplete="off">
        <DialogContent>
          <WizardStepper
            steps={stepTitles}
            activeStep={activeStep}
            onNext={handleNext}
            onBack={handleBack}
            onReset={() => setActiveStep(0)}
            continueButtonText={'Next'}
          />
          <Box py={2}>{steps[activeStep]}</Box>
        </DialogContent>
      </form>
      <AccountStatementViewDialog
        open={openPopupStatement}
        onClose={() => {
          setOpenPopupStatement(false);
        }}
        isPopupStatement
        linkedGlAccountId={selectedStatementLine?.depositAccount?.id}
        highlightedStatementRow={selectedStatementLine}
      />
    </DialogLayout>
  );
};
