import React, { FC, FormEvent, useEffect, useState } from 'react';
import {
  ReduxChangeOrder,
  ReduxPurchaseOrder,
  updateEditingPurchaseOrderLineItem,
} from '@monkeyjump-labs/cam-fe-shared/dist/types/expenseTypes';
import { useDispatch } from 'react-redux';
import { updateChangeOrderAction, updatePurchaseOrderAction, useExpenses } from '../../redux/expenseSlice';
import Stack from '@mui/material/Stack';
import { PurchaseOrderForm } from './PurchaseOrderForm';
import {
  IContactPerson,
  IExpensePurchaseOrderItem,
  ISlimAccountRef,
  IVendor,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import { POAdjustmentList } from './POAdjustmentList';
import { useGridApiRef } from '@mui/x-data-grid-premium';
import { updateEditingRows } from '../../../utils/updateEditingRows';

type ViewPurchaseOrderDetailsProps = {
  purchaseOrder: ReduxPurchaseOrder;
  propertyId: string;
  jobId: string;
  onAddNewVendor: (name: string) => void;
  onPOLineItemAssociation: (lineItemNumber: string) => void;
};

export const ViewPurchaseOrderDetails: FC<ViewPurchaseOrderDetailsProps> = ({
  purchaseOrder,
  onAddNewVendor,
  jobId,
  propertyId,
  onPOLineItemAssociation,
}) => {
  const dispatch = useDispatch();
  const { selectedExpense, selectedPurchaseOrder } = useExpenses();
  const [dirty, setDirty] = useState(false);
  const [editingPurchaseOrder, setEditingPurchaseOrder] = useState(
    selectedPurchaseOrder.submittingValue ?? purchaseOrder,
  );
  const gridRef = useGridApiRef();
  const changeOrderGridRef = useGridApiRef();

  useEffect(() => {
    setEditingPurchaseOrder(selectedPurchaseOrder.submittingValue ?? purchaseOrder);
    selectedPurchaseOrder.submittingValue && setDirty(true);
  }, [selectedPurchaseOrder.submittingValue, purchaseOrder]);

  const handleChangePurchaseOrder = <T extends keyof ReduxPurchaseOrder>(field: T, value: ReduxPurchaseOrder[T]) => {
    setEditingPurchaseOrder({ ...editingPurchaseOrder, [field]: value });
    setDirty(true);
  };

  const handleChangeVendor = (value: IVendor | undefined) => {
    setEditingPurchaseOrder({ ...editingPurchaseOrder, vendor: value });
    setDirty(true);
  };

  const handleChangeVendorContact = (value: IContactPerson | undefined) => {
    setEditingPurchaseOrder({ ...editingPurchaseOrder, vendorContact: value });
    setDirty(true);
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const purchaseOrderToSubmit = updateEditingRows<ReduxPurchaseOrder, IExpensePurchaseOrderItem>(
      gridRef,
      editingPurchaseOrder,
      'items',
      updateEditingPurchaseOrderLineItem,
    );
    dispatch(
      updatePurchaseOrderAction({
        jobId: jobId,
        purchaseOrder: {
          ...purchaseOrderToSubmit,
          amount: purchaseOrderToSubmit.isItemized ? undefined : purchaseOrderToSubmit.amount,
          items: purchaseOrderToSubmit.items?.filter((i) => i.name !== undefined),
        },
      }),
    );
    setDirty(false);
  };

  const handleChangeAssociation = (associations?: string[]) => {
    setEditingPurchaseOrder({
      ...editingPurchaseOrder,
      associatedQuotes: associations,
    });
    setDirty(true);
  };

  const handleUpdateChangeOrder = (changeOrder: ReduxChangeOrder) => {
    if (!selectedPurchaseOrder.value?.number || !selectedExpense.value?.id || !changeOrder.id) return;
    const updatedChangeOrder = updateEditingRows<ReduxChangeOrder, IExpensePurchaseOrderItem>(
      changeOrderGridRef,
      changeOrder,
      'items',
      updateEditingPurchaseOrderLineItem,
    );
    dispatch(
      updateChangeOrderAction({
        expenseId: selectedExpense.value.id,
        poNumber: selectedPurchaseOrder.value.number,
        changeOrderId: changeOrder.id,
        amount: updatedChangeOrder.amount,
        note: updatedChangeOrder.note,
        items: updatedChangeOrder.items,
      }),
    );
  };

  return (
    <Stack spacing={2}>
      <form onSubmit={handleSubmit}>
        <PurchaseOrderForm
          value={editingPurchaseOrder}
          onChangePurchaseOrder={handleChangePurchaseOrder}
          onChangeVendor={handleChangeVendor}
          onChangeVendorContact={handleChangeVendorContact}
          propertyId={propertyId}
          expenseAccount={editingPurchaseOrder.propertyAccount}
          onChangeExpenseAccount={(account: ISlimAccountRef) => {
            setDirty(true);
            setEditingPurchaseOrder({ ...editingPurchaseOrder, propertyAccount: account });
          }}
          onAddNewVendor={onAddNewVendor}
          editingMode
          onAssociateQuote={handleChangeAssociation}
          onPOLineItemAssociation={onPOLineItemAssociation}
          gridApiRef={gridRef}
        />
        <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'right', mt: 1 }}>
          <LoadingButton
            variant={'contained'}
            disabled={!dirty}
            type={'submit'}
            loading={selectedPurchaseOrder.submitting}
          >
            Save Changes
          </LoadingButton>
        </Box>
      </form>
      <POAdjustmentList
        value={editingPurchaseOrder}
        onUpdateChangeOrder={handleUpdateChangeOrder}
        onPOLineItemAssociation={onPOLineItemAssociation}
        updateGridApiRef={changeOrderGridRef}
      />
    </Stack>
  );
};
