import React, { FC, useEffect, useRef, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import { EventApi, EventChangeArg, EventClickArg, EventContentArg } from '@fullcalendar/core';
import './calendar.css';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { TaskCategoryAutocomplete } from '../tasks/components/TaskCategoryAutocomplete';
import {
  AssetType,
  AssociationType,
  CalendarEventType,
  ITaskCategory,
  TaskSection,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import { getEventsAction, updateEventDateRangeAction, useCalendar } from './redux/calendarSlice';
import { useDispatch } from 'react-redux';
import { toReduxDate } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import { useNavigate, useParams } from 'react-router-dom';
import { useTasks } from '@monkeyjump-labs/cam-fe-shared/dist/redux/tasks/taskSlice';
import ConstructionIcon from '@mui/icons-material/Construction';
import ScheduleIcon from '@mui/icons-material/Schedule';
import Typography from '@mui/material/Typography';
import { ReduxCalendarEvent } from './redux/calendarData';
import Tooltip from '@mui/material/Tooltip';
import { useScheduledTasks } from '@monkeyjump-labs/cam-fe-shared/dist/redux/tasks/scheduledTaskSlice';
import Stack from '@mui/material/Stack';
import { getAssetTypeFromPathname } from '../utils/getAssetTypeFromPathname';
import { AssetParams } from '../../../AppRouter';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { AssigneeAutocomplete, UserOption } from '../tasks/components/AssigneeAutocomplete';
import { getContrastColor } from '../utils/colorUtils';
import { randomId } from '@mui/x-data-grid-generator';
import { addDays, parseISO } from 'date-fns';

type CalendarEvent = ReduxCalendarEvent & { backgroundColor?: string };

type CalendarProps = {
  taskSection?: TaskSection;
};

export const Calendar: FC<CalendarProps> = ({ taskSection }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams<AssetParams>();
  const { selectedContext } = useAssets();
  const { selectedTask } = useTasks();
  const { selectedScheduledTask } = useScheduledTasks();
  const { events } = useCalendar();
  const calendarRef = useRef<FullCalendar>(null);
  const [showCompleted, setShowCompleted] = React.useState<boolean>(false);
  const [dateRangeStart, setDateRangeStart] = React.useState<string>();
  const [dateRangeEnd, setDateRangeEnd] = React.useState<string>();
  const [assignee, setAssignee] = React.useState<string>();
  const [taskCategory, setTaskCategory] = React.useState<ITaskCategory>();
  const [calendarEvents, setCalendarEvents] = React.useState<ReduxCalendarEvent[]>([]);
  const [eventType, setEventType] = useState(CalendarEventType.Assignee);
  const [showChildEvents, setShowChildEvents] = useState<boolean>(false);
  const asset = getAssetTypeFromPathname();
  const assetType =
    asset === 'property' ? AssetType.RentalProperty : asset === 'unit' ? AssetType.BuildingUnit : AssetType.Building;

  useEffect(() => {
    const filteringId = eventType === CalendarEventType.Assignee ? assignee : taskCategory?.id;
    const ids = filteringId ? [filteringId] : undefined;
    id &&
      dateRangeEnd &&
      dateRangeStart &&
      dispatch(
        getEventsAction({
          assetId: id,
          assetType: assetType,
          body: {
            eventType: eventType,
            startDate: toReduxDate(dateRangeStart),
            endDate: toReduxDate(dateRangeEnd),
            showCompleted: showCompleted,
            ids: ids,
            taskSection: taskSection,
            showChildTasks: showChildEvents,
          },
        }),
      );
  }, [
    taskSection,
    eventType,
    showCompleted,
    showChildEvents,
    selectedContext.propertyId,
    dateRangeEnd,
    dateRangeStart,
    assignee,
    taskCategory,
    selectedTask.submitted,
    selectedScheduledTask.submitted,
  ]);

  useEffect(() => {
    if (events.value) {
      const formattedEvents: CalendarEvent[] = events.value?.map((event) => {
        return {
          ...event,
          id: event.id ?? randomId(),
          //need to add a day to the end date to make it inclusive for all day events
          end: event.allDay ? addDays(parseISO(event.end), 1).toISOString() : event.end,
          backgroundColor: event.color ? event.color : 'lightgrey',
        };
      });
      setCalendarEvents(formattedEvents);
    }
  }, [events.value]);

  const handleEvents = (events: EventApi[]) => {
    console.log('events', events);
  };

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'row', paddingY: 2, justifyContent: 'space-between' }}>
        <Box sx={{ minWidth: '50%', minHeight: '5rem', display: 'flex', flexDirection: 'row' }}>
          <Box sx={{ minWidth: '30%', marginX: 1, marginTop: 1 }}>
            <FormControl variant={'outlined'} fullWidth>
              <InputLabel id="event-sort-by" aria-label={'event-sort-by'}>
                Event Sort By
              </InputLabel>
              <Select
                onChange={(e) => {
                  setEventType(e.target.value as CalendarEventType);
                }}
                aria-labelledby={'event-sort-by'}
                value={eventType ?? ''}
                label={'Event Sort By'}
              >
                <MenuItem value={CalendarEventType.Assignee}>Assignee</MenuItem>
                <MenuItem value={CalendarEventType.Category}>Category</MenuItem>
              </Select>
            </FormControl>
          </Box>
          {eventType === CalendarEventType.Assignee ? (
            <AssigneeAutocomplete
              title={'Assignee'}
              value={assignee}
              onValueChange={(v?: UserOption | null) => setAssignee(v?.id ?? undefined)}
            />
          ) : (
            <Box sx={{ minWidth: '50%', marginLeft: 2, marginTop: 1 }}>
              <TaskCategoryAutocomplete
                value={taskCategory}
                onChange={(v: ITaskCategory | null) => setTaskCategory(v ?? undefined)}
              />
            </Box>
          )}
        </Box>
        <Box display={'flex'} flexDirection={'column-reverse'}>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  size="small"
                  checked={showCompleted}
                  onChange={(e) => {
                    setShowCompleted(e.target.checked);
                  }}
                />
              }
              label={'Show Completed'}
            />
          </FormGroup>
          {assetType !== AssetType.BuildingUnit && (
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    checked={showChildEvents}
                    onChange={(e) => {
                      setShowChildEvents(e.target.checked);
                    }}
                  />
                }
                label={'Show Child Asset Events'}
              />
            </FormGroup>
          )}
        </Box>
      </Box>
      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        initialDate={selectedContext.currentDate}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay',
        }}
        events={calendarEvents}
        dateClick={(arg: DateClickArg) => {
          //select and date click look very similar; both fire when non-event area is clicked; this has just one date
          console.log('date click! ' + arg.dateStr);
        }}
        datesSet={(arg) => {
          // This function is called whenever the date range changes
          setDateRangeStart(arg.startStr);
          setDateRangeEnd(arg.endStr);
        }}
        editable={true}
        selectable={true}
        select={(arg) => {
          //select and date click look very similar; both fire when non-event area is clicked; this has a start and end
          console.log('select! ' + arg.startStr + ' to ' + arg.endStr);
        }}
        eventClick={(arg: EventClickArg) => {
          if (arg.event.extendedProps.associationType === AssociationType.Task) {
            navigate(`task/${arg.event.extendedProps.taskId}/info`);
          } else if (arg.event.extendedProps.associationType === AssociationType.ScheduledTask) {
            navigate(`scheduledTask/${arg.event.extendedProps.taskId}/info`);
          }
        }}
        eventChange={(arg: EventChangeArg) => {
          if (
            !arg.event.start ||
            !arg.event.end ||
            (arg.event.end === arg.oldEvent.end && arg.event.start === arg.oldEvent.start)
          ) {
            return;
          }
          dispatch(
            updateEventDateRangeAction({
              id: arg.event.extendedProps.taskId,
              associationType: arg.event.extendedProps.associationType,
              start: arg.event.start.toISOString(),
              end: arg.event.allDay ? addDays(arg.event.end, -1).toISOString() : arg.event.end?.toISOString(),
            }),
          );
        }}
        eventContent={(arg: EventContentArg) => {
          const icon =
            arg.event.extendedProps.associationType === AssociationType.Task ? <ConstructionIcon /> : <ScheduleIcon />;
          let assigneeLabel = 'Unassigned';
          if (eventType == CalendarEventType.Assignee && arg.event.extendedProps.name)
            assigneeLabel = `Assignee: ${arg.event.extendedProps.name}`;
          let categoryLabel = 'Uncategorized';
          if (eventType == CalendarEventType.Category && arg.event.extendedProps.name)
            categoryLabel = `Category: ${arg.event.extendedProps.name}`;

          return (
            <Tooltip
              title={
                <Stack direction={'column'}>
                  {eventType === CalendarEventType.Assignee && (
                    <Typography variant={'body2'}>{assigneeLabel}</Typography>
                  )}
                  {eventType === CalendarEventType.Category && (
                    <Typography variant={'body2'}>{categoryLabel}</Typography>
                  )}
                </Stack>
              }
            >
              <Box
                style={{
                  backgroundColor: arg.event.backgroundColor,
                  margin: 1,
                  alignItems: 'center',
                  display: 'flex',
                  flexGrow: 1,
                  borderRadius: 3,
                  overflow: 'hidden',
                }}
              >
                <Box sx={{ paddingX: '.1rem', color: getContrastColor(arg.event.backgroundColor) }}>{icon}</Box>
                <Typography sx={{ paddingX: '.1rem', color: getContrastColor(arg.event.backgroundColor) }}>
                  {arg.timeText}
                </Typography>
                <Typography fontStyle={'italic'} sx={{ color: getContrastColor(arg.event.backgroundColor) }}>
                  {arg.event.title}
                </Typography>
              </Box>
            </Tooltip>
          );
        }}
        eventsSet={handleEvents} // called after events are initialized/added/changed/removed
        /* you can update a remote database when these fire:
         eventAdd={function(){}}
        eventChange={function(){}}
        eventRemove={function(){}} */
      />
    </>
  );
};
