import React, { FC, useState } from 'react';
import { StripedDataGrid, styledFilterPanelProps } from '../../../datagrids/StripedDataGrid';
import { GridColDef, GridRenderCellParams, GridRenderEditCellParams, GridRowParams } from '@mui/x-data-grid-premium';
import { tryFormatDate } from '../../../utils/TryFormatDate';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Add from '@mui/icons-material/Add';
import Stack from '@mui/material/Stack';
import {
  ExpenseElementType,
  expensePaymentStatusMap,
  ReduxExpensePayment,
} from '@monkeyjump-labs/cam-fe-shared/dist/types/expenseTypes';
import { NameCell } from './cells/NameCell';
import { useDispatch } from 'react-redux';
import { ActionCell } from '../../../datagrids/ActionCell';
import { StatusCell, StatusEditCell } from './cells/StatusCell';
import { markPaymentVoidAction, unVoidPaymentAction, useExpenses } from '../../redux/expenseSlice';
import { GlCodeCell } from './cells/GlCodeCell';
import { AssociateElementDialog } from '../dialogs/AssociateElementDialog';
import { AssociatedValueCell } from './cells/AssociatedValueCell';
import { InternalAssociationCell } from './cells/InternalAssociationCell';
import {
  ExpensePaymentExpensePaymentStatus,
  ExpensePaymentPaymentSource,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { VendorDetailsCell } from './cells/VendorDetailsCell';
import EditIcon from '@mui/icons-material/Edit';
import { useIcons } from '../../../icons/useIcons';
import Tooltip from '@mui/material/Tooltip';

type PaymentsTableProps = {
  onOpenDrawer: (number?: string, type?: ExpenseElementType) => void;
  onOpenAddPayment: (type: ExpenseElementType) => void;
};

export const PaymentsTable: FC<PaymentsTableProps> = ({ onOpenDrawer, onOpenAddPayment }) => {
  const dispatch = useDispatch();
  const { selectedExpense, selectedPayment } = useExpenses();
  const rows = selectedExpense.value?.payments ?? [];
  const [openAssociationDialog, setOpenAssociationDialog] = useState(false);
  const [selectedNumber, setSelectedNumber] = useState<string | undefined>();
  const { getActionIcon, ActionType } = useIcons();

  function* createActions(params: GridRowParams<ReduxExpensePayment>) {
    if (!params.row.number) return;
    if (params.row.status !== ExpensePaymentExpensePaymentStatus.Archived) {
      yield (
        <ActionCell
          icon={getActionIcon(ActionType.Void)}
          key={'markAsVoid'}
          label={'Mark As Void'}
          onClick={() => {
            selectedExpense.value?.id &&
              params.row.number &&
              dispatch(
                markPaymentVoidAction({
                  jobId: selectedExpense.value?.id,
                  paymentNumber: params.row.number,
                }),
              );
          }}
          loading={selectedPayment.submitting}
          showInMenu
        />
      );
      yield (
        <ActionCell
          icon={<ReceiptIcon />}
          key={'associateInvoice'}
          label={params.row.associatedInvoice ? 'Change Associated Invoice' : 'Associate Invoice'}
          onClick={() => {
            setSelectedNumber(params.row.number);
            setOpenAssociationDialog(true);
          }}
          showInMenu
        />
      );
    } else {
      yield (
        <ActionCell
          icon={getActionIcon(ActionType.Rollback)}
          key={'unVoid'}
          label={'Undo Void'}
          onClick={() => {
            selectedExpense.value?.id &&
              params.row.number &&
              dispatch(
                unVoidPaymentAction({
                  jobId: selectedExpense.value?.id,
                  paymentNumber: params.row.number,
                }),
              );
          }}
          loading={selectedPayment.submitting}
          showInMenu
        />
      );
    }
  }

  const columns: GridColDef<ReduxExpensePayment>[] = [
    {
      field: 'number',
      headerName: '#',
      flex: 1.5,
      display: 'flex',
      renderCell: (params: GridRenderCellParams) => (
        <NameCell {...params} onOpenDrawer={onOpenDrawer} type={ExpenseElementType.Payment} />
      ),
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      display: 'flex',
      renderCell: (params: GridRenderCellParams) => (
        <NameCell {...params} onOpenDrawer={onOpenDrawer} type={ExpenseElementType.Payment} />
      ),
    },
    {
      field: 'vendor',
      headerName: 'Vendor',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <VendorDetailsCell vendor={params.row.vendor} contact={params.row.vendorContact} {...params} />
      ),
    },
    {
      field: 'approvedBy',
      headerName: 'Approved By',
      flex: 1,
    },
    {
      field: 'dateCreated',
      headerName: 'Date Created',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <>{params.value ? tryFormatDate(params.value ?? '') : ''}</>,
    },
    {
      field: 'datePaid',
      headerName: 'Date Paid',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <>{params.value ? tryFormatDate(params.value ?? '') : ''}</>,
    },
    {
      field: 'associatedInvoice',
      headerName: 'Invoice',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <InternalAssociationCell
          typeToRender={ExpenseElementType.Invoice}
          handleOpenDrawer={onOpenDrawer}
          number={params.value}
          multiplePossible={false}
          {...params}
        />
      ),
    },
    {
      field: 'invoicedAmount',
      headerName: 'Invoiced Amount',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <AssociatedValueCell type={ExpenseElementType.Payment} number={params.row.associatedInvoice} {...params} />
      ),
    },
    {
      field: 'amount',
      headerName: 'Paid Amount',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <>{params.value && `$${params.value}`}</>,
    },
    {
      field: 'payment',
      headerName: 'Source',
      flex: 1,
      valueFormatter: (value?: ExpensePaymentPaymentSource) => {
        switch (value) {
          case ExpensePaymentPaymentSource.Check:
            return 'Check';
          case ExpensePaymentPaymentSource.CreditCard:
            return 'Credit Card';
          case ExpensePaymentPaymentSource.Cash:
            return 'Cash';
        }
      },
    },
    {
      field: 'expenseAccount',
      headerName: 'GL Code',
      flex: 1,
      renderCell: (params: GridRenderCellParams<ReduxExpensePayment>) =>
        params.row.associatedInvoice ? (
          <Tooltip title={'From Invoice'}>
            <div>
              <GlCodeCell {...params} />
            </div>
          </Tooltip>
        ) : (
          <GlCodeCell {...params} />
        ),
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      display: 'flex',
      editable: true,
      renderHeader: () => {
        return (
          <>
            {'Status '}
            <EditIcon fontSize="small" color="disabled" />
          </>
        );
      },
      renderCell: (params: GridRenderCellParams) => <StatusCell statusMap={expensePaymentStatusMap} {...params} />,
      renderEditCell: (params: GridRenderEditCellParams) => (
        <StatusEditCell type={ExpenseElementType.Payment} statusMap={expensePaymentStatusMap} {...params} />
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      flex: 0.75,
      getActions: (params: GridRowParams) => Array.from(createActions(params)),
    },
  ];

  return (
    <Stack spacing={2} mt={'1rem'}>
      <Box>
        <Button variant={'outlined'} startIcon={<Add />} onClick={() => onOpenAddPayment(ExpenseElementType.Payment)}>
          Add Payment
        </Button>
      </Box>
      <StripedDataGrid
        disableRowGrouping
        rows={rows}
        columns={columns}
        autoHeight
        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
        getRowId={(row) => row.number}
        slotProps={{
          filterPanel: styledFilterPanelProps,
        }}
        pagination
        pageSizeOptions={[10, 25, 50, 100]}
      />
      <AssociateElementDialog
        selectedNumber={selectedNumber}
        type={ExpenseElementType.Payment}
        onClose={() => setOpenAssociationDialog(false)}
        open={openAssociationDialog}
      />
    </Stack>
  );
};
