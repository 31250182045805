import React, { FC, useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { formatOptions, handleOptionLabel, UserOption } from '../tasks/components/AssigneeAutocomplete';
import { useUser, getUsersForAssetAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/user/userSlice';
import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import { useDispatch } from 'react-redux';
import { AssetType } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';

type WatcherAutocompleteProps = {
  watchers: string[];
  setWatchers: (watchers: string[]) => void;
  title?: string;
};

export const MultiUserAutocomplete: FC<WatcherAutocompleteProps> = ({ watchers, setWatchers, title }) => {
  const dispatch = useDispatch();
  const { selectedContext } = useAssets();
  const usersForAsset = useUser((u) => u.usersForAsset);

  const [options, setOptions] = useState<UserOption[]>([]);
  const [optionValue, setOptionValue] = useState<UserOption[]>([]);

  useEffect(() => {
    if (usersForAsset.value === undefined) {
      const assetId = selectedContext.unitId ?? selectedContext.buildingId ?? selectedContext.propertyId;
      const associationType = selectedContext.unitId
        ? AssetType.BuildingUnit
        : selectedContext.buildingId
          ? AssetType.Building
          : AssetType.RentalProperty;
      assetId && dispatch(getUsersForAssetAction({ assetId, associationType }));
    }
  }, []);

  useEffect(() => {
    const options: UserOption[] = formatOptions(usersForAsset.value ?? []);
    setOptions(options ?? []);

    const watchersOptions = watchers
      .map((watcher) => {
        return options?.find((option) => option.id === watcher);
      })
      .filter((watcher) => watcher !== undefined) as UserOption[];
    setOptionValue(watchersOptions);
  }, [usersForAsset, watchers]);

  return (
    <Autocomplete
      multiple
      id="watchers"
      options={options}
      value={optionValue}
      filterSelectedOptions
      isOptionEqualToValue={(option, value) => {
        return option.id === value.id;
      }}
      onChange={(event, value: UserOption[]) => {
        if (!value) {
          setWatchers([]);
          return;
        } else {
          setWatchers(
            value.map((v) => {
              return v.id;
            }),
          );
        }
        setOptionValue(value);
      }}
      renderInput={(params) => <TextField {...params} variant="standard" label={title ?? 'Watchers'} />}
      getOptionLabel={(option: UserOption) => handleOptionLabel(option)}
    />
  );
};
