import React, { FC, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Add from '@mui/icons-material/Add';
import { StripedDataGrid, styledFilterPanelProps } from '../../../datagrids/StripedDataGrid';
import Stack from '@mui/material/Stack';
import {
  ExpenseElementType,
  invoiceStatusMap,
  ReduxInvoice,
} from '@monkeyjump-labs/cam-fe-shared/dist/types/expenseTypes';
import { GridColDef, GridRenderCellParams, GridRenderEditCellParams, GridRowParams } from '@mui/x-data-grid-premium';
import { NameCell } from './cells/NameCell';
import { tryFormatDate } from '../../../utils/TryFormatDate';
import { unVoidInvoiceAction, useExpenses, voidInvoiceAction } from '../../redux/expenseSlice';
import { StatusCell, StatusEditCell } from './cells/StatusCell';
import { GlCodeCell } from './cells/GlCodeCell';
import { ActionCell } from '../../../datagrids/ActionCell';
import { useDispatch } from 'react-redux';
import { AssociateElementDialog } from '../dialogs/AssociateElementDialog';
import { AssociatedValueCell } from './cells/AssociatedValueCell';
import { InternalAssociationCell } from './cells/InternalAssociationCell';
import { VendorDetailsCell } from './cells/VendorDetailsCell';
import EditIcon from '@mui/icons-material/Edit';
import { useIcons } from '../../../icons/useIcons';
import { AddExpenseElementAssociation } from '../dialogs/AddExpenseElementDialog';
import { ExpenseInvoiceStatus } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';

type InvoiceTableProps = {
  onOpenDrawer: (number?: string, type?: ExpenseElementType) => void;
  onOpenAddExpenseChildItem: (
    type: ExpenseElementType,
    isUpstream?: boolean,
    downstreamDetails?: AddExpenseElementAssociation,
  ) => void;
};

export const InvoiceTable: FC<InvoiceTableProps> = ({ onOpenAddExpenseChildItem, onOpenDrawer }) => {
  const { selectedExpense } = useExpenses();
  const { getActionIcon, ActionType } = useIcons();
  const dispatch = useDispatch();
  const rows = selectedExpense.value?.invoices ?? [];
  const [openAssociationDialog, setOpenAssociationDialog] = useState(false);
  const [selectedNumber, setSelectedNumber] = useState<string | undefined>();

  function* createActions(params: GridRowParams<ReduxInvoice>) {
    if (params.row.expenseInvoiceStatus != ExpenseInvoiceStatus.Archived) {
      yield (
        <ActionCell
          icon={getActionIcon(ActionType.AssociateExpenseItem)}
          key={'associatePurchaseOrder'}
          label={params.row.associatedPurchaseOrder ? 'Change Associated PO' : 'Associate Purchase Order'}
          onClick={() => {
            setSelectedNumber(params.row.number);
            setOpenAssociationDialog(true);
          }}
          showInMenu
        />
      );
      yield (
        <ActionCell
          icon={getActionIcon(ActionType.Block)}
          key={'voidInvoice'}
          label={'Mark as Void'}
          onClick={() => {
            selectedExpense.value?.id &&
              params.row.number &&
              dispatch(
                voidInvoiceAction({
                  jobId: selectedExpense.value?.id,
                  invoiceNumber: params.row.number,
                }),
              );
          }}
          showInMenu
        />
      );
      yield (
        <ActionCell
          icon={getActionIcon(ActionType.Add)}
          key={'createPayment'}
          label={'Create Payment'}
          onClick={() =>
            onOpenAddExpenseChildItem(ExpenseElementType.Invoice, true, {
              association: params.row.number,
              vendor: params.row.vendor,
              propertyAccount: params.row.propertyAccount,
            })
          }
          showInMenu
        />
      );
    } else {
      yield (
        <ActionCell
          icon={getActionIcon(ActionType.Rollback)}
          key={'unVoidInvoice'}
          label={'Undo Void'}
          onClick={() => {
            selectedExpense.value?.id &&
              params.row.number &&
              dispatch(
                unVoidInvoiceAction({
                  jobId: selectedExpense.value?.id,
                  invoiceNumber: params.row.number,
                }),
              );
          }}
          showInMenu
        />
      );
    }
  }

  const columns: GridColDef<ReduxInvoice>[] = [
    {
      field: 'number',
      headerName: '#',
      flex: 1.5,
      display: 'flex',
      renderCell: (params: GridRenderCellParams) => (
        <NameCell {...params} onOpenDrawer={onOpenDrawer} type={ExpenseElementType.Invoice} />
      ),
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      display: 'flex',
      renderCell: (params: GridRenderCellParams) => (
        <NameCell {...params} onOpenDrawer={onOpenDrawer} type={ExpenseElementType.Invoice} />
      ),
    },
    {
      field: 'vendor',
      headerName: 'Vendor',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <VendorDetailsCell vendor={params.row.vendor} contact={params.row.vendorContact} {...params} />
      ),
    },
    {
      field: 'dateCreated',
      headerName: 'Date Created',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <>{params.value ? tryFormatDate(params.value ?? '') : ''}</>,
    },
    {
      field: 'dateReceived',
      headerName: 'Date Received',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <>{params.value ? tryFormatDate(params.value ?? '') : ''}</>,
    },
    {
      field: 'dueDate',
      headerName: 'Due Date',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <>{params.value ? tryFormatDate(params.value ?? '') : ''}</>,
    },
    {
      field: 'associatedPurchaseOrder',
      headerName: 'Purchase Order',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <InternalAssociationCell
          typeToRender={ExpenseElementType.PurchaseOrder}
          handleOpenDrawer={onOpenDrawer}
          number={params.value}
          multiplePossible={false}
          {...params}
        />
      ),
    },
    {
      field: 'purchaseOrderAmount',
      headerName: 'PO Amount',
      flex: 0.75,
      renderCell: (params: GridRenderCellParams) => (
        <AssociatedValueCell
          type={ExpenseElementType.Invoice}
          number={params.row.associatedPurchaseOrder}
          {...params}
        />
      ),
    },
    {
      field: 'invoiceAmount',
      headerName: 'Invoiced Amount',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <>{params.value && `$${params.value}`}</>,
    },
    {
      field: 'associatedPayment',
      headerName: 'Payments',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <InternalAssociationCell
          typeToRender={ExpenseElementType.Payment}
          handleOpenDrawer={onOpenDrawer}
          number={params.row.number}
          multiplePossible={true}
          {...params}
        />
      ),
    },
    {
      field: 'totalPayments',
      headerName: 'Payments Total',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <AssociatedValueCell
          type={ExpenseElementType.Invoice}
          number={params.row.number}
          distantAssociationType={ExpenseElementType.Payment}
          {...params}
        />
      ),
    },
    {
      field: 'propertyAccount',
      headerName: 'GL Code',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <GlCodeCell {...params} />,
    },
    {
      field: 'expenseInvoiceStatus',
      headerName: 'Status',
      flex: 0.75,
      display: 'flex',
      renderHeader: () => {
        return (
          <>
            {'Status '}
            <EditIcon fontSize="small" color="disabled" />
          </>
        );
      },
      editable: true,
      renderCell: (params: GridRenderCellParams) => <StatusCell statusMap={invoiceStatusMap} {...params} />,
      renderEditCell: (params: GridRenderEditCellParams) => (
        <StatusEditCell type={ExpenseElementType.Invoice} statusMap={invoiceStatusMap} {...params} />
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      flex: 0.75,
      getActions: (params: GridRowParams) => Array.from(createActions(params)),
    },
  ];

  return (
    <Stack spacing={2} mt={'1rem'}>
      <Box>
        <Button
          variant={'outlined'}
          startIcon={<Add />}
          onClick={() => onOpenAddExpenseChildItem(ExpenseElementType.Invoice)}
        >
          Add Invoice
        </Button>
      </Box>
      <StripedDataGrid
        disableRowGrouping
        rows={rows}
        columns={columns}
        autoHeight
        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
        slotProps={{
          filterPanel: styledFilterPanelProps,
        }}
      />
      <AssociateElementDialog
        selectedNumber={selectedNumber}
        type={ExpenseElementType.Invoice}
        onClose={() => setOpenAssociationDialog(false)}
        open={openAssociationDialog}
      />
    </Stack>
  );
};
